import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/modal";
import { VStack, Button, Flex, textDecoration } from "@chakra-ui/react";
import Checkbox from "../Checkbox";
import { useLiquity } from "../../Hooks/LiquityContext";

export type PopupProps = {
  isOpen: boolean;
  onClose: () => void;
};

const Popup: React.FC<PopupProps> = ({ isOpen, onClose }) => {
  const [toggle, setToggle] = useState(false);
  const [understandDisclaimer, setUnderstandDisclaimer] = useState(false);
  const [understandDisclaimerError, setUnderstandDisclaimerError] = useState(false);
  const { account } = useLiquity();
  const onSubmit = (): void => {
    if (!understandDisclaimer) {
      setUnderstandDisclaimerError(true);
    } else {
      localStorage.setItem(account+"agreedToYetiFinanceDisclaimer", "agreed");
      onClose();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      scrollBehavior="inside"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl" pb={2}>
          Disclaimer: Risks of Using Protocol
        </ModalHeader>
        <ModalBody>
          Use at Your Own Risk: Yeti Finance is a novel, decentralized borrowing protocol that allows
          users to deposit assets and borrow the protocol’s native stablecoin, YUSD, against them.
          The Yeti Finance protocol is made up of free, public, and open-source software. Your use of
          Yeti Finance involves various risks, including, but not limited, to losses while digital
          assets are deposited into Yeti Finance via smart contract or economic exploits, and losses
          due to liquidations and redemptions. Before borrowing, staking, or liquidity providing you
          should fully review our{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={"https://techdocs.yeti.finance/"}
            style={{ outline: "none", textDecoration: "underline" }}
          >
            technical documentation
          </a>{" "}
          to understand how the Yeti Finance protocol works. While the Yeti Finance Decentralized
          Finance Protocol has been thoroughly audited by multiple independent software security
          firms and undergone third-party economic analysis, there remains a risk that assets
          deposited into the protocol as well as the YUSD and YETI tokens may suffer complete and
          permanent economic loss should the protocol’s technical or economic mechanisms suffer
          catastrophic failure. THE YETI FINANCE PROTOCOL IS PROVIDED “AS IS”, AT YOUR OWN RISK, AND
          WITHOUT WARRANTIES OF ANY KIND. No developer or entity involved in creating the YETI
          FINANCE will be liable for any claims or damages whatsoever associated with your use,
          inability to use, or your interaction with other users of the Yeti Finance protocol,
          including any direct, indirect, incidental, special, exemplary, punitive or consequential
          damages, or loss of profits, cryptocurrencies, tokens, or anything else of value.
        </ModalBody>
        <ModalFooter justifyContent={"center"}>
          <VStack>
            <Flex mb={1} mt={2}>
              <Checkbox
                isChecked={understandDisclaimer}
                onChange={() => setUnderstandDisclaimer(!understandDisclaimer)}
                error={understandDisclaimerError}
                label="I understand the risks and would like to proceed."
                popup={true}
              />
            </Flex>
            <Button variant="primary" mr={6} onClick={onSubmit}>
              Proceed
            </Button>
          </VStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Popup;
