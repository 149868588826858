import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Header, ConnectCard } from "../../Components";
import { StakeCard, YourBalanceCard } from "../../PageComponents/Stake";
import { VEStakeCard, YourVEBalanceCard } from "../../PageComponents/VEStake";

export type StakeProps = {
  disconnected?: boolean;
};

const Stake: React.FC<StakeProps> = ({ disconnected = false }) => (
  <Box>
    <Header title="stake.png" />
    <Flex direction={["column", null, "row"]} flex={1} mt={6}>
      <Flex flex={1} mr={[0, null, 3]}>
        <StakeCard disconnected={disconnected} />
      </Flex>

      <Flex flex={1} ml={[0, null, 3]} mt={[6, null, 0]}>
        {disconnected ? <ConnectCard title="Your Balance" /> : <YourBalanceCard />}
      </Flex>
    </Flex>
    <Flex direction={["column", null, "row"]} flex={1} mt={6}>
      <Flex flex={1} mr={[0, null, 3]}>
        <VEStakeCard disconnected={disconnected} />
      </Flex>

      <Flex flex={1} ml={[0, null, 3]} mt={[6, null, 0]}>
        {disconnected ? <ConnectCard title="Your Balance" /> : <YourVEBalanceCard />}
      </Flex>
    </Flex>
  </Box>
);

export default Stake;
