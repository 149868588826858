import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";

export type LinkProps = {
  label: string;
  to: string;
};

const CustomLink: React.FC<LinkProps> = ({ label, to }) => {
  const location = useLocation();

  let current = false;
  if (location.pathname === to) {
    current = true;
  }

  return (
    <Box bg={current ? "brand.500" : "transparent"} px={[3, null, 4]} py={1} borderRadius="infinity">
      <Link to={to}>
        <Text
          textStyle="title4"
          fontSize={["md", "lg", null, "xl"]}
          color={current ? "white" : "brand.300"}
          _hover={{ color: "white" }}
        >
          {label}
        </Text>
      </Link>
    </Box>
  );
};

export default CustomLink;
