import React from "react";
import { Flex, Box, Text, Tr, Td, Spacer } from "@chakra-ui/react";
import StatColumn from "../StatColumn";
import { Icon, TokenTable } from "../../../Components";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import tokenData, { tokenSafetyMapping } from "../../../TokenData";
import { format, getNum, formatWithDecimals } from "../../../Utils/number";
import { VC_explanation } from "../../../Utils/constants";
import Tooltip from "../../../Components/Tooltip";

const selector = ({ trove, price, tokenBalances, total, safetyRatios, YUSDPrice }: LiquityStoreState) => ({
  trove,
  price,
  tokenBalances,
  total,
  safetyRatios,
  YUSDPrice
});

const BorrowSummary: React.FC = () => {
  const { trove, price, tokenBalances, total, safetyRatios, YUSDPrice } = useLiquitySelector(selector);
  // console.log('YUSDPrice', +String(YUSDPrice))
  tokenData.map(
    token =>
      (token["troveBalance"] = formatWithDecimals(trove.collaterals[token.address], token.decimals))
  );
  tokenData.map(
    token =>
      (token["walletBalance"] = formatWithDecimals(
        tokenBalances[token.underlying == undefined ? token.address : token.underlying!],
        token.decimals
      ))
  );
  tokenData.map(token => (token["safetyRatio"] = format(token["safetyRatio"])));

  const depositedCollateral = tokenData.filter(
    token => token.walletBalance !== 0 || token.troveBalance !== 0
  );

  const heldCollateral = tokenData.filter(token => token.troveBalance !== 0);

  const collateralizationRatio = format(trove.collateralRatio(price, safetyRatios).mul(100));

  const totalBorrowed = format(trove.debt["debt"]);

  console.log("Yeti airdrop secret link: https://bit.ly/IqT6zt");

  let vcValue: number = 0;
  let usdValue: number = 0;
  let stableVC: number = 0;
  let stableUSD: number = 0;
  tokenData.map(token => {
    const vc = format(price[token.address]) * token.safetyRatio * formatWithDecimals(trove.collaterals[token.address], token.decimals)
    vcValue += vc 
    

    const usd = format(price[token.address]) * formatWithDecimals(trove.collaterals[token.address], token.decimals)
    usdValue += usd

    if (token.isStable) {
      stableVC += vc
      stableUSD += usd
    }
      
  });

  const getCollateralRatioDisplay = (collateralRatio: number) => {
    if (collateralRatio < 130) {
      return ["RedThermometer", "red.500"];
    } else if (collateralRatio < 170) {
      return ["YellowThermometer", "yellow.500"];
    } else {
      return ["GreenThermometer", "green.500"];
    }
  };

  const getTroveRiskynessMsg = () => {
    const riskeyness = (vcValue - stableVC) / (totalBorrowed - stableUSD) * 100
    const precentStable = stableVC / vcValue * 100
    let safteyLable:string;
    let amountRoom:string;
    if (stableUSD > totalBorrowed) {
      if (collateralizationRatio < 1.12) {
        safteyLable = "risky"
        amountRoom = "not much"
      } else if (collateralizationRatio < 1.14) {
        safteyLable = "medium risk"
        amountRoom = "some"
      } else {
        safteyLable = "safe"
        amountRoom = "a lot of"
      }
    } else if (riskeyness < 130) {
      safteyLable = "risky"
      amountRoom = "not much"
    } else if (riskeyness < 170) {
      safteyLable = "low-medium risk"
      amountRoom = "some"
    } else {
      safteyLable = "safe"
      amountRoom = "a lot of"
    }
    return `Your trove is comprised of ${precentStable.toFixed(3)}% stables${riskeyness>0 ? `, with an ICR without stable coins of ${(riskeyness).toFixed(3)}%.`: "."} We deem this as ${safteyLable} since there is ${amountRoom} room for your collateral prices to fall before reaching the liquidation threshold of 110%.`
  }

  let totalSystemVC = 0;
  Object.keys(total.collaterals).map(
    address =>
      (totalSystemVC +=
        tokenSafetyMapping[address] !== undefined
          ? format(total.collaterals[address]) * format(price[address]) * tokenSafetyMapping[address]
          : 0)
  );
  console.log("totalSystemVC", totalSystemVC);
  const totalSystemRatio = totalSystemVC / format(total.debt["debt"]);

  return (
    <Box layerStyle="base" flex={1} px={2}>
      <Text textStyle="title3" textAlign={["center", "left"]} pb={6} px={6}>
        Borrow Summary
      </Text>
      <Flex direction={["column", "row"]} mb={5} px={6}>
        
        <StatColumn
          iconName={getCollateralRatioDisplay(collateralizationRatio)[0]}
          amount={`${collateralizationRatio.toFixed(3)}%`}
          description="Collateralization Ratio"
          color={getCollateralRatioDisplay(collateralizationRatio)[1]}
          tooltip="Ratio of risk adjusted value in trove to debt"
          msg={getTroveRiskynessMsg()}
        />
        <StatColumn
          iconName="Bank"
          amount={`$${getNum(totalBorrowed * +String(YUSDPrice), 2)}`}
          description="Borrowed YUSD"
          secondDescription="(in USD)"
        />
        <StatColumn
          iconName="MoneyStack"
          amount={`$${getNum(usdValue, 2)}`}
          description="Total Collateral"
        />
      </Flex>
      <Flex px={6} mb={2}>
        <Text textStyle="subtitle1" fontWeight="normal">
          Risk Adjusted Value: <Tooltip>{VC_explanation}</Tooltip>
        </Text>
        <Spacer />
        <Text textStyle="subtitle1">{getNum(vcValue, 2)}</Text>
      </Flex>
      <Flex px={6} mb={1}>
        <Text textStyle="subtitle1" fontWeight="normal">
          System Collateral Ratio:
        </Text>
        <Spacer />
        <Text textStyle="subtitle1">{(totalSystemRatio * 100).toFixed(3)}%</Text>
      </Flex>
      <Flex px={6} mb={2.5}>
        <Text textStyle="subtitle3" fontWeight="normal">
          {totalSystemRatio < 1.5 ? "Recovery Mode ( < 150%)" : "Normal Mode ( > 150%)"}
        </Text>
      </Flex>
      <TokenTable
        headers={["Token", "Wallet Balance", "Trove Balance"]}
        // tooltips={["placeholder", "placeholder", "placeholder"]}
        width={5}
        display={["none", "block"]}
      >
        <>
          {depositedCollateral.map(token => (
            <Tr key={token.token + token.walletBalance}>
              <Td pb={0} pt={4}>
                <Flex align="center">
                  <Icon iconName={token.token} h={5} w={5} />
                  <Text ml={3}>{token.token}</Text>
                </Flex>
              </Td>
              {[...new Array(2)].map(_ => (
                <Td pb={0} pt={4} />
              ))}
              <Td pb={0} pt={4}>
                {getNum(token.walletBalance)}
              </Td>
              <Td pb={0} pt={4}>
                {getNum(token.troveBalance)}
              </Td>
            </Tr>
          ))}
        </>
      </TokenTable>

      {/* Mobile Table */}
      {heldCollateral.length !== 0 && (
        <TokenTable
          headers={["Token", "Trove Balance"]}
          // tooltips={["placeholder", "placeholder"]}
          width={2}
          display={["block", "none"]}
        >
          <>
            {heldCollateral.map(token => (
              <Tr key={token.token + token.walletBalance}>
                <Td pb={0} pt={4}>
                  <Flex align="center">
                    <Icon iconName={token.token} h={5} w={5} />
                    <Text ml={3}>{token.token}</Text>
                  </Flex>
                </Td>
                <Td pb={0} pt={4}>
                  {getNum(token.troveBalance)}
                </Td>
              </Tr>
            ))}
          </>
        </TokenTable>
      )}
    </Box>
  );
};

export default BorrowSummary;
