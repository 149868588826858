import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Tag,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Toggle, AdjustInput, CoinAmount } from "../../../Components";
import Tooltip from "../../../Components/Tooltip";
import ConfirmVEStakeModal from "../ConfirmvVEStakeModal";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { capitalizeFirstLetter } from "../../../Utils/string";
import { adjustValue, newWeeklyAPR, getNum, format } from "../../../Utils/number";
import { validateDeposit } from "../../../Utils/validation";
import { Form } from "react-final-form";
import Warning from "../WarningModal/Warning";

export type VEStakeCardProps = {
  disconnected?: boolean;
};

const selector = ({
  yetiStake,
  yetiBalance,
  totalStakedYETI,
  totalSYETISupply,
  veYETIStaked
}: LiquityStoreState) => ({
  yetiStake,
  yetiBalance,
  totalStakedYETI,
  totalSYETISupply,
  veYETIStaked
});

const calculateAPR = (totalStakedYETI: Decimal, totalSYETISupply: Decimal): number => {
  return format(totalSYETISupply) / format(totalStakedYETI);
};

var dataSelector = useLiquitySelector;

const VEStakeCard: React.FC<VEStakeCardProps> = ({ disconnected = false }) => {
  let yetiStake, yetiBalance, yetiStaked: number, yetiAPR: number;
  let userYetiBalance: any;
  let maxStake: string = "";
  if (!disconnected) {
    const { yetiStake, yetiBalance, veYETIStaked } = dataSelector(selector);
    const { totalStakedYETI, totalSYETISupply } = dataSelector(selector);
    maxStake = String(veYETIStaked.yetiStake);
    userYetiBalance = format(yetiBalance);
    yetiStaked = format(veYETIStaked.yetiStake);
    yetiAPR = 1.125; // calculateAPR(totalStakedYETI, totalSYETISupply);
  } else {
    userYetiBalance = 1000;
    yetiStaked = 0;
    yetiAPR = 1.125;
  }

  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const toast = useToast();

  const [mode, setMode] = useState<"deposit" | "withdraw">("deposit");

  const validate = (valueChange: number) => {
    validateDeposit(toast, mode, userYetiBalance, yetiStaked, valueChange, onConfirmOpen);
  };

  return (
    <>
      <Box layerStyle="card" flex={1}>
        <Text textStyle="title3" mb={2}>
          veYETI
        </Text>

        <Toggle
          options={[
            { value: "Stake", key: "deposit" },
            { value: "Unstake", key: "withdraw" }
          ]}
          size="md"
          onChange={v => setMode(v as "deposit" | "withdraw")}
        />
        <Form
          onSubmit={() => {}}
          render={({ values }) => (
            <>
              {!disconnected && (
                <ConfirmVEStakeModal
                  isOpen={isConfirmOpen}
                  onClose={onConfirmClose}
                  mode={mode}
                  amount={values.vestakeInput || "0"}
                  total={adjustValue(mode, yetiStaked, values.vestakeInput)}
                />
              )}

              {/* {!disconnected && (
                <Warning
                  isOpen={isConfirmOpen}
                  onClose={onConfirmClose}
                  mode={mode}
                  amount={values.vestakeInput || "0"}
                  total={adjustValue(mode, yetiStaked, values.vestakeInput)}
                />
              )} */}

              <AdjustInput
                mt={4}
                max={mode === "deposit" ? userYetiBalance : maxStake}
                name="vestakeInput"
                token="YETI"
                showToken
                fillContainer
              />
              {mode === "deposit" ? (<Text textStyle="body1" fontWeight="bold" mt={1.5}>
                Wallet Balance: {getNum(userYetiBalance)} YETI
              </Text>) : (<Text textStyle="body1" fontWeight="bold" mt={1.5}>
                Staked Balance: {getNum(yetiStaked)} YETI
              </Text>)}
              <Box>
                <Flex mt={4}>
                  <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                    {"New Staked Amount"}
                  </Text>
                  <Spacer />
                  <CoinAmount
                    token="YETI"
                    amount={adjustValue(mode, yetiStaked, values.vestakeInput)}
                    textStyle="subtitle1"
                    color="white"
                  />
                </Flex>
                <Flex mt={4}>
                  <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                    {"New hourly veYeti Accumulation "}
                    <Tooltip>Estimated amount of rewards you will receive in a week based on your deposit</Tooltip>
                  </Text>
                  <Spacer />
                  <CoinAmount
                    token="veYETI"
                    amount={adjustValue(mode, yetiStaked, values.vestakeInput) * 0.015}
                    textStyle="subtitle1"
                    color="green.400"
                    noCurrencyConvert={true}
                  />
                </Flex>
              </Box>
              <Divider color="brand.600" mt={4} />

              {/* <Flex mt={4}>
                <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                  {"veYETI Growth Rate "}
                  <Tooltip>Estimated amount of rewards you will receive in a week based on your deposit</Tooltip>
                </Text>
                <Spacer />

                <Tag bgColor="secondary.400">
                  <Text textStyle="subtitle1">{(yetiAPR * 100).toFixed(3)}%</Text>
                </Tag>
              </Flex> */}

              {!disconnected && (
                <Flex mt={4} justify="flex-end">
                  <Button colorScheme="brand" onClick={() => validate(values.vestakeInput)}>
                    {mode == "deposit" ? "Stake" : "Unstake"}
                  </Button>
                </Flex>
              )}
            </>
          )}
        />
      </Box>
    </>
  );
};

export default VEStakeCard;
