import React from "react";
import { Box, Flex, Spacer, Text } from "@chakra-ui/react";
import CoinAmount from "../../../Components/CoinAmount";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { getBalanceInfo } from "./YourVEBalance.utils";
import { newWeeklyAPR, format } from "../../../Utils/number";
import Tooltip from "../../../Components/Tooltip";

const selector = ({ veYETIStaked, totalStakedYETI, totalSYETISupply }: LiquityStoreState) => ({
  veYETIStaked,
  totalStakedYETI,
  totalSYETISupply
});

const calculateAPR = (totalSYETISupply: Decimal, totalStakedYETI: Decimal): number => {
  return 1.125;
};

const YourveBalanceCard: React.FC = () => {
  const { veYETIStaked, totalStakedYETI, totalSYETISupply } = useLiquitySelector(selector);
  const yetiStaked = format(veYETIStaked.yetiStake);

  let stakeShare: number;
  if (
    Number(veYETIStaked.veYETIGain.toString()) > 0 &&
    Number(veYETIStaked.veYETITotal.toString()) > 0
  ) {
    stakeShare = format(veYETIStaked.veYETIGain.div(veYETIStaked.veYETITotal)) * 100;
  } else {
    stakeShare = 0;
  }

  const yetiAPR = calculateAPR(totalSYETISupply, totalStakedYETI);
  const weeklyRewards = newWeeklyAPR("deposit", yetiStaked, "0", yetiAPR);
  const balanceInfo = getBalanceInfo(
    yetiStaked,
    stakeShare,
    weeklyRewards,
    format(veYETIStaked.veYETIGain)
  );

  return (
    <Box layerStyle="card" flex={1}>
      <Text textStyle="title3" mb={5}>
        Your veYETI Balance
      </Text>
      <Box>
        {balanceInfo.map(({ tooltip, value, percent, title }) => (
          <Flex direction="row" mt={4}>
            <Text textStyle="subtitle1" fontWeight="normal">
              {title + " "}
              {tooltip !== undefined && <Tooltip>{tooltip}</Tooltip>}
            </Text>
            <Spacer />
            {percent !== undefined && <Text textStyle="subtitle1">{percent.toFixed(3)}%</Text>}
            {value !== undefined && title === "Total Amount Staked" && (
              <CoinAmount token="YETI" amount={value} textStyle="subtitle1" color="white" />
            )}
            {value !== undefined && title != "Total Amount Staked" && (
              <CoinAmount
                token="veYETI"
                amount={value}
                textStyle="subtitle1"
                color="white"
                noCurrencyConvert={true}
              />
            )}
          </Flex>
        ))}
      </Box>
    </Box>
  );
};

export default YourveBalanceCard;
