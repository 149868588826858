import React from "react";
import { Button, Flex } from "@chakra-ui/react"
import Tooltip from "../../../../Components/Tooltip";
import { useTransactionFunction } from "../../../../Components/Transaction";
import { useLiquity } from "../../../../Hooks/LiquityContext";
import { getNum } from "../../../../Utils/number";

export type PoolSwapProps = {
  minAmount: number
  dollarTotal: number
  close: () => void
};

const PoolSwap: React.FC<PoolSwapProps> = ({ minAmount, dollarTotal, close }) => {
  const { liquity } = useLiquity();
  const onSubmit = (): void => {
    console.log('dollarTotal', dollarTotal);
    console.log('minAmount', minAmount, Math.round(dollarTotal * (1 - minAmount)));
    sendTransaction();
    close();
  };
  const [sendTransaction] = useTransactionFunction(
    "stability-deposit",
    liquity.send.claimRewardsSwap.bind(liquity.send, 47520)
  );
  return (
    <Button variant="primary" mr={8} onClick={onSubmit}>
      Auto-Compound {<Flex ml={1}><Tooltip>Use existing collateral rewards to swap for YUSD, which will be redeposited in the pool for you to maximize future YETI and liquidation gains.</Tooltip></Flex>}
    </Button>
  );
};

export default PoolSwap;
