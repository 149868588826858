import { TroveMappings, Decimal } from "@liquity/lib-base";
import { TokenData, TokenDataMapping } from "../Types";

const tokenData: TokenData[] = [
  {
    address: "0x12fF866E8B704986138573Cca4543ef09FF3210E",
    safetyRatio: 0.75,
    recoveryRatio: 0.75,
    troveBalance: 0,
    walletBalance: 0,
    token: "JOE",
    decimals: 18,
    isStable: false,
    apr: 100
  },
  {
    address: "0xf35563f2A2E018e4b5b8719121B7B646c95e5220",
    safetyRatio: 0.8,
    recoveryRatio: 0.8,
    troveBalance: 0,
    walletBalance: 0,
    token: "WETH-WAVAX JLP",
    decimals: 18,
    isStable: false,
    apr: 100
  },
  {
    address: "0xccD322422ad55aA5C0D380001A01a5d445118DED",
    safetyRatio: 0.5,
    recoveryRatio: 0.5,
    troveBalance: 0,
    walletBalance: 0,
    token: "DANGER",
    decimals: 18,
    isStable: false,
    apr: 100
  },
  {
    address: "0x02823f9B469960Bb3b1de0B3746D4b95B7E35543",
    safetyRatio: 1,
    recoveryRatio: 1.55,
    troveBalance: 0,
    walletBalance: 0,
    token: "USDT",
    decimals: 6,
    isStable: true,
    apr: 100
  },
  {
    address: "0x4D17c0C31aA199CBe64af2E7E6dF046D6824B7Da",
    safetyRatio: 1.05,
    recoveryRatio: 1.55,
    troveBalance: 0,
    walletBalance: 0,
    token: "qiUSDC",
    decimals: 18,
    isStable: true,
    apr: 100
  },
  {
    address: "0x03a01753831fc9440004d1730F372386eB03eA43",
    safetyRatio: 1,
    recoveryRatio: 1,
    troveBalance: 0,
    walletBalance: 0,
    token: "WAVAX",
    decimals: 18,
    isStable: false,
    apr: 100
  },
  {
    address: "0xdFFf5a4C84340217c94778339F34fe01C7185088",
    safetyRatio: 1,
    recoveryRatio: 1,
    troveBalance: 0,
    walletBalance: 0,
    token: "WETH",
    decimals: 18,
    isStable: false,
    apr: 100
  },
  {
    address: "0x2E946D8194b0F4Ae645Dd9f913f11697818A31D5",
    safetyRatio: 1,
    recoveryRatio: 1,
    token: "VAULT_OLD",
    troveBalance: 0,
    walletBalance: 0,
    decimals: 18,
    isStable: false,
    apr: 100
  },
  {
    address: "0x9B275F85a71e7412C0860C7d2802d53F1fca924F",
    safetyRatio: 1,
    recoveryRatio: 1,
    token: "VAULT WAVAX",
    underlying: "0x03a01753831fc9440004d1730F372386eB03eA43",
    troveBalance: 0,
    walletBalance: 0,
    decimals: 18,
    isStable: false,
    apr: 100
  }
  // {
  //   address: "0x91F058eB0F9F34C26e4428cB2f084C63c354f4F5",
  //   safetyRatio: 0.1,
  //   recoveryRatio: 0.1,
  //   token: "TEST",
  //   troveBalance: 0,
  //   walletBalance: 0,
  //   decimals: 18
  // }
];

export const tokenDataOrder: { [key: string]: number } = {
  JOE: 0,
  "WETH-WAVAX JLP": 1,
  DANGER: 2,
  USDT: 3,
  qiUSDC: 4,
  WAVAX: 5,
  WETH: 6,
  VAULT_OLD: 8,
  "VAULT WAVAX": 8
};

export let tokenDataMapping: TokenDataMapping = {};
tokenData.map(
  token =>
    (tokenDataMapping[token.address] = {
      token: token.token,
      safetyRatio: token.safetyRatio,
      decimals: token.decimals
    })
);

export let tokenDataDecimals: TroveMappings = {};
tokenData.map(({ address, decimals }) => (tokenDataDecimals[address] = Decimal.from(decimals)));

export let tokenSafetyMapping: { [key: string]: number } = {};
tokenData.map(({ address, safetyRatio }) => (tokenSafetyMapping[address] = safetyRatio));

export default tokenData;
