import React from "react";

import TokenTable from "../../../Components/TokenTable";
import Icon from "../../../Components/Icon";
import { useTransactionFunction } from "../../../Components/Transaction";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { Flex, Tr, Td } from "@chakra-ui/react";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/modal";
import { Button, Text } from "@chakra-ui/react";

export type MintModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const testnetTokens = [
  {
    token: "WETH-WAVAX JLP",
    safetyRatio: 0.8,
    address: "0xf35563f2A2E018e4b5b8719121B7B646c95e5220"
  },
  { token: "JOE", safetyRatio: 0.75, address: "0x12fF866E8B704986138573Cca4543ef09FF3210E" },
  { token: "DANGER", safetyRatio: 0.5, address: "0xccD322422ad55aA5C0D380001A01a5d445118DED" },
  { token: "qiUSDC", safetyRatio: 1.05, address: "0x4D17c0C31aA199CBe64af2E7E6dF046D6824B7Da" },
  { token: "WAVAX", safetyRatio: 1.0, address: "0x03a01753831fc9440004d1730F372386eB03eA43" },
  { token: "WETH", safetyRatio: 1.0, address: "0xdFFf5a4C84340217c94778339F34fe01C7185088" },
  { token: "YUSD", safetyRatio: "N/A", address: "0xe6BbfD5be4B5AD13302FD5764dFF8e4f604d4887" },
  { token: "CLP", safetyRatio: "N/A", address: "0x9D0782A36A045fe96609D87B4f014590FFB5B455" }
];

const MintModal: React.FC<MintModalProps> = ({ isOpen, onClose }) => {
  const { liquity } = useLiquity();

  const [mintQiUSDC] = useTransactionFunction(
    "mintQiUSDC",
    liquity.send.mintToken.bind(liquity.send, testnetTokens[3].address)
  );
  const [mintWAVAX] = useTransactionFunction(
    "mintWAVAX",
    liquity.send.mintToken.bind(liquity.send, testnetTokens[4].address)
  );
  const [mintWETH] = useTransactionFunction(
    "mintWETH",
    liquity.send.mintToken.bind(liquity.send, testnetTokens[5].address)
  );
  const [mintJOE] = useTransactionFunction(
    "mintJOE",
    liquity.send.mintToken.bind(liquity.send, testnetTokens[1].address)
  );
  const [mintJLP] = useTransactionFunction(
    "mintJLP",
    liquity.send.mintToken.bind(liquity.send, testnetTokens[0].address)
  );
  const [mintDANGER] = useTransactionFunction(
    "mintDANGER",
    liquity.send.mintToken.bind(liquity.send, testnetTokens[2].address)
  );

  const [mintYUSD] = useTransactionFunction("mintYUSD", liquity.send.mintYUSD.bind(liquity.send));

  const [mintCLP] = useTransactionFunction(
    "mintCLP",
    liquity.send.mintToken.bind(liquity.send, testnetTokens[7].address)
  );

  let mintFunctions = new Map<string, any>();
  mintFunctions.set("qiUSDC", mintQiUSDC);
  mintFunctions.set("WAVAX", mintWAVAX);
  mintFunctions.set("WETH", mintWETH);
  mintFunctions.set("JOE", mintJOE);
  mintFunctions.set("WETH-WAVAX JLP", mintJLP);
  mintFunctions.set("DANGER", mintDANGER);
  mintFunctions.set("YUSD", mintYUSD);
  mintFunctions.set("CLP", mintCLP);

  const onSubmit = (token: string) => {
    const send = mintFunctions.get(token);
    send();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl" pt={7} pb={1}>
          YETI Fuji Testnet Faucet
        </ModalHeader>
        <ModalBody pb={10} px={0}>
          <Text
            textStyle="hover"
            px={6}
            pb={2}
            _hover={{ color: "#4a97fe" }}
            onClick={() => window.open("https://faucet.avax-test.network/", "_blank")}
          >
            Click here to mint native testnet AVAX to pay for gas fees.
          </Text>
          <Text px={6}>Each collateral can only be minted once per address!</Text>
          <TokenTable headers={["Token", "Safety Ratio", "Mint"]} width={3}>
            <>
              {/*instead of borrowed tokens, should be trove.collaterals, which is a TroveMapping of each collateral address and amount borrowed against
              . To get wallet balance, use variable tokenBalances, which is Trovemapping of each collat address and amount in wallets*/}
              {testnetTokens.map(coin => (
                <Tr key={coin.token + coin.safetyRatio}>
                  <Td pb={0} pt={4}>
                    <Flex align="center">
                      <Icon iconName={coin.token} h={5} w={5} />
                      <Text ml={3}>{coin.token}</Text>
                    </Flex>
                  </Td>
                  <Td pb={0} pt={4}>
                    {coin.safetyRatio}
                  </Td>
                  <Td pb={0} pt={4}>
                    <Button variant="primary" onClick={() => onSubmit(coin.token)}>
                      Mint
                    </Button>
                  </Td>
                </Tr>
              ))}
            </>
          </TokenTable>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MintModal;
