import React, { useState, useEffect } from "react";
import { PageWrapper, Navbar, LiquidationEvent, Popup } from "./Components";
import { Dashboard, Stake, Borrow, Pool, Loading, LiquidationCalculator } from "./Screens";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { useLiquity } from "./Hooks/LiquityContext";
import { LiquityStoreProvider } from "@liquity/lib-react";
import { StabilityViewProvider } from "./PageComponents/Pool/context/StabilityViewProvider";
import { QueryClient, QueryClientProvider } from "react-query";
import { TransactionMonitor } from "./Components/Transaction";
import { useDisclosure } from "@chakra-ui/react";
import TestnetBanner from "./Components/TestnetBanner";

const YetiFrontend: React.FC = () => {
  const { liquity, account } = useLiquity();
  const [snow, setSnow] = useState<0 | 1>(0);
  const queryClient = new QueryClient();

  const toggleSnow = () => {
    if (snow === 0) {
      setSnow(1);
    } else {
      setSnow(0);
    }
  };
  let agreedToDisclaimer;
  
  if (localStorage.getItem(account+"agreedToYetiFinanceDisclaimer") == undefined) {
    agreedToDisclaimer = true;
  } else {
    agreedToDisclaimer = false;
  }
  const { isOpen: isAddCollateralTypeOpen, onClose: onAddCollateralTypeClose } = useDisclosure({
    defaultIsOpen: true
  });

  return (
    <LiquityStoreProvider loader={<Loading />} store={liquity.store}>
      <QueryClientProvider client={queryClient}>
        <Router>
          <StabilityViewProvider>
            <TestnetBanner />
            <LiquidationEvent />
            {agreedToDisclaimer && (
              <Popup isOpen={isAddCollateralTypeOpen} onClose={onAddCollateralTypeClose} />
            )}
            <PageWrapper snow={snow}>
              <Navbar
                walletConnected={true}
                dashboardVisible={true}
                snow={snow}
                setSnow={toggleSnow}
              />
              <Switch>
                <Route path="/borrow">
                  <Borrow />
                </Route>
                <Route path="/farm">
                  <Pool />
                </Route>
                <Route path="/stake">
                  <Stake />
                </Route>
                <Route path="/calculator">
                  <LiquidationCalculator />
                </Route>
                <Route path="/">
                  <Dashboard />
                </Route>
              </Switch>
            </PageWrapper>
          </StabilityViewProvider>
        </Router>
      </QueryClientProvider>
      <TransactionMonitor />
    </LiquityStoreProvider>
  );
};

export default YetiFrontend;
