import {
  Box,
  useDisclosure,
  Flex,
  Tr,
  Td,
  Input,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Button,
  Divider,
  NumberInput,
  NumberInputField,
  SliderMark
} from "@chakra-ui/react";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import React, { useEffect, useState } from "react";
import { TokenTable, Header, Icon } from "../../../Components";
import { CoinShow, TokenData } from "../../../Types";
import { format } from "../../../Utils/number";
import AddCollateralTypeModal from "../../Borrow/AddCollateralTypeModal";
import { getNum } from "../../utils";
import OverallStats from "../OverallStats";

type CollateralCalculatorProps = {
  collateral: TokenData[];
};

type CollateralStats = {
  adjustedPrice: number;
  adjustedPriceString: string;
  weightedCollateralValue: number;
  maxAdjustedPrice: number;
  minAdjustedPrice: number;
  adjustmentStep: number;
};

export type AdjustedCollateral = TokenData &
  CollateralStats & {
    troveBalanceString: string;
    price: number;
  };

export type OverallCollateralStats = CollateralStats;

type CalculatorState = {
  adjustedCollaterals: AdjustedCollateral[];
  overallStats: CollateralStats;
};

// this can be made more generic to handle stable coins and moved to a global util file
export const isStableCoin = (coin: TokenData) => coin.token === "qiUSDC";
export const getOverallWeightedValue = (collaterals: AdjustedCollateral[]) => {
  const value = collaterals.reduce((acc, item) => acc + item.weightedCollateralValue, 0);

  return value;
};

const selector = ({ trove, price }: LiquityStoreState) => ({
  trove,
  price
});

const CollateralCalculator: React.FC<CollateralCalculatorProps> = ({ collateral }) => {
  const {
    isOpen: isAddCollateralTypeOpen,
    onOpen: onAddCollateralTypeOpen,
    onClose: onAddCollateralTypeClose
  } = useDisclosure();

  const { trove, price } = useLiquitySelector(selector);

  const coinShow: CoinShow = {};
  collateral.forEach(coin => {
    if (coin.troveBalance === 0 || coin.token == "BC" || coin.token == "USDT") {
      coinShow[coin.token] = false;
    } else {
      coinShow[coin.token] = true;
    }
  });

  const [show, setShow] = useState<CoinShow>(coinShow);

  const initialAdjustedCollateral: AdjustedCollateral[] = collateral
    .filter(coin => show[coin.token])
    .map(coin => {
      const coinPrice = format(price[coin.address]);
      const priceAdjustmentSpread = isStableCoin(coin)
        ? coinPrice * 1.25 - coinPrice * 0.75
        : coinPrice * 5 - coinPrice;

      return {
        ...coin,
        price: coinPrice,
        troveBalanceString: coin.troveBalance.toString(),
        adjustedPrice: coinPrice,
        adjustedPriceString: coinPrice.toFixed(3),
        weightedCollateralValue: coin.troveBalance * coinPrice * coin.safetyRatio,
        maxAdjustedPrice: isStableCoin(coin) ? coinPrice * 1.25 : coinPrice * 6,
        minAdjustedPrice: isStableCoin(coin) ? coinPrice * 0.75 : 0,
        adjustmentStep: priceAdjustmentSpread / 20
      };
    });

  const initialCalculatorState: CalculatorState = {
    adjustedCollaterals: initialAdjustedCollateral,
    overallStats: {
      adjustedPrice: 0,
      adjustedPriceString: "0",
      weightedCollateralValue: getOverallWeightedValue(initialAdjustedCollateral),
      maxAdjustedPrice: 500,
      minAdjustedPrice: -100,
      adjustmentStep: 5
    }
  };

  const [calculatorState, setCalculatorState] = useState<CalculatorState>(initialCalculatorState);

  let availableCollateral: TokenData[] = collateral.filter(coin => !show[coin.token]);

  const handleCollateralChange = (collateral: AdjustedCollateral, index: number) => {
    const newCollaterals = [...calculatorState.adjustedCollaterals];
    const originalTotalPrice = calculatorState.adjustedCollaterals.reduce(
      (price, collateralItem) => price + collateralItem.price,
      0
    );

    collateral.weightedCollateralValue =
      collateral.troveBalance * format(collateral.adjustedPrice) * collateral.safetyRatio;

    newCollaterals[index] = collateral;

    const adjustedTotalPrice = newCollaterals.reduce(
      (price, collateralItem) => price + collateralItem.adjustedPrice,
      0
    );

    setCalculatorState({
      adjustedCollaterals: newCollaterals,
      overallStats: {
        ...calculatorState.overallStats,
        weightedCollateralValue: getOverallWeightedValue(newCollaterals),
        adjustedPrice: ((adjustedTotalPrice - originalTotalPrice) / originalTotalPrice) * 100
      }
    });
  };

  useEffect(() => {
    const oldTokens = new Set(
      calculatorState.adjustedCollaterals.map(collateral => collateral.token)
    );
    const addedTokens = new Set(
      Object.entries(show)
        .filter(([token, isShown]) => !!isShown && !oldTokens.has(token))
        .map(([token]) => token)
    );

    availableCollateral = collateral.filter(coin => !show[coin.token]);

    const newCollaterals: AdjustedCollateral[] = collateral
      .filter(coin => addedTokens.has(coin.token))
      .map(coin => {
        const coinPrice = format(price[coin.address]);
        const priceAdjustmentSpread = isStableCoin(coin)
          ? coinPrice * 1.25 - coinPrice * 0.75
          : coinPrice * 5 - coinPrice;

        return {
          ...coin,
          price: coinPrice,
          adjustedPrice: coinPrice,
          troveBalanceString: coin.troveBalance.toString(),
          adjustedPriceString: coinPrice.toString(),
          weightedCollateralValue: coin.troveBalance * coinPrice * coin.safetyRatio,
          maxAdjustedPrice: isStableCoin(coin) ? coinPrice * 1.25 : coinPrice * 6,
          minAdjustedPrice: isStableCoin(coin) ? coinPrice * 0.75 : 0,
          adjustmentStep: priceAdjustmentSpread / 20
        };
      });

    if (!newCollaterals.length) {
      return;
    }

    const newAdjustedCollaterals = [...calculatorState.adjustedCollaterals, ...newCollaterals];

    const originalTotalPrice = newAdjustedCollaterals.reduce(
      (price, collateralItem) => price + collateralItem.price,
      0
    );

    const adjustedTotalPrice = newAdjustedCollaterals.reduce(
      (price, collateralItem) => price + collateralItem.adjustedPrice,
      0
    );

    setCalculatorState({
      adjustedCollaterals: newAdjustedCollaterals,
      overallStats: {
        ...calculatorState.overallStats,
        weightedCollateralValue: getOverallWeightedValue(newAdjustedCollaterals),
        adjustedPrice: ((adjustedTotalPrice - originalTotalPrice) / originalTotalPrice) * 100
      }
    });
  }, [show]);

  return (
    <>
      <AddCollateralTypeModal
        isOpen={isAddCollateralTypeOpen}
        onClose={onAddCollateralTypeClose}
        show={show}
        setShow={setShow}
        availableCollateral={availableCollateral}
        borrowMode="normal"
      />

      <Box>
        <Flex direction={["column", null, "row"]} flex={1} mt={6}>
          <Flex flex={1} mr={[0, null, 3]}>
            <TokenTable
              headers={[
                "Collateral",
                "Balance",
                "Current Price",
                "Price Adjuster",
                "Price",
                "",
                "Collateral Weight",
                "",
                "Weighted Collateral Value"
              ]}
              width={9}
            >
              {calculatorState.adjustedCollaterals.map((item, index) => (
                <Tr key={index}>
                  <Td pt={8} pb={2}>
                    <Flex align="center" w={28}>
                      <Text ml={3} whiteSpace="pre-wrap">
                        {item.token}
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={8} pb={2} pl={2}>
                    <Flex align="center">
                      <NumberInput
                        precision={3}
                        value={item.troveBalanceString}
                        onChange={val => {
                          handleCollateralChange(
                            {
                              ...item,
                              troveBalance: parseFloat(val),
                              troveBalanceString: val
                            },
                            index
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </Flex>
                  </Td>
                  <Td pt={8} pb={2} pl={2}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap">
                        {item.price.toFixed(3)}
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={8} pb={2} pl={2}>
                    <Flex align="center" w={60} pr={6}>
                      <Slider
                        focusThumbOnChange={false}
                        aria-label="slider-ex-6"
                        value={item.adjustedPrice}
                        min={item.minAdjustedPrice}
                        max={item.maxAdjustedPrice}
                        step={item.adjustmentStep}
                        onChange={val => {
                          handleCollateralChange(
                            {
                              ...item,
                              adjustedPrice: val,
                              adjustedPriceString: val.toString()
                            },
                            index
                          );
                        }}
                      >
                        <SliderMark
                          value={item.minAdjustedPrice}
                          mt="1"
                          ml="-2.5"
                          fontSize="x-small"
                        >
                          {isStableCoin(item) ? "0.75X" : "0X"}
                        </SliderMark>
                        <SliderMark
                          value={item.maxAdjustedPrice}
                          mt="1"
                          ml="-2.5"
                          fontSize="x-small"
                        >
                          {isStableCoin(item) ? "1.25X" : "5X"}
                        </SliderMark>
                        <SliderTrack>
                          <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb />
                      </Slider>
                    </Flex>
                  </Td>
                  <Td pt={8} pb={2} pl={2}>
                    <Flex align="center">
                      <NumberInput
                        precision={3}
                        value={item.adjustedPriceString}
                        onChange={val => {
                          handleCollateralChange(
                            {
                              ...item,
                              adjustedPrice: parseFloat(val),
                              adjustedPriceString: val.toString()
                            },
                            index
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </Flex>
                  </Td>
                  <Td pt={8} pb={2} pl={0} pr={0}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap" color="gray.500">
                        x
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={8} pb={2} pl={2}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap">
                        {item.safetyRatio}
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={8} pb={2} pl={0} pr={0}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap" color="gray.500">
                        =
                      </Text>
                    </Flex>
                  </Td>
                  <Td pt={8} pb={2} pl={2}>
                    <Flex align="center">
                      <Text ml={3} whiteSpace="nowrap">
                        $ {getNum(item.weightedCollateralValue)}
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              ))}
            </TokenTable>
          </Flex>
        </Flex>
        <Flex>
          <Button
            disabled={!availableCollateral.length}
            colorScheme="brand"
            variant="outline"
            _active={{ bg: "transparent" }}
            mt={2}
            mx={6}
            leftIcon={<Icon iconName="BlueAddIcon" />}
            onClick={onAddCollateralTypeOpen}
          >
            Add Collateral Type
          </Button>
          <Button
            colorScheme="brand"
            variant="outline"
            _active={{ bg: "transparent" }}
            mt={2}
            leftIcon={<Icon iconName="BlueAddIcon" />}
            onClick={() => {
              setCalculatorState(initialCalculatorState);
            }}
          >
            Reset
          </Button>
        </Flex>
      </Box>
      <Box my={5} px={6}>
        <Divider />
      </Box>
      <OverallStats
        collaterals={calculatorState.adjustedCollaterals}
        overallTroveStats={calculatorState.overallStats}
        setCalculatorState={setCalculatorState}
        debt={format(trove.debt["debt"])}
      />
    </>
  );
};

export default CollateralCalculator;
