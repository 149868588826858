import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Tag,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Toggle, AdjustInput, CoinAmount } from "../../../Components";
import ConfirmDepositModal from "../ConfirmDepositModal";
import { LiquityStoreState, Decimal } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { validateDeposit } from "../../../Utils/validation";
import {
  adjustValue,
  newWeeklyAPR,
  format,
  addString,
  subtractString,
  getNum
} from "../../../Utils/number";
import { capitalizeFirstLetter } from "../../../Utils/string";
import { Form } from "react-final-form";
import Tooltip from "../../../Components/Tooltip";

export type PoolCardProps = {
  disconnected?: boolean;
};

const selector = ({
  stabilityDeposit,
  yusdBalance,
  yusdInStabilityPool,
  remainingStabilityPoolYETIReward
}: LiquityStoreState) => ({
  stabilityDeposit,
  yusdBalance,
  yusdInStabilityPool,
  remainingStabilityPoolYETIReward
});

var dataSelector = useLiquitySelector;

const calculateAPR = (
  yusdInStabilityPool: Decimal,
  remainingStabilityPoolYETIReward: Decimal
): number => {
  const yearlyHalvingSchedule = 0.5;
  const remainingYetiOneYear = remainingStabilityPoolYETIReward.mul(yearlyHalvingSchedule);
  // remainingYetiOneYear * price of Yeti
  const remainingYetiOneYearInUSD = remainingYetiOneYear.mul(1);
  const aprPercentage = remainingYetiOneYearInUSD.div(yusdInStabilityPool).mul(100);

  return parseFloat(aprPercentage.toString());
};

const PoolCard: React.FC<PoolCardProps> = ({ disconnected = false }) => {
  let stabilityDeposit, yusdBalance, yetiAPR: number, userBalance: number, poolDeposit: number;
  const { liquity } = useLiquity();
  if (disconnected) {
    userBalance = 1000;
    poolDeposit = 0;
    yetiAPR = 0;
  } else {
    ({ stabilityDeposit, yusdBalance } = dataSelector(selector));
    const { yusdInStabilityPool, remainingStabilityPoolYETIReward } = dataSelector(selector);
    userBalance = format(yusdBalance);
    poolDeposit = format(stabilityDeposit.currentYUSD);
    yetiAPR = calculateAPR(yusdInStabilityPool, remainingStabilityPoolYETIReward);
  }

  //const [yetiPrice, setYetiPrice] = useState<Decimal | undefined>(undefined);

  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const toast = useToast();

  const [mode, setMode] = useState<"deposit" | "withdraw">("deposit");

  const validate = (valueChange: number) => {
    validateDeposit(toast, mode, userBalance, poolDeposit, valueChange, onConfirmOpen);
  };

  const notNegative = (mode: "deposit" | "withdraw", initialValue: number, valueChange: string) => {
    if (mode === "deposit") {
      return addString(initialValue, valueChange);
    }
    const ans = subtractString(initialValue, valueChange);
    if (ans > 0) {
      return ans;
    }
    return 0;
  };
  const getFormattedValue = (value: string) => {
    if (/^[0-9.]*$/.test(value)) {
      return value;
    }
    return "";
  };
  const [value, setValue] = useState<any>({});
  const [reward, setReward] = useState<Decimal>(Decimal.from(0));
  // useEffect(() => {
  //   if (!isNaN(value.input)) {
  //     liquity
  //       .getEstimatedYETIPoolRewards(+String(value.input) + poolDeposit, 604800)
  //       .then(num => setReward(num));
  //   }
  // }, [value.input, userBalance]);

  return (
    <Box layerStyle="card" flex={1}>
      <Text textStyle="title3" mb={2}>
        Stability Pool {<Tooltip>You will accrue YETI for staking YUSD in the Stability Pool. In case of liquidations, a portion of YUSD from the Stability Pool will be used to offset debt for a portion of the collateral as reward. The liquidation reward at the time of payout can be up to 10%. When liquidations occur, your YUSD amount staked will decrease slightly but you will then have claimable collateral which you can take as reward. The “Auto-compound” button will take all the collateral reward that you have, swap it for YUSD, and redeposit it in the pool for you to maximize future YETI and liquidation gains.</Tooltip>}
      </Text>

      <Toggle
        options={[
          { value: "Stake", key: "deposit" },
          { value: "Unstake", key: "withdraw" }
        ]}
        size="md"
        onChange={v => setMode(v as "deposit" | "withdraw")}
      />
      <Form
        onSubmit={() => {}}
        render={({ values }) => (
          <>
            {setValue(values)}
            {!disconnected && (
              <ConfirmDepositModal
                isOpen={isConfirmOpen}
                onClose={onConfirmClose}
                mode={mode}
                amount={values.input || 0}
                total={adjustValue(mode, poolDeposit, values.input)}
              />
            )}
            <AdjustInput
              mt={4}
              max={mode === "deposit" ? userBalance : poolDeposit}
              name="input"
              token="YUSD"
              showToken
              fillContainer
            />
            {mode === "deposit" ? (<Text textStyle="body1" fontWeight="bold" mt={1.5}>
              Wallet Balance: {getNum(userBalance)} YUSD
            </Text>) : (<Text textStyle="body1" fontWeight="bold" mt={1.5}>
              Pool Balance: {getNum(poolDeposit)} YUSD
            </Text>)}
            <Box>
              <Flex mt={4}>
                <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                  New Total Deposit
                </Text>
                <Spacer />
                <CoinAmount
                  token="YUSD"
                  amount={adjustValue(mode, poolDeposit, values.input)}
                  textStyle="subtitle1"
                  color="white"
                />
              </Flex>
              <Flex mt={4}>
                <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                  New Estimated Weekly Rewards
                </Text>
                <Spacer />
                <CoinAmount
                  token="YETI"
                  amount={newWeeklyAPR(mode, poolDeposit, values.input, yetiAPR)}
                  textStyle="subtitle1"
                  color="green.400"
                />
                {/* <CoinAmount
                  token="YETI"
                  amount={
                    +values.input &&
                    Decimal.from(values.input).lte(Decimal.from(userBalance))
                      ? +String(reward)
                      : 0
                  }
                  textStyle="subtitle1"
                  color="green.400"
                /> */}
              </Flex>
            </Box>

            <Divider color="brand.600" mt={4} />

            {/* Yeti Reward APR */}
            <Flex mt={4}>
              <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                YETI Reward APR
              </Text>
              <Spacer />
              <Tag bgColor="secondary.400">
                <Text textStyle="subtitle1" mr={[5, 0]}>
                  {(yetiAPR * 100).toFixed(3)}%
                  {/* {(+values.input &&
                    Decimal.from(values.input).lte(Decimal.from(userBalance))
                      ? ((+String(reward) * 52) / (+values.input + poolDeposit)) * 100
                      : 0
                    ).toFixed(3)}
                    % */}
                </Text>
              </Tag>
            </Flex>

            {/* Deposit or Withdraw Button */}
            {!disconnected && (
              <Flex mt={4} justify="flex-end">
                <Button colorScheme="brand" onClick={() => validate(values.input)}>
                  {mode == "deposit" ? "Stake" : "Unstake"}
                </Button>
              </Flex>
            )}
          </>
        )}
      />
    </Box>
  );
};

export default PoolCard;
