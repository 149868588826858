import React from "react";
import { Flex, Box, Text, Spacer } from "@chakra-ui/react";
import StatColumn from "../StatColumn";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { format, getNum } from "../../../Utils/number";

const selector = ({ stabilityDeposit, yusdBalance }: LiquityStoreState) => ({
  stabilityDeposit,
  yusdBalance
});

const PoolSummary: React.FC = () => {
  const { stabilityDeposit, yusdBalance } = useLiquitySelector(selector);
  const balance = format(yusdBalance);
  const deposited = format(stabilityDeposit.currentYUSD);

  return (
    <Box layerStyle="base" flex={1}>
      <Text textStyle="title3" textAlign={["center", "left"]} pb={6}>
        Stability Pool Summary
      </Text>
      <Flex>
        <StatColumn
          iconName="YUSD"
          amount={getNum(deposited, 2)}
          units="YUSD"
          description="Deposited in Stability Pool"
        />
        <Spacer />
        <StatColumn
          iconName="YUSD"
          amount={getNum(balance, 2)}
          units="YUSD"
          description="YUSD Balance in Wallet"
        />
      </Flex>
    </Box>
  );
};

export default PoolSummary;
