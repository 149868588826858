import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Tag,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Toggle, AdjustInput, CoinAmount } from "../../../Components";
import Tooltip from "../../../Components/Tooltip";
import ConfirmStakeModal from "../ConfirmStakeModal";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { capitalizeFirstLetter } from "../../../Utils/string";
import { adjustValue, newWeeklyAPR, getNum, format } from "../../../Utils/number";
import { validateDeposit } from "../../../Utils/validation";
import { Form } from "react-final-form";
import { useLiquity } from "../../../Hooks/LiquityContext";
import LPTokenData from "../../../LPTokenData";

export type FarmCardProps = {
  disconnected?: boolean;
};

const selector = ({ farm, lpTokenBalance }: LiquityStoreState) => ({
  farm,
  lpTokenBalance
});

var dataSelector = useLiquitySelector;

const FarmCard: React.FC<FarmCardProps> = ({ disconnected = false }) => {
  let farm, lpStaked: number, yetiAPR: number;
  let userLPBalance: any;
  const [lpBalance, setLPBalance] = useState<Number>(0);
  const { lpTokenBalance } = dataSelector(selector);

  const { liquity } = useLiquity();
  if (!disconnected) {
    ({ farm } = dataSelector(selector));
    userLPBalance = lpBalance;
    lpStaked = format(farm.lpTokenBalance);
    yetiAPR = 1.125; // calculateAPR(totalStakedYETI, totalSYETISupply);
  } else {
    userLPBalance = 1000;
    lpStaked = 0;
    yetiAPR = 1.125;
  }

  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const toast = useToast();

  const [mode, setMode] = useState<"deposit" | "withdraw">("deposit");

  const validate = (valueChange: number) => {
    validateDeposit(toast, mode, userLPBalance, lpStaked, valueChange, onConfirmOpen);
  };

  const [value, setValue] = useState<any>({});
  const [reward, setReward] = useState<Decimal>(Decimal.from(0));
  useEffect(() => {
    if (!isNaN(value.stakeInput)) {
      liquity
        .getEstimatedYETIFarmRewards(+String(value.stakeInput) + lpStaked, 604800)
        .then(num => setReward(num));
    }
    if (lpTokenBalance) {
      setLPBalance(format(lpTokenBalance));
    }
  }, [value.stakeInput, lpTokenBalance]);

  return (
    <>
      <Box layerStyle="card" flex={1}>
        <Text textStyle="title3" mb={2}>
          Stake Curve LP Tokens
        </Text>

        <Toggle
          options={[
            { value: "Stake", key: "deposit" },
            { value: "Unstake", key: "withdraw" }
          ]}
          size="md"
          onChange={v => setMode(v as "deposit" | "withdraw")}
        />
        <Form
          onSubmit={() => {}}
          render={({ values }) => (
            <>
              {setValue(values)}
              {!disconnected && (
                <ConfirmStakeModal
                  isOpen={isConfirmOpen}
                  onClose={onConfirmClose}
                  mode={mode}
                  amount={values.stakeInput || "0"}
                  total={adjustValue(mode, lpStaked, values.stakeInput)}
                />
              )}

              <AdjustInput
                mt={4}
                max={mode === "deposit" ? userLPBalance : lpStaked}
                name="stakeInput"
                token="CLP"
                showToken
                fillContainer
                noCurrencyConvert={true}
              />
              {mode === "deposit" ? (<Text textStyle="body1" fontWeight="bold" mt={1.5}>
                Wallet Balance: {getNum(userLPBalance)} Curve LP Tokens
              </Text>) : (<Text textStyle="body1" fontWeight="bold" mt={1.5}>
                Staked Balance: {getNum(lpStaked)} Curve LP Tokens
              </Text>)}
              <Box>
                <Flex mt={4}>
                  <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                    {"New Staked Amount"}
                  </Text>
                  <Spacer />
                  <CoinAmount
                    token="Curve LP Tokens"
                    amount={adjustValue(mode, lpStaked, values.stakeInput)}
                    textStyle="subtitle1"
                    color="white"
                    noCurrencyConvert={true}
                  />
                </Flex>
                <Flex mt={4}>
                  <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                    {"New Estimated Weekly Rewards "}
                    <Tooltip>Estimated amount of rewards you will receive in a week based on your deposit</Tooltip>
                  </Text>
                  <Spacer />
                  <CoinAmount
                    token="YETI"
                    amount={
                      +values.stakeInput &&
                      Decimal.from(values.stakeInput).lte(Decimal.from(userLPBalance))
                        ? +String(reward)
                        : 0
                    }
                    textStyle="subtitle1"
                    color="green.400"
                  />
                </Flex>
              </Box>
              <Divider color="brand.600" mt={4} />

              <Flex mt={4}>
                <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                  {"YETI Reward APR "}
                  <Tooltip>Estimated amount of rewards you will receive in a week based on your deposit</Tooltip>
                </Text>
                <Spacer />

                <Tag bgColor="secondary.400">
                  <Text textStyle="subtitle1">
                    {(+values.stakeInput &&
                      Decimal.from(values.stakeInput).lte(Decimal.from(userLPBalance))
                        ? ((+String(reward) * 52) / (+values.stakeInput + lpStaked)) * 100
                        : 0
                    ).toFixed(3)}
                    
                    {/* {isNaN(+values.stakeInput) && 
                      (((+String(reward) * 52) / (lpStaked)) * 100).toFixed(3)} */}
                    %
                  </Text>
                </Tag>
              </Flex>

              {!disconnected && (
                <Flex mt={4} justify="flex-end">
                  <Button colorScheme="brand" onClick={() => validate(values.stakeInput)}>
                    {mode == "deposit" ? "Stake" : "Unstake"}
                  </Button>
                </Flex>
              )}
            </>
          )}
        />
      </Box>
    </>
  );
};

export default FarmCard;
