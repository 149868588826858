import { Icon } from "@chakra-ui/react";
const CurveLP = () => (
  <Icon viewBox="-60 -30 450 450">
    <defs>
      <clipPath id="clip-path">
        <rect
          width={600}
          height={600}
          style={{
            fill: "none"
          }}
        />
      </clipPath>
    </defs>
    <title>{"Asset 1"}</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="176.4 253.4 144.8 260.4 140.8 245.6 174.5 239.5 176.4 253.4"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="144.8 260.4 117.2 269.7 111.3 254.3 140.8 245.6 144.8 260.4"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.4 239.5 140.8 245.6 137.3 228.3 172.7 223.1 174.4 239.5"
            style={{
              fill: "#0028ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="140.8 245.6 111.3 254.3 105.9 236.3 137.3 228.3 140.8 245.6"
            style={{
              fill: "#0018ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178.5 264.7 149 272.4 144.7 260.4 176.4 253.4 178.5 264.7"
            style={{
              fill: "#0000f1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="149 272.4 123.6 282.2 117.2 269.7 144.8 260.4 149 272.4"
            style={{
              fill: "#0000da"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="172.7 223.1 137.3 228.3 134.2 209 171.2 204.8 172.7 223.1"
            style={{
              fill: "#0058ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="137.3 228.3 105.9 236.3 101.3 216.1 134.2 209 137.3 228.3"
            style={{
              fill: "#0048ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211 248.9 176.4 253.4 174.5 239.5 210.9 235.8 211 248.9"
            style={{
              fill: "#0014ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="180.8 273 153.4 281.2 149 272.4 178.5 264.7 180.8 273"
            style={{
              fill: "#0000da"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="117.2 269.7 94.7 281.3 86.7 265.4 111.3 254.3 117.2 269.7"
            style={{
              fill: "#0000e8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="153.4 281.2 130.3 291.5 123.6 282.2 149 272.4 153.4 281.2"
            style={{
              fill: "#0000c4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="210.9 235.8 174.5 239.5 172.7 223.1 210.8 220.5 210.9 235.8"
            style={{
              fill: "#003cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="111.3 254.3 86.8 265.4 79.6 246.8 105.9 236.3 111.3 254.3"
            style={{
              fill: "#0008ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211.2 259.3 178.5 264.7 176.4 253.4 211 248.9 211.2 259.3"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="123.6 282.2 103.2 294.1 94.7 281.3 117.2 269.7 123.6 282.2"
            style={{
              fill: "#0000c8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.2 204.8 134.2 209 131.7 188.1 170 185 171.2 204.8"
            style={{
              fill: "#0090ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="134.2 209 101.3 216.1 97.5 194.2 131.7 188.1 134.2 209"
            style={{
              fill: "#0084ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="210.8 220.5 172.7 223.1 171.2 204.8 210.7 203.4 210.8 220.5"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="105.9 236.3 79.6 246.8 73.4 226 101.3 216.1 105.9 236.3"
            style={{
              fill: "#003cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211.4 267 180.8 273 178.5 264.7 211.2 259.3 211.4 267"
            style={{
              fill: "#0000f6"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183 278.1 157.9 286.7 153.4 281.2 180.8 273 183 278.1"
            style={{
              fill: "#0000d1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="130.3 291.5 112.1 303.6 103.2 294.1 123.6 282.2 130.3 291.5"
            style={{
              fill: "#0000ad"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.9 286.7 137.1 297.2 130.3 291.5 153.4 281.2 157.9 286.7"
            style={{
              fill: "#00b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="210.7 203.4 171.2 204.8 170 185 210.7 184.8 210.7 203.4"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="101.3 216.1 73.4 226 68.3 203.4 97.5 194.2 101.3 216.1"
            style={{
              fill: "#0078ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211.6 271.6 183 278.1 180.8 273 211.4 267 211.6 271.6"
            style={{
              fill: "#0000ed"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="170 185 131.7 188.1 129.8 166.1 169.1 164.1 170 185"
            style={{
              fill: "#0cf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="247 246.5 211 248.9 210.9 235.8 248.5 234.5 247 246.5"
            style={{
              fill: "#002cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="131.7 188.1 97.4 194.2 94.5 171.2 129.8 166.1 131.7 188.1"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="245.4 256.1 211.2 259.3 211 248.9 247 246.5 245.4 256.1"
            style={{
              fill: "#0014ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="248.5 234.5 210.9 235.8 210.8 220.6 249.9 220.4 248.5 234.5"
            style={{
              fill: "#0050ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="94.7 281.3 77.5 294.8 67.7 278.7 86.7 265.4 94.7 281.3"
            style={{
              fill: "#0000da"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="86.7 265.4 67.7 278.7 58.9 259.7 79.6 246.8 86.7 265.4"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="137.1 297.2 121.1 309.4 112.1 303.6 130.3 291.5 137.1 297.2"
            style={{
              fill: "#0000a4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="103.2 294.1 87.9 307.8 77.5 294.8 94.7 281.3 103.2 294.1"
            style={{
              fill: "#0000b6"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.3 279.9 162.4 288.7 157.9 286.7 183 278.1 185.3 279.9"
            style={{
              fill: "#0000d6"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="243.8 263 211.4 267 211.2 259.3 245.4 256.1 243.8 263"
            style={{
              fill: "#0004ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="249.9 220.4 210.8 220.5 210.7 203.4 251.1 204.5 249.9 220.4"
            style={{
              fill: "#0078ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="162.4 288.7 143.9 299.3 137.1 297.2 157.9 286.7 162.4 288.7"
            style={{
              fill: "#0000bf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="210.7 184.8 170 185 169.1 164.1 210.7 165.1 210.7 184.8"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="79.6 246.8 58.9 259.7 51.2 238.5 73.4 226 79.6 246.8"
            style={{
              fill: "#0034ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211.8 273.1 185.3 279.9 183 278.2 211.6 271.6 211.8 273.1"
            style={{
              fill: "#0000f1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="112.1 303.6 98.9 317.3 87.9 307.8 103.2 294.1 112.1 303.6"
            style={{
              fill: "#00009f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="97.4 194.2 68.3 203.4 64.4 179.6 94.5 171.2 97.4 194.2"
            style={{
              fill: "#00bcff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="242.2 267 211.6 271.6 211.4 267 243.8 263 242.2 267"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169.1 164.1 129.8 166.1 128.6 143.5 168.5 142.6 169.1 164.1"
            style={{
              fill: "#23ffd4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="251.1 204.5 210.7 203.4 210.7 184.8 252.2 187.2 251.1 204.5"
            style={{
              fill: "#00acff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="129.8 166.1 94.6 171.2 92.7 147.6 128.6 143.5 129.8 166.1"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="143.9 299.3 130.1 311.5 121.1 309.4 137.1 297.2 143.9 299.3"
            style={{
              fill: "#0000ad"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="73.4 226 51.2 238.5 44.9 215.3 68.3 203.4 73.4 226"
            style={{
              fill: "#0070ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="187.5 278.3 166.7 287.2 162.4 288.7 185.3 279.9 187.5 278.3"
            style={{
              fill: "#0000e8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="121.1 309.4 110 323 98.9 317.3 112.1 303.6 121.1 309.4"
            style={{
              fill: "#000096"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="210.7 165.1 169.1 164.1 168.5 142.6 210.8 144.9 210.7 165.1"
            style={{
              fill: "#26ffd1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="240.6 268.1 211.8 273 211.6 271.6 242.2 267 240.6 268.1"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="282.6 246.2 247 246.5 248.5 234.5 285.5 235.4 282.6 246.2"
            style={{
              fill: "#04f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="279.5 254.8 245.4 256.1 247 246.5 282.6 246.2 279.5 254.8"
            style={{
              fill: "#002cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="166.8 287.2 150.4 297.7 143.9 299.3 162.4 288.7 166.8 287.2"
            style={{
              fill: "#0000d1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="252.2 187.2 210.7 184.8 210.7 165.1 253 168.9 252.2 187.2"
            style={{
              fill: "#00e0fb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212 271.3 187.5 278.3 185.3 279.9 211.8 273.1 212 271.3"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="285.5 235.4 248.5 234.5 249.9 220.4 288.1 222.5 285.5 235.4"
            style={{
              fill: "#0064ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="94.6 171.2 64.4 179.6 61.8 155.1 92.7 147.6 94.6 171.2"
            style={{
              fill: "#19ffde"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="276.4 260.9 243.8 263 245.4 256.1 279.5 254.8 276.4 260.9"
            style={{
              fill: "#001cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="77.5 294.8 65.5 309.8 54.2 293.7 67.7 278.7 77.5 294.8"
            style={{
              fill: "#0000d1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="87.9 307.8 77.7 322.7 65.5 309.8 77.5 294.8 87.9 307.8"
            style={{
              fill: "#0000a8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168.5 142.6 128.6 143.5 128.1 120.8 168.3 121.1 168.5 142.6"
            style={{
              fill: "#5aff9d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="288.1 222.5 249.9 220.4 251.1 204.5 290.4 207.9 288.1 222.5"
            style={{
              fill: "#008cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="67.7 278.7 54.1 293.7 43.9 274.6 58.9 259.7 67.7 278.7"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="68.3 203.4 44.9 215.4 40.1 190.9 64.4 179.6 68.3 203.4"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="128.6 143.5 92.7 147.6 91.8 124 128.1 120.8 128.6 143.5"
            style={{
              fill: "#5aff9d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="273.2 264.3 242.2 267 243.8 263 276.4 260.9 273.2 264.3"
            style={{
              fill: "#0018ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="150.4 297.7 138.9 309.7 130.1 311.5 143.9 299.3 150.4 297.7"
            style={{
              fill: "#0000bf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="130.1 311.5 121.1 324.9 110.1 323 121.1 309.5 130.1 311.5"
            style={{
              fill: "#00009b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="98.9 317.3 90.5 332 77.8 322.7 87.9 307.8 98.9 317.3"
            style={{
              fill: "#000092"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="239 266.2 212 271.3 211.8 273.1 240.6 268.1 239 266.2"
            style={{
              fill: "#000cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="58.9 259.7 43.9 274.6 34.9 253.2 51.2 238.5 58.9 259.7"
            style={{
              fill: "#002cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="210.8 144.9 168.5 142.6 168.3 121.1 210.9 124.7 210.8 144.9"
            style={{
              fill: "#5dff9a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="253 168.9 210.7 165.1 210.8 144.9 253.6 150 253 168.9"
            style={{
              fill: "#2cffca"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="290.4 207.9 251.1 204.5 252.2 187.2 292.4 191.9 290.4 207.9"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="189.6 273.3 170.9 282 166.8 287.2 187.5 278.3 189.6 273.3"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212.3 266.4 189.6 273.3 187.5 278.3 212 271.3 212.3 266.4"
            style={{
              fill: "#000cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="270 265 240.6 268.1 242.2 267 273.2 264.3 270 265"
            style={{
              fill: "#0018ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="170.9 282 156.6 292.3 150.4 297.7 166.8 287.2 170.9 282"
            style={{
              fill: "#0000f1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="92.7 147.6 61.8 155.1 60.7 130.6 91.8 124 92.7 147.6"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="110.1 323 103.5 337.5 90.5 332 98.9 317.3 110.1 323"
            style={{
              fill: "#000089"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="51.2 238.5 34.9 253.2 27.6 229.8 44.9 215.3 51.2 238.5"
            style={{
              fill: "#006cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="64.4 179.6 40.1 190.9 36.9 165.8 61.8 155.1 64.4 179.6"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="292.4 191.9 252.2 187.2 253 168.9 293.9 175 292.4 191.9"
            style={{
              fill: "#06ecf1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168.3 121.1 128.1 120.8 128.3 98.8 168.4 100.2 168.3 121.1"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="237.6 261.4 212.3 266.4 212 271.3 239 266.2 237.6 261.4"
            style={{
              fill: "#0024ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="138.9 309.7 131.9 322.8 121.1 324.9 130.1 311.5 138.9 309.7"
            style={{
              fill: "#0000ad"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="311.6 255.3 279.5 254.8 282.6 246.2 315.8 247.7 311.6 255.3"
            style={{
              fill: "#04f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="315.8 247.7 282.6 246.2 285.5 235.4 319.8 238 315.8 247.7"
            style={{
              fill: "#005cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="253.6 150 210.8 144.9 210.9 124.7 254 131.1 253.6 150"
            style={{
              fill: "#5dff9a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="128.1 120.8 91.8 124 92.1 100.9 128.3 98.8 128.1 120.8"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="267 262.9 239 266.2 240.6 268.1 270 265 267 262.9"
            style={{
              fill: "#0028ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="156.6 292.3 147.1 304 138.9 309.7 150.4 297.7 156.6 292.3"
            style={{
              fill: "#0000df"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="307.2 260.6 276.4 260.9 279.5 254.8 311.6 255.3 307.2 260.6"
            style={{
              fill: "#0038ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="319.8 238 285.5 235.4 288.1 222.5 323.4 226.3 319.8 238"
            style={{
              fill: "#0078ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="210.9 124.7 168.3 121.1 168.4 100.2 211 104.9 210.9 124.7"
            style={{
              fill: "#90ff66"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="121.1 324.9 116.4 339 103.5 337.5 110.1 323 121.1 324.9"
            style={{
              fill: "#00008d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="191.6 265 174.6 273.4 170.9 282 189.6 273.3 191.6 265"
            style={{
              fill: "#001cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="302.7 263.4 273.2 264.3 276.4 260.9 307.1 260.6 302.7 263.4"
            style={{
              fill: "#0034ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212.5 258.4 191.6 265 189.6 273.3 212.3 266.4 212.5 258.4"
            style={{
              fill: "#0030ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="323.4 226.3 288.1 222.5 290.4 207.9 326.6 213.1 323.4 226.3"
            style={{
              fill: "#00a0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="293.9 175 253 168.9 253.6 150 295 157.5 293.9 175"
            style={{
              fill: "#33ffc4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="44.9 215.3 27.6 229.8 22 205 40.1 190.9 44.9 215.3"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="65.5 309.8 58.5 325.8 45.8 309.9 54.1 293.7 65.5 309.8"
            style={{
              fill: "#0000c8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="77.8 322.7 72.1 338.4 58.5 325.8 65.5 309.8 77.8 322.7"
            style={{
              fill: "#0000a4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.6 273.4 162.3 283.4 156.6 292.3 170.9 282 174.6 273.4"
            style={{
              fill: "#0008ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="54.2 293.7 45.8 309.9 34.4 291 43.9 274.6 54.2 293.7"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="91.8 124 60.7 130.6 61 106.7 92.1 100.9 91.8 124"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="298.3 263.7 270 265 273.2 264.3 302.7 263.4 298.3 263.7"
            style={{
              fill: "#0034ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="264.2 258.1 237.6 261.4 239 266.2 267 262.9 264.2 258.1"
            style={{
              fill: "#003cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="61.8 155.1 36.9 165.8 35.5 140.6 60.7 130.6 61.8 155.1"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="90.5 332 86.3 347.3 72.1 338.4 77.8 322.7 90.5 332"
            style={{
              fill: "#000089"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="236.4 253.6 212.5 258.4 212.3 266.4 237.6 261.4 236.4 253.6"
            style={{
              fill: "#04f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="326.6 213.1 290.4 207.9 292.4 191.9 329.3 198.5 326.6 213.1"
            style={{
              fill: "#00c8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="254 131.1 210.9 124.7 211 104.9 254 112.6 254 131.1"
            style={{
              fill: "#90ff66"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168.4 100.2 128.3 98.8 129.2 77.8 169 80.3 168.4 100.2"
            style={{
              fill: "#caff2c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.1 304 142.1 316.7 131.9 322.8 138.9 309.7 147.1 304"
            style={{
              fill: "#0000cd"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="43.9 274.6 34.4 291 24.4 269.6 34.9 253.2 43.9 274.6"
            style={{
              fill: "#0028ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="128.3 98.8 92.1 100.9 93.4 79 129.2 77.8 128.3 98.8"
            style={{
              fill: "#ceff29"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211 104.9 168.4 100.2 169 80.3 211.1 86.1 211 104.9"
            style={{
              fill: "#c7ff30"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="131.9 322.8 129 336.5 116.4 339 121.2 324.9 131.9 322.8"
            style={{
              fill: "#00009f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="295 157.5 253.6 150 254 131.1 295.6 139.9 295 157.5"
            style={{
              fill: "#60ff97"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="294.2 261.4 267 262.9 270 265 298.3 263.7 294.2 261.4"
            style={{
              fill: "#0040ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="162.3 283.3 154.7 294.6 147.1 304 156.6 292.3 162.3 283.3"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="103.5 337.5 100.8 352.3 86.3 347.3 90.5 332 103.5 337.5"
            style={{
              fill: "navy"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="40.1 190.9 22 205 18.3 179.4 36.9 165.8 40.1 190.9"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="329.3 198.5 292.4 191.9 293.9 175 331.4 183 329.3 198.5"
            style={{
              fill: "#0ff8e7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212.7 247.5 193.3 253.6 191.6 265 212.5 258.4 212.7 247.5"
            style={{
              fill: "#0058ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="193.3 253.6 178 261.5 174.6 273.4 191.6 265 193.3 253.6"
            style={{
              fill: "#0048ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="261.7 250.6 236.4 253.6 237.6 261.4 264.2 258.1 261.7 250.6"
            style={{
              fill: "#0058ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="34.9 253.2 24.4 269.6 16.2 246.1 27.6 229.8 34.9 253.2"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="339.6 257.3 311.6 255.3 315.8 247.7 344.7 250.7 339.6 257.3"
            style={{
              fill: "#0060ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="334.3 261.8 307.2 260.6 311.6 255.3 339.6 257.3 334.3 261.8"
            style={{
              fill: "#0054ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="344.7 250.7 315.8 247.7 319.8 238 349.5 242 344.7 250.7"
            style={{
              fill: "#0074ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="235.3 243.2 212.7 247.5 212.5 258.4 236.4 253.6 235.3 243.2"
            style={{
              fill: "#006cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178 261.5 167.3 270.9 162.3 283.3 174.6 273.4 178 261.5"
            style={{
              fill: "#0038ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="290.2 256.5 264.2 258.1 267 262.9 294.1 261.4 290.2 256.5"
            style={{
              fill: "#0054ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="254 112.6 211 104.9 211.1 86.1 253.9 94.9 254 112.6"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="116.4 339 115.1 353.4 100.8 352.4 103.5 337.5 116.4 339"
            style={{
              fill: "#000084"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="60.7 130.6 35.5 140.6 35.9 115.9 61 106.7 60.7 130.6"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="328.9 263.9 302.7 263.4 307.2 260.6 334.3 261.8 328.9 263.9"
            style={{
              fill: "#004cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="92.1 100.9 61 106.7 62.7 83.9 93.4 79 92.1 100.9"
            style={{
              fill: "#d1ff26"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="349.5 242 319.8 238 323.4 226.3 353.9 231.6 349.5 242"
            style={{
              fill: "#0090ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="331.4 183 293.9 175 295 157.5 332.8 166.9 331.4 183"
            style={{
              fill: "#36ffc1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="295.6 139.9 254 131.1 254 112.6 295.6 122.7 295.6 139.9"
            style={{
              fill: "#8dff6a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169 80.3 129.2 77.8 130.8 58.5 169.8 61.9 169 80.3"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="154.7 294.6 151.4 306.9 142.1 316.7 147.1 304 154.7 294.6"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="323.6 263.8 298.3 263.7 302.7 263.4 328.9 263.9 323.6 263.8"
            style={{
              fill: "#0050ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.1 316.7 140.8 330 129 336.5 131.9 322.8 142.1 316.7"
            style={{
              fill: "#0000c4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="353.9 231.6 323.4 226.3 326.6 213.1 357.8 219.6 353.9 231.6"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211.1 86.1 169 80.3 169.8 61.9 211.3 68.7 211.1 86.1"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="259.5 240.7 235.3 243.2 236.4 253.6 261.7 250.6 259.5 240.7"
            style={{
              fill: "#0080ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="129.2 77.8 93.4 79 95.8 58.8 130.8 58.5 129.2 77.8"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="36.9 165.8 18.3 179.4 16.6 153.7 35.5 140.6 36.9 165.8"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="27.6 229.8 16.2 246.1 10 221.2 22 205 27.6 229.8"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="167.3 270.9 161.3 281.6 154.7 294.6 162.3 283.3 167.3 270.9"
            style={{
              fill: "#002cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="286.7 249.3 261.7 250.6 264.2 258.1 290.2 256.5 286.7 249.3"
            style={{
              fill: "#0070ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="72.1 338.4 70.9 353.6 56.7 341.2 58.5 325.8 72.1 338.4"
            style={{
              fill: "#00009f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="58.5 325.8 56.7 341.2 43.5 325.5 45.8 309.9 58.5 325.8"
            style={{
              fill: "#0000c8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213 233.9 194.8 239.4 193.3 253.6 212.7 247.5 213 233.9"
            style={{
              fill: "#008cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="318.6 261.3 294.1 261.4 298.3 263.7 323.6 263.8 318.6 261.3"
            style={{
              fill: "#005cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="194.8 239.4 180.8 246.5 178 261.5 193.3 253.6 194.8 239.4"
            style={{
              fill: "#007cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="357.8 219.6 326.6 213.1 329.3 198.5 361 206.4 357.8 219.6"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="86.3 347.3 85.7 362.2 70.9 353.6 72.1 338.4 86.3 347.3"
            style={{
              fill: "#000089"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="129 336.5 129.1 350.3 115.1 353.4 116.4 339 129 336.5"
            style={{
              fill: "#00009b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="332.8 166.9 295 157.5 295.6 139.9 333.6 150.7 332.8 166.9"
            style={{
              fill: "#63ff94"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="45.8 309.9 43.5 325.5 31.6 306.7 34.4 291 45.8 309.9"
            style={{
              fill: "#0000fa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="234.4 230.3 213 233.9 212.8 247.5 235.3 243.2 234.4 230.3"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="253.9 94.9 211.1 86.1 211.3 68.7 253.4 78.6 253.9 94.9"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="295.6 122.7 254 112.5 253.9 94.9 295.2 106.2 295.6 122.7"
            style={{
              fill: "#beff39"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="180.8 246.5 171.5 255.3 167.3 270.9 178 261.5 180.8 246.5"
            style={{
              fill: "#0070ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="100.8 352.4 100.8 367 85.7 362.2 86.3 347.3 100.8 352.4"
            style={{
              fill: "navy"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="313.8 256.5 290.2 256.5 294.2 261.4 318.6 261.3 313.8 256.5"
            style={{
              fill: "#006cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="61 106.7 35.9 116 38 92.4 62.7 83.9 61 106.7"
            style={{
              fill: "#d4ff23"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="361 206.4 329.3 198.5 331.4 183 363.4 192.3 361 206.4"
            style={{
              fill: "#19ffde"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="93.4 79 62.7 83.9 65.9 62.9 95.8 58.8 93.4 79"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="34.4 291 31.6 306.7 21.2 285.4 24.4 269.6 34.4 291"
            style={{
              fill: "#0024ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="283.7 239.9 259.5 240.7 261.7 250.6 286.7 249.3 283.7 239.9"
            style={{
              fill: "#0094ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="257.7 228.5 234.4 230.3 235.3 243.2 259.5 240.7 257.7 228.5"
            style={{
              fill: "#00acff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169.8 61.9 130.8 58.5 133.1 41.4 171.1 45.6 169.8 61.9"
            style={{
              fill: "#ffb200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="22 205 10 221.2 5.9 195.4 18.3 179.4 22 205"
            style={{
              fill: "#13fce4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="151.4 306.9 151.6 319.6 140.8 330 142.1 316.7 151.4 306.9"
            style={{
              fill: "#0000f1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211.3 68.7 169.8 61.9 171 45.6 211.5 53.3 211.3 68.7"
            style={{
              fill: "#ffbd00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="161.3 281.6 159.6 293.3 151.4 306.9 154.7 294.6 161.3 281.6"
            style={{
              fill: "#0020ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="333.6 150.6 295.6 139.9 295.6 122.7 333.6 134.7 333.6 150.6"
            style={{
              fill: "#8dff6a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="355.9 264.1 334.3 261.8 339.6 257.3 361.8 260.4 355.9 264.1"
            style={{
              fill: "#006cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="361.8 260.4 339.6 257.3 344.7 250.7 367.5 254.7 361.8 260.4"
            style={{
              fill: "#0078ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="35.5 140.6 16.6 153.7 17.1 128.5 35.9 115.9 35.5 140.6"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="349.9 265.7 328.9 263.9 334.3 261.8 355.9 264.1 349.9 265.7"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="367.5 254.7 344.7 250.7 349.5 242 372.9 247.1 367.5 254.7"
            style={{
              fill: "#008cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="130.8 58.5 95.8 58.8 99.2 40.8 133.1 41.4 130.8 58.5"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.2 218 195.9 222.6 194.8 239.3 213 233.9 213.2 218"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="115.1 353.4 115.7 367.8 100.8 367 100.8 352.4 115.1 353.4"
            style={{
              fill: "#000084"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="140.8 330 142.2 343.3 129.1 350.3 129 336.5 140.8 330"
            style={{
              fill: "#00b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="309.6 249.5 286.7 249.3 290.2 256.5 313.8 256.5 309.6 249.5"
            style={{
              fill: "#0084ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.5 255.3 166.9 265.4 161.3 281.7 167.3 270.9 171.5 255.3"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="363.4 192.3 331.4 183 332.8 166.9 365.1 177.6 363.4 192.3"
            style={{
              fill: "#3cffba"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="233.8 215.3 213.2 218 213 233.9 234.4 230.3 233.8 215.3"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="24.4 269.6 21.2 285.4 12.7 261.9 16.2 246.1 24.4 269.6"
            style={{
              fill: "#0064ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="344.1 265.2 323.6 263.8 328.9 263.9 349.9 265.7 344.1 265.2"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="195.9 222.6 183 228.9 180.8 246.5 194.8 239.4 195.9 222.6"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="372.9 247.1 349.5 242 353.9 231.6 377.8 237.9 372.9 247.1"
            style={{
              fill: "#00a4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="295.2 106.2 253.9 94.9 253.4 78.6 294.2 90.9 295.2 106.2"
            style={{
              fill: "#ebff0c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="253.4 78.6 211.3 68.7 211.5 53.3 252.7 64 253.4 78.6"
            style={{
              fill: "#ffc800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="281.2 228.4 257.7 228.5 259.5 240.7 283.7 239.9 281.2 228.4"
            style={{
              fill: "#00bcff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="338.4 262.5 318.6 261.3 323.6 263.8 344.1 265.2 338.4 262.5"
            style={{
              fill: "#0074ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="377.8 237.9 353.9 231.6 357.7 219.6 382 227.2 377.8 237.9"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="256.4 214.4 233.8 215.3 234.4 230.3 257.7 228.5 256.4 214.4"
            style={{
              fill: "#00dcfe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183 228.9 174.8 236.9 171.5 255.3 180.8 246.5 183 228.9"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="333.6 134.7 295.6 122.7 295.1 106.2 332.9 119.4 333.6 134.7"
            style={{
              fill: "#b7ff40"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="305.9 240.5 283.7 239.9 286.7 249.3 309.6 249.5 305.9 240.5"
            style={{
              fill: "#00a4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="365.1 177.6 332.8 166.9 333.6 150.7 366 162.7 365.1 177.6"
            style={{
              fill: "#63ff94"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="129.1 350.3 130.2 364.4 115.7 367.8 115.1 353.4 129.1 350.3"
            style={{
              fill: "#000096"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="18.3 179.4 5.9 195.4 4 169.4 16.6 153.7 18.3 179.4"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="62.7 83.9 38 92.4 42 70.7 65.9 62.9 62.7 83.9"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171 45.6 133.1 41.4 136.1 26.9 172.6 31.8 171 45.6"
            style={{
              fill: "#ff7a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="95.8 58.8 65.9 62.9 70.5 44.2 99.2 40.8 95.8 58.8"
            style={{
              fill: "#ff9c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211.5 53.3 171.1 45.6 172.6 31.8 211.7 40.2 211.5 53.3"
            style={{
              fill: "#ff8900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="333.2 257.7 313.8 256.5 318.6 261.3 338.4 262.5 333.2 257.7"
            style={{
              fill: "#0084ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="70.9 353.6 72.6 367.4 58.5 355.1 56.7 341.2 70.9 353.6"
            style={{
              fill: "#00009f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="382 227.2 357.8 219.6 361 206.4 385.6 215.2 382 227.2"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="56.7 341.2 58.5 355.1 45.3 339.4 43.5 325.5 56.7 341.2"
            style={{
              fill: "#0000c8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="159.6 293.3 161.2 305.5 151.6 319.6 151.4 306.9 159.6 293.3"
            style={{
              fill: "#0018ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="16.2 246.1 12.7 262 6.2 237 10 221.2 16.2 246.1"
            style={{
              fill: "#00acff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="85.7 362.2 87.4 376.1 72.6 367.4 70.9 353.6 85.7 362.2"
            style={{
              fill: "#000089"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="166.9 265.4 166.4 276.5 159.6 293.3 161.3 281.7 166.9 265.4"
            style={{
              fill: "#005cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.3 200.1 196.7 203.7 195.9 222.6 213.2 218 213.3 200.1"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="151.6 319.6 154.3 332.3 142.2 343.3 140.8 330 151.6 319.6"
            style={{
              fill: "#0000ed"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="294.2 90.9 253.4 78.6 252.7 64 292.7 77.2 294.2 90.9"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="233.4 198.5 213.3 200.1 213.2 218 233.8 215.3 233.4 198.5"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="35.9 115.9 17.1 128.5 19.6 104.3 38 92.4 35.9 115.9"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="43.5 325.5 45.3 339.4 33.4 320.7 31.6 306.7 43.5 325.5"
            style={{
              fill: "#0000fa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="279.3 215.2 256.4 214.4 257.7 228.5 281.2 228.4 279.3 215.2"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="133.1 41.4 99.2 40.8 103.6 25.7 136.1 26.9 133.1 41.4"
            style={{
              fill: "#ff6f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="252.7 64 211.5 53.3 211.7 40.2 251.8 51.6 252.7 64"
            style={{
              fill: "#ff9800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="196.7 203.7 184.5 209 183 228.9 195.9 222.6 196.7 203.7"
            style={{
              fill: "#13fce4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="328.4 251 309.6 249.5 313.8 256.5 333.1 257.7 328.4 251"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.8 236.9 171.2 246.3 166.9 265.4 171.5 255.3 174.8 236.9"
            style={{
              fill: "#00a8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="302.8 229.7 281.2 228.4 283.7 239.9 305.9 240.5 302.8 229.7"
            style={{
              fill: "#0cf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="385.6 215.2 361 206.4 363.4 192.3 388.3 202.4 385.6 215.2"
            style={{
              fill: "#1fffd7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="100.7 367 102.4 380.9 87.4 376.1 85.7 362.2 100.7 367"
            style={{
              fill: "navy"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="366 162.7 333.6 150.7 333.6 134.7 366 148 366 162.7"
            style={{
              fill: "#8dff6a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="332.9 119.4 295.2 106.2 294.2 90.9 331.5 105.1 332.9 119.4"
            style={{
              fill: "#e1ff16"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="255.6 198.7 233.4 198.5 233.8 215.3 256.4 214.4 255.6 198.7"
            style={{
              fill: "#23ffd4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="31.6 306.7 33.4 320.7 23 299.4 21.2 285.4 31.6 306.7"
            style={{
              fill: "#0024ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="370.6 267.5 355.9 264.1 361.8 260.4 376.8 264.5 370.6 267.5"
            style={{
              fill: "#0084ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.2 343.3 143.9 357.1 130.2 364.4 129.1 350.3 142.2 343.3"
            style={{
              fill: "#00b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="364.3 268.5 349.9 265.7 355.9 264.1 370.6 267.5 364.3 268.5"
            style={{
              fill: "#0080ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="376.8 264.5 361.8 260.4 367.5 254.7 382.9 259.6 376.8 264.5"
            style={{
              fill: "#0090ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="358.1 267.7 344.1 265.2 349.9 265.7 364.3 268.5 358.1 267.7"
            style={{
              fill: "#0084ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="382.9 259.6 367.5 254.7 372.9 247.1 388.5 253 382.9 259.6"
            style={{
              fill: "#00a0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="184.5 209 177 216.1 174.8 236.9 183 228.9 184.5 209"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="10 221.2 6.2 237 1.9 211.2 5.9 195.4 10 221.2"
            style={{
              fill: "#13fce4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="324.3 242.5 305.9 240.5 309.6 249.5 328.4 251 324.3 242.5"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="211.7 40.2 172.6 31.8 174.4 20.9 212 29.8 211.7 40.2"
            style={{
              fill: "#ff6000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="388.3 202.4 363.4 192.3 365.1 177.6 390.2 189 388.3 202.4"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="16.6 153.7 4 169.4 4.5 143.7 17.1 128.5 16.6 153.7"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="115.7 367.8 117.4 381.7 102.4 380.9 100.8 367 115.7 367.8"
            style={{
              fill: "#000084"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="172.6 31.8 136.1 26.9 139.5 15.4 174.4 20.9 172.6 31.8"
            style={{
              fill: "#ff4e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="292.7 77.2 252.7 64 251.8 51.6 290.8 65.5 292.7 77.2"
            style={{
              fill: "#ffab00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="352.1 264.8 338.4 262.5 344.1 265.2 358.1 267.7 352.1 264.8"
            style={{
              fill: "#008cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="278.1 200.6 255.6 198.7 256.4 214.4 279.3 215.2 278.1 200.6"
            style={{
              fill: "#2cffca"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="388.5 253 372.9 247.1 377.8 237.9 393.7 244.8 388.5 253"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="65.9 62.9 42 70.7 47.6 51.3 70.5 44.2 65.9 62.9"
            style={{
              fill: "#ff9400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="99.2 40.8 70.5 44.2 76.3 28.3 103.6 25.7 99.2 40.8"
            style={{
              fill: "#ff6400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="300.6 217.4 279.3 215.2 281.2 228.4 302.8 229.7 300.6 217.4"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="233.3 180.3 213.5 180.8 213.3 200.1 233.4 198.5 233.3 180.3"
            style={{
              fill: "#50ffa7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.5 180.8 197.2 183.1 196.7 203.7 213.3 200.1 213.5 180.8"
            style={{
              fill: "#4dffaa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="366 148 333.6 134.7 332.9 119.4 365.1 133.8 366 148"
            style={{
              fill: "#b4ff43"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="166.4 276.5 169.2 288.1 161.2 305.5 159.6 293.3 166.4 276.5"
            style={{
              fill: "#0058ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="251.8 51.6 211.7 40.2 212 29.8 250.7 41.8 251.8 51.6"
            style={{
              fill: "#ff6f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="21.2 285.4 23 299.4 14.5 276.1 12.7 261.9 21.2 285.4"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="331.5 105.1 294.2 90.9 292.7 77.2 329.4 92.3 331.5 105.1"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="161.2 305.5 164.9 317.7 154.3 332.3 151.6 319.6 161.2 305.5"
            style={{
              fill: "#0014ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.2 246.3 171.8 256.7 166.4 276.5 166.9 265.4 171.2 246.3"
            style={{
              fill: "#00a0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="346.6 260.1 333.1 257.8 338.4 262.5 352.1 264.8 346.6 260.1"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="255.3 181.8 233.3 180.4 233.4 198.5 255.6 198.7 255.3 181.8"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="393.7 244.8 377.8 237.9 382 227.2 398.1 235.2 393.7 244.8"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="136.1 26.9 103.6 25.7 108.9 13.7 139.5 15.4 136.1 26.9"
            style={{
              fill: "#ff3f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="38 92.4 19.6 104.3 24.2 82 42 70.7 38 92.4"
            style={{
              fill: "#ffd300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="197.2 183.1 185.3 187.4 184.5 209 196.7 203.7 197.2 183.1"
            style={{
              fill: "#49ffad"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="320.9 232.4 302.8 229.7 305.9 240.5 324.3 242.5 320.9 232.4"
            style={{
              fill: "#00dcfe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="390.2 189 365.1 177.6 366 162.7 391.1 175.4 390.2 189"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="177 216.1 174.2 224.7 171.2 246.3 174.8 236.9 177 216.1"
            style={{
              fill: "#09f0ee"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="130.2 364.5 131.8 378.4 117.4 381.7 115.7 367.8 130.2 364.5"
            style={{
              fill: "#00009b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="154.3 332.3 156.5 345.9 143.9 357.1 142.2 343.3 154.3 332.3"
            style={{
              fill: "#0000e8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="341.5 253.6 328.4 251 333.2 257.7 346.6 260.1 341.5 253.6"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="398.1 235.2 382 227.2 385.6 215.2 401.9 224.5 398.1 235.2"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="299.1 203.9 278.1 200.6 279.3 215.2 300.6 217.4 299.1 203.9"
            style={{
              fill: "#33ffc4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="277.6 184.9 255.2 181.8 255.6 198.7 278.1 200.6 277.6 184.9"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212 29.8 174.4 20.9 176.4 13.2 212.2 22.5 212 29.8"
            style={{
              fill: "#ff3b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="72.6 367.4 75.3 379.9 61.3 367.7 58.5 355.1 72.6 367.4"
            style={{
              fill: "#0000a8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="365.1 133.8 332.9 119.4 331.5 105.1 363.4 120.6 365.1 133.8"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="290.8 65.5 251.8 51.6 250.7 41.8 288.5 56.2 290.8 65.5"
            style={{
              fill: "#ff8600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="58.5 355.1 61.3 367.7 48.2 352.1 45.3 339.4 58.5 355.1"
            style={{
              fill: "#0000cd"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="5.9 195.4 1.9 211.2 0 185 4 169.4 5.9 195.4"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.3 187.4 178.2 193.5 177 216.1 184.5 209 185.3 187.4"
            style={{
              fill: "#46ffb1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.4 20.9 139.5 15.4 143.5 7.4 176.4 13.2 174.4 20.9"
            style={{
              fill: "#ff2900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="12.7 261.9 14.5 276.1 8 251.2 6.2 237 12.7 261.9"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="233.5 161.3 213.6 160.4 213.5 180.8 233.3 180.4 233.5 161.3"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="87.4 376.1 89.9 388.5 75.3 379.9 72.6 367.4 87.4 376.1"
            style={{
              fill: "#000092"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="329.4 92.3 292.7 77.2 290.8 65.5 326.7 81.3 329.4 92.3"
            style={{
              fill: "#ffb900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.6 160.4 197.2 161.5 197.2 183.1 213.5 180.8 213.6 160.4"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="318.4 220.9 300.6 217.4 302.8 229.8 320.9 232.4 318.4 220.9"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="250.7 41.8 212 29.8 212.2 22.5 249.3 34.7 250.7 41.8"
            style={{
              fill: "#ff4e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="391.1 175.4 366 162.7 366 148 391.1 161.9 391.1 175.4"
            style={{
              fill: "#8aff6d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="17.1 128.5 4.5 143.7 7.4 119.2 19.6 104.3 17.1 128.5"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="45.3 339.4 48.2 352.1 36.4 333.6 33.4 320.7 45.3 339.4"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="337.2 245.5 324.3 242.5 328.4 251 341.5 253.6 337.2 245.5"
            style={{
              fill: "#00c8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="255.5 164.1 233.5 161.3 233.3 180.3 255.2 181.8 255.5 164.1"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="401.9 224.5 385.6 215.2 388.3 202.4 404.8 213 401.9 224.5"
            style={{
              fill: "#29ffce"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="103.6 25.7 76.3 28.3 83.3 15.8 108.8 13.7 103.6 25.7"
            style={{
              fill: "#ff3000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="70.5 44.2 47.6 51.3 54.8 34.8 76.3 28.3 70.5 44.2"
            style={{
              fill: "#ff5900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="370.9 272.2 364.3 268.5 370.6 267.5 377.3 271.6 370.9 272.2"
            style={{
              fill: "#0098ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="377.3 271.6 370.6 267.5 376.8 264.5 383.6 269.2 377.3 271.6"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="102.4 380.9 104.8 393.2 89.9 388.5 87.4 376.1 102.4 380.9"
            style={{
              fill: "#000084"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="364.5 271 358.1 267.7 364.3 268.5 370.9 272.2 364.5 271"
            style={{
              fill: "#0098ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="197.2 161.5 185.3 164.6 185.3 187.4 197.2 183.1 197.2 161.5"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="383.6 269.2 376.8 264.5 382.9 259.6 389.7 265.1 383.6 269.2"
            style={{
              fill: "#00a4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.8 256.7 175.4 267.7 169.2 288.1 166.4 276.5 171.8 256.7"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="143.9 357.1 145.5 371.1 131.9 378.4 130.2 364.5 143.9 357.1"
            style={{
              fill: "#00b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169.2 288.1 173.8 299.8 164.9 317.7 161.2 305.5 169.2 288.1"
            style={{
              fill: "#0054ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="139.5 15.4 108.8 13.7 114.8 5.2 143.5 7.4 139.5 15.4"
            style={{
              fill: "#ff1600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="298.5 189.4 277.6 184.9 278.1 200.6 299.1 203.9 298.5 189.4"
            style={{
              fill: "#5aff9d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="358.5 268 352.1 264.8 358.1 267.7 364.5 271 358.5 268"
            style={{
              fill: "#00a0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="33.4 320.7 36.4 333.6 26.2 312.6 23 299.4 33.4 320.7"
            style={{
              fill: "#0028ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.2 224.7 175.5 234.4 171.8 256.7 171.2 246.3 174.2 224.7"
            style={{
              fill: "#06ecf1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="363.4 120.6 331.5 105.1 329.4 92.3 360.8 108.6 363.4 120.6"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="277.9 168.6 255.5 164.1 255.2 181.8 277.6 184.9 277.9 168.6"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="389.7 265.1 382.9 259.6 388.5 253 395.5 259.3 389.7 265.1"
            style={{
              fill: "#00b4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="333.6 236.1 320.9 232.4 324.3 242.5 337.2 245.5 333.6 236.1"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="288.5 56.2 250.7 41.8 249.3 34.7 285.8 49.4 288.5 56.2"
            style={{
              fill: "#ff6400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="42 70.7 24.2 82 30.7 61.9 47.6 51.3 42 70.7"
            style={{
              fill: "#ff9100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="404.7 213 388.3 202.4 390.2 189 406.7 200.8 404.7 213"
            style={{
              fill: "#46ffb1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="316.7 208.4 299.1 203.9 300.6 217.4 318.4 220.9 316.7 208.4"
            style={{
              fill: "#39ffbe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212.2 22.5 176.4 13.2 178.6 9 212.5 18.4 212.2 22.5"
            style={{
              fill: "#f20"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="391.1 161.9 366 147.9 365.1 133.8 390.1 148.8 391.1 161.9"
            style={{
              fill: "#adff49"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178.2 193.5 175.7 201.2 174.2 224.7 177 216.1 178.2 193.5"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="352.8 263.4 346.6 260.1 352.1 264.8 358.5 268 352.8 263.4"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="326.7 81.3 290.8 65.5 288.5 56.2 323.5 72.4 326.7 81.3"
            style={{
              fill: "#ff9800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="164.9 317.7 167.5 331 156.5 345.9 154.3 332.3 164.9 317.7"
            style={{
              fill: "#0014ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="233.9 141.9 213.7 139.6 213.6 160.4 233.5 161.3 233.9 141.9"
            style={{
              fill: "#b4ff43"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="395.5 259.3 388.5 253 393.7 244.8 400.7 252.1 395.5 259.3"
            style={{
              fill: "#00c8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="117.4 381.7 119.6 394 104.8 393.2 102.4 380.9 117.4 381.7"
            style={{
              fill: "#00008d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="249.3 34.7 212.2 22.5 212.5 18.4 247.8 30.6 249.3 34.7"
            style={{
              fill: "#ff3800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="6.2 237 8 251.2 3.8 225.5 1.9 211.2 6.2 237"
            style={{
              fill: "#13fce4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="256.2 146.2 233.9 141.9 233.5 161.3 255.5 164.1 256.2 146.2"
            style={{
              fill: "#adff49"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.7 139.6 196.9 139.3 197.2 161.5 213.6 160.4 213.7 139.6"
            style={{
              fill: "#b7ff40"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="176.4 13.2 143.5 7.4 147.8 3 178.6 9 176.4 13.2"
            style={{
              fill: "#f60b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.3 164.6 178.2 169.6 178.2 193.5 185.3 187.4 185.3 164.6"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="4 169.4 0 185 0.5 159.2 4.5 143.8 4 169.4"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="347.7 257.1 341.5 253.6 346.6 260.1 352.8 263.4 347.7 257.1"
            style={{
              fill: "#00c0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="23 299.4 26.2 312.6 17.8 289.5 14.5 276.1 23 299.4"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="330.9 225.4 318.4 220.9 320.9 232.4 333.6 236.1 330.9 225.4"
            style={{
              fill: "#1fffd7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="400.7 252.1 393.6 244.8 398.1 235.2 405.2 243.6 400.7 252.1"
            style={{
              fill: "#00e0fb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="298.8 174.5 277.9 168.6 277.6 184.9 298.5 189.4 298.8 174.5"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="406.7 200.8 390.2 189 391.1 175.4 407.6 188.3 406.7 200.8"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="360.8 108.6 329.4 92.3 326.7 81.3 357.5 98.3 360.8 108.6"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="278.9 152.1 256.2 146.2 255.5 164.1 277.9 168.6 278.9 152.1"
            style={{
              fill: "#aaff4d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="196.9 139.3 184.6 141.2 185.3 164.6 197.2 161.5 196.9 139.3"
            style={{
              fill: "#baff3c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="316 195.1 298.5 189.4 299.1 203.9 316.7 208.4 316 195.1"
            style={{
              fill: "#5aff9d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="285.8 49.4 249.3 34.7 247.8 30.6 282.9 45.3 285.8 49.4"
            style={{
              fill: "#ff4e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="19.6 104.3 7.4 119.2 12.5 96.3 24.2 82 19.6 104.3"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="390.1 148.8 365.1 133.8 363.4 120.6 388.1 136.6 390.1 148.8"
            style={{
              fill: "#d1ff26"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="108.9 13.7 83.3 15.8 91.2 6.9 114.8 5.2 108.9 13.7"
            style={{
              fill: "#f10800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="343.3 249.4 337.2 245.5 341.5 253.6 347.7 257.1 343.3 249.4"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="156.5 345.9 158 359.9 145.5 371.1 143.9 357.1 156.5 345.9"
            style={{
              fill: "#0000ed"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="323.4 72.4 288.5 56.2 285.8 49.4 319.7 65.9 323.4 72.4"
            style={{
              fill: "#ff7a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="143.5 7.4 114.8 5.2 121.3 0.6 147.8 3 143.5 7.4"
            style={{
              fill: "#df0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="234.6 122.6 213.7 118.8 213.7 139.6 233.9 141.9 234.6 122.6"
            style={{
              fill: "#e4ff13"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212.5 18.4 178.6 9 180.9 8.4 212.7 17.6 212.5 18.4"
            style={{
              fill: "#fa0f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="76.3 28.3 54.8 34.8 63.3 21.7 83.3 15.8 76.3 28.3"
            style={{
              fill: "#ff2500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="405.2 243.6 398.1 235.2 401.9 224.5 409 233.9 405.2 243.6"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="131.9 378.4 133.9 390.8 119.6 394 117.4 381.7 131.9 378.4"
            style={{
              fill: "#00009f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="247.8 30.6 212.5 18.4 212.7 17.6 246.2 29.7 247.8 30.6"
            style={{
              fill: "#ff2500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="257.5 128.4 234.6 122.6 233.9 141.9 256.2 146.2 257.5 128.4"
            style={{
              fill: "#dbff1c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.7 118.8 196.2 117.2 196.9 139.3 213.7 139.6 213.7 118.8"
            style={{
              fill: "#ebff0c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="175.5 234.4 179.7 244.8 175.4 267.7 171.8 256.7 175.5 234.4"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="329.2 213.8 316.7 208.4 318.4 220.9 330.9 225.4 329.2 213.8"
            style={{
              fill: "#3cffba"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="175.4 267.7 180.8 278.9 173.8 299.8 169.2 288.1 175.4 267.7"
            style={{
              fill: "#0098ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="175.7 201.2 177.3 210.2 175.5 234.4 174.2 224.7 175.7 201.2"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178.6 9 147.8 3 152.4 2.4 180.9 8.4 178.6 9"
            style={{
              fill: "#df0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="75.3 379.9 77.9 390.3 64.2 378.3 61.3 367.7 75.3 379.9"
            style={{
              fill: "#0000b2"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="407.6 188.3 391.1 175.4 391.1 161.9 407.6 176 407.6 188.3"
            style={{
              fill: "#8aff6d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="61.3 367.7 64.1 378.3 51.4 363.1 48.2 352.1 61.3 367.7"
            style={{
              fill: "#0000da"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="299.9 159.4 278.8 152.1 277.9 168.6 298.8 174.5 299.9 159.4"
            style={{
              fill: "#a7ff50"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178.2 169.6 175.7 176.4 175.7 201.2 178.2 193.5 178.2 169.6"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="339.6 240.5 333.6 236.1 337.2 245.5 343.3 249.4 339.6 240.5"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="369 276.5 370.8 272.2 377.3 271.6 375.4 276.3 369 276.5"
            style={{
              fill: "#00a8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="357.5 98.3 326.7 81.3 323.4 72.4 353.6 89.8 357.5 98.3"
            style={{
              fill: "#ffab00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="14.5 276.1 17.8 289.5 11.4 265 8 251.2 14.5 276.1"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="280.5 135.8 257.5 128.4 256.2 146.2 278.8 152.1 280.5 135.8"
            style={{
              fill: "#d4ff23"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="362.8 275 364.5 271 370.8 272.2 369 276.5 362.8 275"
            style={{
              fill: "#00acff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="47.6 51.3 30.7 61.9 39.1 44.8 54.8 34.8 47.6 51.3"
            style={{
              fill: "#f50"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="1.9 211.2 3.8 225.5 1.9 199.4 0 185 1.9 211.2"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="89.9 388.5 92.2 398.7 77.9 390.3 75.3 379.9 89.9 388.5"
            style={{
              fill: "#00009b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="282.9 45.3 247.8 30.6 246.2 29.7 279.7 44.2 282.9 45.3"
            style={{
              fill: "#ff3f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="375.4 276.3 377.3 271.6 383.6 269.2 381.6 274.4 375.4 276.3"
            style={{
              fill: "#00acff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="184.6 141.2 177 145.2 178.2 169.6 185.3 164.6 184.6 141.2"
            style={{
              fill: "#beff39"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="173.8 299.8 176.8 312.8 167.5 331 164.9 317.7 173.8 299.8"
            style={{
              fill: "#0050ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="316.3 181.5 298.8 174.5 298.5 189.4 316 195.1 316.3 181.5"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="409 233.9 401.9 224.5 404.7 213 411.9 223.5 409 233.9"
            style={{
              fill: "#30ffc7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="388.1 136.6 363.4 120.6 360.8 108.6 385.3 125.5 388.1 136.6"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="356.8 271.9 358.5 268 364.5 271 362.8 275 356.8 271.9"
            style={{
              fill: "#00b4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="48.2 352.1 51.3 363.1 39.8 344.9 36.4 333.6 48.2 352.1"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="319.7 65.9 285.8 49.4 282.9 45.3 315.6 61.8 319.7 65.9"
            style={{
              fill: "#ff6400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="381.7 274.4 383.6 269.2 389.7 265.1 387.7 270.9 381.7 274.4"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="235.5 104 213.7 98.8 213.7 118.8 234.6 122.6 235.5 104"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="196.2 117.2 183.1 117.9 184.5 141.2 196.9 139.3 196.2 117.2"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212.7 17.6 180.9 8.4 183.3 11.4 212.9 20.3 212.7 17.6"
            style={{
              fill: "#ed0400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="246.2 29.7 212.7 17.6 212.9 20.3 244.5 32 246.2 29.7"
            style={{
              fill: "#ff1e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="259.2 111.3 235.5 104 234.6 122.6 257.5 128.4 259.2 111.3"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="351.2 267.3 352.8 263.4 358.5 268 356.8 272 351.2 267.3"
            style={{
              fill: "#00c0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="4.5 143.7 0.5 159.2 3.5 134.5 7.4 119.2 4.5 143.7"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="104.8 393.2 106.8 403.4 92.2 398.7 89.9 388.5 104.8 393.2"
            style={{
              fill: "#000092"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.7 98.8 195.2 95.8 196.2 117.2 213.7 118.8 213.7 98.8"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="387.7 270.9 389.7 265.1 395.4 259.3 393.3 265.9 387.7 270.9"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="328.4 201.6 316 195.1 316.7 208.4 329.2 213.8 328.4 201.6"
            style={{
              fill: "#5dff9a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="336.9 230.6 330.9 225.4 333.6 236.1 339.7 240.5 336.9 230.6"
            style={{
              fill: "#26ffd1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="407.6 176 391.1 161.9 390.1 148.8 406.5 163.9 407.6 176"
            style={{
              fill: "#aaff4d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.8 3 121.3 0.6 128.2 0 152.4 2.4 147.8 3"
            style={{
              fill: "#c80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="145.5 371.1 147.4 383.6 133.9 390.8 131.8 378.4 145.5 371.1"
            style={{
              fill: "#0000c4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="346.1 261.3 347.7 257.1 352.8 263.4 351.2 267.3 346.1 261.3"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="114.8 5.2 91.2 6.9 99.8 2 121.3 0.6 114.8 5.2"
            style={{
              fill: "#cd0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="302 144.6 280.5 135.8 278.8 152.1 299.9 159.4 302 144.6"
            style={{
              fill: "#ceff29"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="279.7 44.2 246.2 29.7 244.6 32 276.4 46 279.7 44.2"
            style={{
              fill: "#ff3800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="282.9 120.2 259.2 111.3 257.5 128.4 280.5 135.8 282.9 120.2"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="411.9 223.5 404.8 213 406.7 200.8 413.8 212.4 411.9 223.5"
            style={{
              fill: "#4dffaa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="36.4 333.6 39.8 344.9 29.8 324.3 26.2 312.6 36.4 333.6"
            style={{
              fill: "#0030ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="180.9 8.4 152.4 2.4 157.1 5.7 183.3 11.4 180.9 8.4"
            style={{
              fill: "#d10000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="353.6 89.8 323.5 72.4 319.7 65.9 349 83.5 353.6 89.8"
            style={{
              fill: "#ff9100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="167.5 331 169.1 345.1 158.1 359.9 156.5 345.9 167.5 331"
            style={{
              fill: "#0014ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="236.7 86.6 213.7 79.9 213.7 98.8 235.5 104 236.7 86.6"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="393.4 265.9 395.5 259.3 400.7 252.1 398.5 259.6 393.4 265.9"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="317.6 167.7 299.9 159.4 298.8 174.5 316.3 181.5 317.6 167.7"
            style={{
              fill: "#a4ff53"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="315.6 61.8 282.9 45.3 279.7 44.2 311.2 60.5 315.6 61.8"
            style={{
              fill: "#f50"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="385.3 125.5 360.8 108.6 357.5 98.3 381.6 115.8 385.3 125.5"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="244.6 32 212.9 20.3 213.1 26.4 242.9 37.4 244.6 32"
            style={{
              fill: "#ff1e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="24.2 82 12.5 96.3 19.8 75.8 30.7 61.9 24.2 82"
            style={{
              fill: "#ff8d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="261.4 95.3 236.7 86.6 235.5 104 259.2 111.3 261.4 95.3"
            style={{
              fill: "#ffb600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212.9 20.3 183.3 11.4 185.7 18.1 213.1 26.4 212.9 20.3"
            style={{
              fill: "#f10800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="83.3 15.8 63.3 21.7 73.1 12.3 91.2 6.9 83.3 15.8"
            style={{
              fill: "#e40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="341.8 253.9 343.3 249.4 347.7 257.1 346.1 261.3 341.8 253.9"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="195.2 95.8 180.9 95.2 183.1 117.8 196.2 117.2 195.2 95.8"
            style={{
              fill: "#ffc100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.7 79.9 193.8 75.6 195.2 95.8 213.7 98.8 213.7 79.9"
            style={{
              fill: "#ff9800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="119.6 394 121.3 404.2 106.8 403.4 104.8 393.2 119.6 394"
            style={{
              fill: "#000096"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183.1 117.8 174.7 120.7 177 145.2 184.6 141.2 183.1 117.8"
            style={{
              fill: "#f8f500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="335.1 219.9 329.2 213.8 330.9 225.4 336.9 230.6 335.1 219.9"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="8 251.2 11.4 265 7.2 239.6 3.8 225.5 8 251.2"
            style={{
              fill: "#13fce4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="276.4 46 244.5 32 242.8 37.4 273.1 50.8 276.4 46"
            style={{
              fill: "#ff3800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="177 145.2 174.2 151.1 175.7 176.4 178.2 169.6 177 145.2"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="328.7 189.2 316.3 181.5 316 195.1 328.4 201.6 328.7 189.2"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="238 70.8 213.6 62.8 213.7 79.9 236.7 86.6 238 70.8"
            style={{
              fill: "#ff7e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="398.5 259.6 400.7 252.1 405.2 243.6 403 251.9 398.5 259.6"
            style={{
              fill: "#09f0ee"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="175.7 176.5 177.3 184.7 177.3 210.2 175.7 201.2 175.7 176.5"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="285.9 105.7 261.4 95.3 259.2 111.3 282.9 120.2 285.9 105.7"
            style={{
              fill: "#ffc400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="242.9 37.4 213.1 26.4 213.3 35.7 241.2 45.9 242.9 37.4"
            style={{
              fill: "#ff2900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="177.3 210.2 181.9 220.1 179.7 244.9 175.5 234.4 177.3 210.2"
            style={{
              fill: "#40ffb7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="263.9 80.9 238.1 70.8 236.7 86.6 261.4 95.3 263.9 80.9"
            style={{
              fill: "#ff9100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="406.5 163.9 390.1 148.8 388.1 136.6 404.4 152.6 406.5 163.9"
            style={{
              fill: "#caff2c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="413.8 212.4 406.7 200.8 407.6 188.4 414.8 201 413.8 212.4"
            style={{
              fill: "#6aff8d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="304.8 130.5 282.9 120.2 280.5 135.8 302 144.6 304.8 130.5"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="179.7 244.9 185.5 255.6 180.8 278.9 175.4 267.7 179.7 244.9"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="349 83.5 319.7 65.9 315.6 61.8 344.1 79.5 349 83.5"
            style={{
              fill: "#ff7a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="311.2 60.5 279.7 44.2 276.4 46 306.6 61.9 311.2 60.5"
            style={{
              fill: "#ff4e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.1 26.4 185.7 18.1 188 28.1 213.3 35.7 213.1 26.4"
            style={{
              fill: "#ff1300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183.3 11.4 157.1 5.7 161.8 12.8 185.7 18.1 183.3 11.4"
            style={{
              fill: "#d60000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="239.5 57.1 213.5 47.9 213.6 62.8 238 70.8 239.5 57.1"
            style={{
              fill: "#ff5900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="0 185 1.9 199.4 2.4 173.8 0.5 159.2 0 185"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="241.2 45.9 213.3 35.7 213.5 47.9 239.6 57.1 241.2 45.9"
            style={{
              fill: "#ff3b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="26.2 312.6 29.8 324.3 21.5 301.8 17.8 289.5 26.2 312.6"
            style={{
              fill: "#0070ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="338.2 245.5 339.6 240.5 343.3 249.4 341.8 254 338.2 245.5"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="273.1 50.8 242.8 37.4 241.2 45.9 269.9 58.3 273.1 50.8"
            style={{
              fill: "#ff4300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.6 62.8 192.1 57.3 193.8 75.6 213.7 79.9 213.6 62.8"
            style={{
              fill: "#ff6c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="266.8 68.4 239.6 57.1 238.1 70.8 263.9 80.9 266.8 68.4"
            style={{
              fill: "#ff6f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="319.8 154.3 302 144.6 299.9 159.4 317.6 167.7 319.8 154.3"
            style={{
              fill: "#c7ff30"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="54.8 34.8 39.1 44.8 49.1 31.1 63.3 21.7 54.8 34.8"
            style={{
              fill: "#ff1e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="152.4 2.4 128.2 0 135.3 3.4 157.1 5.7 152.4 2.4"
            style={{
              fill: "#b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="381.6 115.8 357.5 98.3 353.6 89.8 377.1 107.8 381.6 115.8"
            style={{
              fill: "#ffbd00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="269.9 58.3 241.2 45.9 239.6 57.1 266.8 68.4 269.9 58.3"
            style={{
              fill: "#f50"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="180.8 278.9 184 291.7 176.8 312.8 173.8 299.8 180.8 278.9"
            style={{
              fill: "#0098ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.3 35.7 188 28.1 190.1 41.3 213.5 47.9 213.3 35.7"
            style={{
              fill: "#ff2900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="213.5 47.9 190.1 41.3 192.1 57.3 213.6 62.8 213.5 47.9"
            style={{
              fill: "#ff4700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="289.4 92.7 263.9 80.9 261.4 95.3 285.9 105.7 289.4 92.7"
            style={{
              fill: "#ffa300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="193.8 75.6 178 73.8 180.9 95.2 195.2 95.8 193.8 75.6"
            style={{
              fill: "#ff8900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="403 251.9 405.2 243.6 409 233.9 406.7 243.2 403 251.9"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="306.6 61.9 276.4 46 273.1 50.8 302.1 65.9 306.6 61.9"
            style={{
              fill: "#ff5200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="334.4 208.6 328.4 201.6 329.2 213.8 335.1 219.8 334.4 208.6"
            style={{
              fill: "#60ff97"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="352.9 279.7 362.8 275 369 276.5 358.9 281.4 352.9 279.7"
            style={{
              fill: "#00bcff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="121.3 0.6 99.8 2 109 1.2 128.2 0 121.3 0.6"
            style={{
              fill: "#b20000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="358.9 281.4 369 276.5 375.4 276.3 365.1 281.5 358.9 281.4"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="158.1 359.9 159.8 372.6 147.4 383.6 145.5 371.1 158.1 359.9"
            style={{
              fill: "#0000f1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="347 276.5 356.8 271.9 362.8 275 352.9 279.7 347 276.5"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="308.3 117.4 285.9 105.7 282.9 120.2 304.8 130.5 308.3 117.4"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="330.1 176.7 317.6 167.7 316.3 181.5 328.7 189.1 330.1 176.7"
            style={{
              fill: "#a0ff56"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="344.1 79.5 315.6 61.8 311.2 60.5 338.8 77.9 344.1 79.5"
            style={{
              fill: "#ff6f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.7 18.1 161.8 12.8 166.3 23.5 188 28.1 185.7 18.1"
            style={{
              fill: "#e40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="293.3 81.5 266.8 68.5 263.9 80.9 289.4 92.7 293.3 81.5"
            style={{
              fill: "#ff8200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="7.4 119.2 3.5 134.5 8.8 111.4 12.5 96.3 7.4 119.2"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="133.9 390.8 135.4 401.1 121.3 404.2 119.6 394 133.9 390.8"
            style={{
              fill: "#0000a8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="180.9 95.2 171.4 97 174.7 120.7 183.1 117.8 180.9 95.2"
            style={{
              fill: "#ffb900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="335.4 236.2 336.9 230.6 339.6 240.5 338.2 245.5 335.4 236.2"
            style={{
              fill: "#30ffc7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="365.1 281.5 375.4 276.3 381.6 274.4 371.1 280.1 365.1 281.5"
            style={{
              fill: "#00bcff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="414.8 201 407.6 188.4 407.6 176 414.7 189.6 414.8 201"
            style={{
              fill: "#87ff70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="302.1 65.9 273.1 50.8 269.9 58.3 297.6 72.5 302.1 65.9"
            style={{
              fill: "#ff5900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="404.4 152.6 388.1 136.6 385.3 125.5 401.3 142.2 404.4 152.6"
            style={{
              fill: "#e7ff0f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="341.6 271.9 351.2 267.3 356.8 272 347 276.5 341.6 271.9"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="297.6 72.5 269.9 58.3 266.8 68.5 293.3 81.5 297.6 72.5"
            style={{
              fill: "#ff6c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="192.1 57.3 174.6 54.4 178 73.8 193.8 75.6 192.1 57.3"
            style={{
              fill: "#ff5d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="77.9 390.3 79.7 398.2 66.3 386.6 64.2 378.3 77.9 390.3"
            style={{
              fill: "#0000c4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="322.9 141.6 304.8 130.5 302 144.6 319.8 154.3 322.9 141.6"
            style={{
              fill: "#e7ff0f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="371.1 280.1 381.6 274.4 387.7 270.9 377 277.2 371.1 280.1"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="377.1 107.8 353.6 89.8 349 83.5 372.1 101.7 377.1 107.8"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="188 28.1 166.3 23.5 170.6 37.5 190.1 41.3 188 28.1"
            style={{
              fill: "#ff1300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="64.2 378.3 66.3 386.6 53.9 371.8 51.4 363.1 64.2 378.3"
            style={{
              fill: "#0000e8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="406.7 243.2 409 233.9 411.9 223.5 409.5 233.7 406.7 243.2"
            style={{
              fill: "#36ffc1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="336.7 266 346.1 261.3 351.2 267.3 341.6 271.9 336.7 266"
            style={{
              fill: "#00e0fb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="190.1 41.3 170.6 37.5 174.6 54.4 192.1 57.3 190.1 41.3"
            style={{
              fill: "#ff3400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.7 120.7 171.1 125.7 174.2 151.1 177 145.2 174.7 120.7"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="176.8 312.8 178.4 327 169.1 345.1 167.5 331 176.8 312.8"
            style={{
              fill: "#0054ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="91.2 6.9 73.1 12.3 83.8 7 99.8 2 91.2 6.9"
            style={{
              fill: "#b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.1 5.7 135.3 3.4 142.4 10.8 161.8 12.8 157.1 5.7"
            style={{
              fill: "#bf0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="312.5 105.8 289.4 92.7 285.9 105.7 308.3 117.4 312.5 105.8"
            style={{
              fill: "#ffb600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="338.7 77.9 311.2 60.5 306.6 61.9 333.3 78.8 338.7 77.9"
            style={{
              fill: "#ff6800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="92.2 398.7 93.6 406.4 79.7 398.2 77.9 390.3 92.2 398.7"
            style={{
              fill: "#0000ad"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="17.8 289.5 21.5 301.8 15.3 277.7 11.4 265 17.8 289.5"
            style={{
              fill: "#00b4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="3.8 225.5 7.2 239.6 5.3 213.9 1.9 199.4 3.8 225.5"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="334.6 197.2 328.7 189.2 328.4 201.6 334.4 208.6 334.6 197.2"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="30.7 61.9 19.8 75.8 29.2 58.1 39.1 44.8 30.7 61.9"
            style={{
              fill: "#ff4e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="377 277.2 387.7 270.9 393.3 266 382.5 272.8 377 277.2"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="51.4 363.1 53.9 371.8 42.7 354.1 39.8 345 51.4 363.1"
            style={{
              fill: "#0004ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="333.7 226.2 335.1 219.9 336.9 230.6 335.4 236.2 333.7 226.2"
            style={{
              fill: "#49ffad"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.2 151.1 175.5 158.6 177.4 184.7 175.7 176.5 174.2 151.1"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="332.4 259 341.8 254 346.1 261.3 336.7 266 332.4 259"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="332.4 164.6 319.8 154.3 317.6 167.7 330 176.7 332.4 164.6"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="317.2 95.8 293.3 81.5 289.4 92.7 312.5 105.8 317.2 95.8"
            style={{
              fill: "#ff9800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178 73.8 167 74.7 171.4 97 180.9 95.2 178 73.8"
            style={{
              fill: "#ff7e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="333.3 78.8 306.6 61.9 302.1 65.9 327.8 82.2 333.3 78.8"
            style={{
              fill: "#ff6c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="414.7 189.6 407.6 176 406.5 163.9 413.5 178.5 414.7 189.6"
            style={{
              fill: "#a7ff50"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="401.3 142.2 385.3 125.5 381.6 115.8 397.4 133.1 401.3 142.2"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="326.8 129.9 308.3 117.4 304.8 130.5 322.9 141.6 326.8 129.9"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="372.1 101.7 349 83.5 344.1 79.5 366.5 97.7 372.1 101.7"
            style={{
              fill: "#ff9400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="177.4 184.7 181.9 194.1 181.9 220.1 177.3 210.2 177.4 184.7"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="322.4 87.9 297.6 72.5 293.3 81.5 317.2 95.8 322.4 87.9"
            style={{
              fill: "#ff8200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="327.8 82.2 302.1 65.9 297.6 72.5 322.4 87.9 327.8 82.2"
            style={{
              fill: "#ff7300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="106.8 403.4 107.8 411 93.6 406.4 92.2 398.7 106.8 403.4"
            style={{
              fill: "#0000a4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="128.2 0 109 1.2 118.5 4.6 135.3 3.4 128.2 0"
            style={{
              fill: "#a40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="409.5 233.7 411.9 223.5 413.8 212.4 411.4 223.6 409.5 233.7"
            style={{
              fill: "#50ffa7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="382.5 272.8 393.3 266 398.5 259.6 387.5 267.1 382.5 272.8"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="161.8 12.8 142.4 10.8 149.3 22 166.3 23.5 161.8 12.8"
            style={{
              fill: "#cd0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="181.9 220.1 188 230.5 185.6 255.6 179.7 244.8 181.9 220.1"
            style={{
              fill: "#40ffb7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="328.9 250.9 338.2 245.5 341.8 254 332.4 259 328.9 250.9"
            style={{
              fill: "#1fffd7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="0.5 159.2 2.4 173.8 5.4 149.1 3.5 134.5 0.5 159.2"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="63.3 21.7 49.1 31.2 60.5 21.3 73.1 12.3 63.3 21.7"
            style={{
              fill: "#da0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.4 383.6 148.7 394.1 135.4 401.1 133.9 390.8 147.4 383.6"
            style={{
              fill: "#0000cd"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="39.8 345 42.7 354.1 32.9 334.1 29.8 324.4 39.8 345"
            style={{
              fill: "#003cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.6 54.4 161.8 54.4 167 74.7 178 73.9 174.6 54.4"
            style={{
              fill: "#ff4e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="335.9 185.9 330 176.7 328.7 189.2 334.6 197.2 335.9 185.9"
            style={{
              fill: "#9dff5a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="332.9 215.8 334.4 208.6 335.1 219.9 333.7 226.2 332.9 215.8"
            style={{
              fill: "#63ff94"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="166.3 23.5 149.3 22 155.8 36.6 170.6 37.5 166.3 23.5"
            style={{
              fill: "#e80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="331.5 119.5 312.5 105.8 308.3 117.4 326.8 129.9 331.5 119.5"
            style={{
              fill: "#ffc400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.4 97 166.7 101.2 171.1 125.7 174.7 120.7 171.4 97"
            style={{
              fill: "#ffae00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="366.5 97.7 344.1 79.5 338.8 77.9 360.6 95.8 366.5 97.7"
            style={{
              fill: "#ff8600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="335.6 153.1 322.9 141.6 319.8 154.3 332.4 164.6 335.6 153.1"
            style={{
              fill: "#deff19"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.5 255.6 189 268.3 184 291.7 180.8 278.9 185.5 255.6"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="170.6 37.5 155.8 36.6 161.8 54.4 174.6 54.4 170.6 37.5"
            style={{
              fill: "#f20"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169.1 345.1 170.8 358 159.8 372.6 158.1 359.9 169.1 345.1"
            style={{
              fill: "#0018ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="397.4 133.1 381.6 115.8 377.1 107.8 392.7 125.5 397.4 133.1"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="387.5 267.1 398.5 259.6 403 251.9 391.8 260.2 387.5 267.1"
            style={{
              fill: "#0ff8e7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="413.5 178.5 406.5 163.9 404.4 152.6 411.3 168 413.5 178.5"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="326.2 242.1 335.4 236.2 338.2 245.5 328.9 250.9 326.2 242.1"
            style={{
              fill: "#33ffc4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="121.3 404.2 121.9 411.8 107.8 411 106.8 403.4 121.3 404.2"
            style={{
              fill: "#0000a8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="411.4 223.6 413.8 212.4 414.8 201 412.3 213.1 411.4 223.6"
            style={{
              fill: "#6aff8d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="99.8 2 83.8 7 95.1 6 109 1.2 99.8 2"
            style={{
              fill: "#9f0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="335.3 285 352.9 279.7 358.9 281.4 341.1 286.9 335.3 285"
            style={{
              fill: "#00c8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="336.7 110.8 317.2 95.8 312.5 105.8 331.5 119.5 336.7 110.8"
            style={{
              fill: "#ffab00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="329.8 281.7 347 276.5 352.9 279.7 335.3 285 329.8 281.7"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="360.6 95.8 338.7 77.9 333.3 78.8 354.5 96.3 360.6 95.8"
            style={{
              fill: "#ff8200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="11.4 265 15.3 277.7 11.2 252.8 7.2 239.6 11.4 265"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="12.5 96.3 8.8 111.4 16.5 90.6 19.8 75.8 12.5 96.3"
            style={{
              fill: "#ff8d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="341.1 286.9 358.9 281.4 365.1 281.5 347 287.3 341.1 286.9"
            style={{
              fill: "#00c8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="324.5 277.1 341.6 271.9 347 276.5 329.7 281.7 324.5 277.1"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="135.3 3.4 118.5 4.6 128 12.1 142.4 10.8 135.3 3.4"
            style={{
              fill: "#a80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.1 125.7 171.7 132.7 175.5 158.6 174.2 151.1 171.1 125.7"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="342.4 103.8 322.4 87.9 317.2 95.8 336.7 110.8 342.4 103.8"
            style={{
              fill: "#ff9800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="354.5 96.3 333.3 78.8 327.8 82.2 348.4 99 354.5 96.3"
            style={{
              fill: "#ff8200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="333.1 205.3 334.6 197.2 334.4 208.6 332.9 215.8 333.1 205.3"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="338.3 174.9 332.4 164.6 330.1 176.7 336 185.9 338.3 174.9"
            style={{
              fill: "#baff3c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="339.7 142.7 326.8 129.9 322.9 141.6 335.6 153.1 339.7 142.7"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="347 287.3 365.1 281.5 371.1 280.1 352.9 286.2 347 287.3"
            style={{
              fill: "#00c8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="391.8 260.2 403 251.9 406.7 243.2 395.4 252.3 391.8 260.2"
            style={{
              fill: "#26ffd1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="319.8 271.2 336.7 266 341.6 271.9 324.5 277.1 319.8 271.2"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="29.8 324.3 32.9 334.1 24.9 312.2 21.5 301.8 29.8 324.3"
            style={{
              fill: "#0078ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="348.4 99 327.8 82.2 322.4 87.9 342.4 103.9 348.4 99"
            style={{
              fill: "#ff8900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="392.7 125.5 377.1 107.8 372.1 101.7 387.3 119.6 392.7 125.5"
            style={{
              fill: "#ffb900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="184 291.7 185.6 306.1 178.4 327 176.8 312.8 184 291.7"
            style={{
              fill: "#0098ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="1.9 199.4 5.3 213.9 5.9 188.5 2.4 173.8 1.9 199.4"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="167 74.7 160.8 78.1 166.7 101.2 171.4 97 167 74.7"
            style={{
              fill: "#ff7300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="324.5 232.7 333.7 226.2 335.4 236.2 326.2 242.1 324.5 232.7"
            style={{
              fill: "#4dffaa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="411.3 168 404.4 152.6 401.3 142.2 408.2 158.3 411.3 168"
            style={{
              fill: "#deff19"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="39.1 44.8 29.2 58.1 40.4 43.9 49.1 31.1 39.1 44.8"
            style={{
              fill: "#ff1a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="352.9 286.2 371.1 280.1 377 277.2 358.5 283.7 352.9 286.2"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="315.7 264.3 332.4 259 336.7 266 319.8 271.2 315.7 264.3"
            style={{
              fill: "#13fce4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="412.3 213.1 414.8 201 414.7 189.6 412.2 202.6 412.3 213.1"
            style={{
              fill: "#87ff70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="175.5 158.6 179.7 167.5 181.9 194.1 177.4 184.7 175.5 158.6"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.4 10.8 128 12.1 137.2 23.6 149.3 22 142.4 10.8"
            style={{
              fill: "#b60000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="344.6 133.5 331.5 119.5 326.8 129.9 339.7 142.7 344.6 133.5"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="387.3 119.6 372 101.7 366.5 97.7 381.5 115.6 387.3 119.6"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="159.8 372.6 160.8 383.3 148.7 394.1 147.4 383.6 159.8 372.6"
            style={{
              fill: "#0000fa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="135.4 401.1 135.6 408.7 121.9 411.8 121.3 404.2 135.4 401.1"
            style={{
              fill: "#00b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="395.4 252.3 406.7 243.2 409.5 233.7 398.1 243.5 395.4 252.3"
            style={{
              fill: "#3cffba"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="358.5 283.7 377 277.2 382.5 272.8 363.7 279.9 358.5 283.7"
            style={{
              fill: "#00dcfe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="341.5 164.6 335.6 153.1 332.4 164.6 338.3 174.9 341.5 164.6"
            style={{
              fill: "#d4ff23"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="161.8 54.4 153.9 57 160.8 78.1 167 74.7 161.8 54.4"
            style={{
              fill: "#ff3f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="334.4 194.9 336 185.9 334.6 197.2 333.1 205.3 334.4 194.9"
            style={{
              fill: "#9aff5d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="73.1 12.3 60.5 21.3 73 15.5 83.8 7 73.1 12.3"
            style={{
              fill: "#b20000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="79.7 398.2 80.3 403.3 67.4 392 66.3 386.6 79.7 398.2"
            style={{
              fill: "#0000d6"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="312.3 256.5 328.9 250.9 332.4 259 315.7 264.3 312.3 256.5"
            style={{
              fill: "#26ffd1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="66.3 386.6 67.4 392 55.5 377.8 53.9 371.8 66.3 386.6"
            style={{
              fill: "#0000fa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="323.7 223 332.9 215.8 333.7 226.2 324.5 232.7 323.7 223"
            style={{
              fill: "#63ff94"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="181.9 194.1 188.1 204.2 188 230.5 181.9 220.1 181.9 194.1"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="149.3 22 137.2 23.6 145.9 38.7 155.8 36.6 149.3 22"
            style={{
              fill: "#d60000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="109 1.2 95.1 5.9 106.8 9.2 118.5 4.6 109 1.2"
            style={{
              fill: "#960000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="408.2 158.3 401.3 142.2 397.4 133.1 404.2 149.7 408.2 158.3"
            style={{
              fill: "#f4f802"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="155.8 36.6 145.9 38.7 153.8 57 161.8 54.4 155.8 36.6"
            style={{
              fill: "#ff1300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="93.6 406.4 93.7 411.2 80.3 403.3 79.7 398.2 93.6 406.4"
            style={{
              fill: "#0000bf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="350.1 125.8 336.7 110.8 331.5 119.5 344.6 133.5 350.1 125.8"
            style={{
              fill: "#ffc100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="3.5 134.5 5.4 149.1 10.7 126.1 8.8 111.4 3.5 134.5"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="381.5 115.6 366.5 97.7 360.6 95.8 375.2 113.6 381.5 115.6"
            style={{
              fill: "#ff9c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="412.2 202.6 414.7 189.6 413.5 178.5 411 192.3 412.2 202.6"
            style={{
              fill: "#a0ff56"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="166.7 101.2 166.2 107.5 171.7 132.6 171.1 125.7 166.7 101.2"
            style={{
              fill: "#ffab00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="53.9 371.8 55.4 377.8 44.6 360.8 42.7 354.1 53.9 371.8"
            style={{
              fill: "#0014ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="363.7 279.9 382.5 272.8 387.5 267.1 368.5 274.7 363.7 279.9"
            style={{
              fill: "#06ecf1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="21.5 301.8 24.9 312.2 18.8 288.9 15.3 277.7 21.5 301.8"
            style={{
              fill: "#00bcff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178.4 327 179.9 340.2 170.8 358 169.1 345.1 178.4 327"
            style={{
              fill: "#0054ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="309.8 248.1 326.2 242.1 328.9 250.9 312.3 256.5 309.8 248.1"
            style={{
              fill: "#39ffbe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="356.1 119.8 342.4 103.8 336.7 110.8 350.1 125.8 356.1 119.8"
            style={{
              fill: "#ffae00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="188 230.5 191.6 243 189 268.3 185.5 255.6 188 230.5"
            style={{
              fill: "#40ffb7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="375.2 113.6 360.6 95.8 354.5 96.3 368.8 113.7 375.2 113.6"
            style={{
              fill: "#ff9800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="7.2 239.6 11.2 252.8 9.3 227.7 5.3 213.9 7.2 239.6"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="398.1 243.5 409.5 233.7 411.4 223.6 399.9 234.2 398.1 243.5"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="345.7 155.3 339.7 142.7 335.6 153.1 341.6 164.6 345.7 155.3"
            style={{
              fill: "#eeff09"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="362.4 115.7 348.4 99 342.4 103.8 356.1 119.8 362.4 115.7"
            style={{
              fill: "#ff9f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="368.8 113.7 354.5 96.3 348.4 99 362.4 115.7 368.8 113.7"
            style={{
              fill: "#ff9800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="336.7 185 338.3 174.9 336 185.9 334.4 194.9 336.7 185"
            style={{
              fill: "#b4ff43"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="107.8 411 107.4 415.6 93.7 411.2 93.6 406.4 107.8 411"
            style={{
              fill: "#0000b6"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="306.1 287.5 329.7 281.7 335.3 285 311.4 290.9 306.1 287.5"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="404.2 149.7 397.4 133.1 392.7 125.5 399.4 142.5 404.2 149.7"
            style={{
              fill: "#ffde00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="324 213.2 333.1 205.3 332.9 215.8 323.7 223 324 213.2"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="311.4 290.9 335.3 285 341.1 286.9 317 293 311.4 290.9"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="301.1 282.8 324.5 277.1 329.7 281.7 306.1 287.5 301.1 282.8"
            style={{
              fill: "#00e0fb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="317 293 341.2 286.9 347 287.3 322.6 293.7 317 293"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="19.8 75.8 16.5 90.6 26.3 72.7 29.2 58.1 19.8 75.8"
            style={{
              fill: "#ff4e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="296.6 276.9 319.8 271.2 324.5 277.1 301.1 282.8 296.6 276.9"
            style={{
              fill: "#09f0ee"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="368.5 274.7 387.5 267.1 391.8 260.2 372.6 268.4 368.5 274.7"
            style={{
              fill: "#19ffde"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="42.7 354.1 44.6 360.8 35.3 341.6 32.9 334.1 42.7 354.1"
            style={{
              fill: "#0048ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="308.1 239.1 324.5 232.7 326.2 242.1 309.8 248.1 308.1 239.1"
            style={{
              fill: "#50ffa7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="411 192.3 413.5 178.5 411.4 168 408.8 182.4 411 192.3"
            style={{
              fill: "#beff39"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="118.5 4.6 106.8 9.2 118.5 16.7 128 12.1 118.5 4.6"
            style={{
              fill: "#960000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="350.6 147.1 344.6 133.5 339.7 142.7 345.7 155.3 350.6 147.1"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="148.7 394.1 148.5 402 135.6 408.7 135.4 401.1 148.7 394.1"
            style={{
              fill: "#0000da"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="322.6 293.7 347 287.3 352.9 286.2 328.1 292.9 322.6 293.7"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.7 132.6 175.4 141.1 179.7 167.5 175.5 158.6 171.7 132.6"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="292.7 270.1 315.7 264.3 319.8 271.2 296.6 276.9 292.7 270.1"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.8 78.1 159 83.8 166.2 107.5 166.7 101.2 160.8 78.1"
            style={{
              fill: "#ff6c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="2.4 173.8 5.9 188.5 8.8 164.2 5.4 149.1 2.4 173.8"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="399.9 234.2 411.4 223.6 412.3 213.1 400.7 224.4 399.9 234.2"
            style={{
              fill: "#6dff8a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="49.1 31.2 40.4 43.9 53.1 33.4 60.5 21.3 49.1 31.2"
            style={{
              fill: "#d10000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="399.4 142.5 392.7 125.5 387.3 119.6 393.9 136.8 399.4 142.5"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="189 268.3 190.6 282.7 185.6 306.1 184 291.7 189 268.3"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="339.9 175.7 341.6 164.6 338.3 174.9 336.7 185 339.9 175.7"
            style={{
              fill: "#ceff29"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="83.8 7 73 15.5 86.2 14.1 95.1 6 83.8 7"
            style={{
              fill: "#960000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="325.2 203.6 334.4 194.9 333.1 205.3 324 213.2 325.2 203.6"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="121.9 411.8 120.9 416.3 107.4 415.6 107.8 411 121.9 411.8"
            style={{
              fill: "#00b"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="328.1 292.9 352.9 286.2 358.5 283.7 333.4 290.8 328.1 292.9"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="170.8 358 171.6 369.1 160.8 383.3 159.8 372.6 170.8 358"
            style={{
              fill: "#0020ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="372.6 268.4 391.8 260.2 395.4 252.3 376 261 372.6 268.4"
            style={{
              fill: "#29ffce"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="289.4 262.4 312.3 256.5 315.7 264.3 292.7 270.1 289.4 262.4"
            style={{
              fill: "#29ffce"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="356.2 140.4 350.1 125.8 344.6 133.5 350.6 147.1 356.2 140.4"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="307.3 229.9 323.7 223 324.5 232.7 308.1 239.1 307.3 229.9"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="408.8 182.4 411.3 168 408.2 158.3 405.7 173.3 408.8 182.4"
            style={{
              fill: "#d4ff23"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="393.9 136.8 387.3 119.6 381.5 115.6 388 132.8 393.9 136.8"
            style={{
              fill: "#ffb900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="128 12.1 118.5 16.7 129.8 28.3 137.2 23.6 128 12.1"
            style={{
              fill: "#a80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="15.3 277.7 18.8 288.9 14.9 264.7 11.2 252.8 15.3 277.7"
            style={{
              fill: "#19ffde"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="179.7 167.5 185.7 177.4 188.1 204.2 181.9 194.1 179.7 167.5"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="153.9 57 150.4 62.3 159 83.8 160.8 78.1 153.9 57"
            style={{
              fill: "#ff3400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="32.9 334.1 35.3 341.6 27.6 320.5 24.9 312.2 32.9 334.1"
            style={{
              fill: "#0080ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="362.2 135.3 356.1 119.8 350.1 125.8 356.2 140.4 362.2 135.3"
            style={{
              fill: "#ffc100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="333.4 290.8 358.5 283.8 363.7 279.9 338.4 287.3 333.4 290.8"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="400.7 224.4 412.3 213.1 412.2 202.6 400.6 214.6 400.7 224.4"
            style={{
              fill: "#87ff70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="287 254 309.7 248.1 312.3 256.6 289.4 262.4 287 254"
            style={{
              fill: "#3cffba"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="344 167.3 345.7 155.3 341.6 164.6 339.9 175.7 344 167.3"
            style={{
              fill: "#e4ff13"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="388 132.8 381.5 115.6 375.2 113.6 381.6 130.6 388 132.8"
            style={{
              fill: "#ffb200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="137.2 23.6 129.8 28.3 140.6 43.7 145.9 38.7 137.2 23.6"
            style={{
              fill: "#c80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="145.9 38.7 140.6 43.7 150.4 62.3 153.9 57 145.9 38.7"
            style={{
              fill: "#f10800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="327.3 194.4 336.7 185 334.4 194.9 325.2 203.6 327.3 194.4"
            style={{
              fill: "#b1ff46"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="368.6 131.9 362.3 115.7 356.1 119.8 362.2 135.3 368.6 131.9"
            style={{
              fill: "#ffb200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="8.8 111.4 10.7 126.1 18.4 105.5 16.5 90.6 8.8 111.4"
            style={{
              fill: "#ff8d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="376 261 395.4 252.3 398.1 243.5 378.6 252.8 376 261"
            style={{
              fill: "#40ffb7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="381.6 130.6 375.2 113.6 368.8 113.6 375.1 130.3 381.6 130.6"
            style={{
              fill: "#ffab00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="375.1 130.3 368.8 113.6 362.3 115.7 368.6 131.9 375.1 130.3"
            style={{
              fill: "#ffae00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="307.5 220.6 324 213.2 323.7 223 307.3 229.9 307.5 220.6"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="277.7 293.9 306.1 287.5 311.4 290.9 282.8 297.6 277.7 293.9"
            style={{
              fill: "#00dcfe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="273 289 301.1 282.8 306.1 287.5 277.7 293.9 273 289"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="5.3 213.9 9.3 227.7 9.9 202.9 5.9 188.5 5.3 213.9"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="405.7 173.3 408.2 158.3 404.2 149.7 401.7 165.2 405.7 173.3"
            style={{
              fill: "#ebff0c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="80.3 403.3 79.7 405.3 67.4 394.6 67.4 392 80.3 403.3"
            style={{
              fill: "#0000ed"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.6 306.1 187.1 319.5 179.9 340.2 178.4 327.1 185.6 306.1"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="188.1 204.2 191.7 216.6 191.6 243 188 230.5 188.1 204.2"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="282.8 297.6 311.4 290.9 317 293 288 299.9 282.8 297.6"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="67.4 392 67.4 394.6 56 381 55.5 377.8 67.4 392"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="166.2 107.5 169 115.6 175.4 141.1 171.7 132.6 166.2 107.5"
            style={{
              fill: "#ffa300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="268.7 283 296.6 276.9 301.1 282.8 273 289 268.7 283"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="338.4 287.3 363.7 279.9 368.5 274.7 342.9 282.5 338.4 287.3"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="135.6 408.7 134.1 413.4 121 416.3 121.9 411.8 135.6 408.7"
            style={{
              fill: "#0000cd"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="285.3 245.3 308.1 239.1 309.7 248.1 287 254 285.3 245.3"
            style={{
              fill: "#50ffa7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="93.7 411.2 92.5 412.8 79.7 405.3 80.3 403.3 93.7 411.2"
            style={{
              fill: "#0000d6"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="348.8 160 350.6 147.1 345.7 155.3 344 167.3 348.8 160"
            style={{
              fill: "#f8f500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="288 299.9 317 293 322.6 293.7 293.3 300.8 288 299.9"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="95.1 6 86.2 14.1 99.8 17.1 106.8 9.2 95.1 6"
            style={{
              fill: "#890000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="265 276.1 292.7 270.1 296.6 276.9 268.7 283.1 265 276.1"
            style={{
              fill: "#19ffde"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.8 383.3 160.3 391.5 148.5 402 148.7 394.1 160.8 383.3"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="400.6 214.6 412.2 202.6 411 192.3 399.4 204.9 400.6 214.6"
            style={{
              fill: "#a0ff56"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="55.5 377.8 56 381 45.7 364.8 44.6 360.8 55.5 377.8"
            style={{
              fill: "#0024ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="29.2 58.1 26.3 72.7 37.9 58.2 40.4 43.9 29.2 58.1"
            style={{
              fill: "#ff1600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="330.4 185.9 339.9 175.7 336.7 185 327.3 194.4 330.4 185.9"
            style={{
              fill: "#c7ff30"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="401.7 165.2 404.2 149.8 399.4 142.5 397 158.3 401.7 165.2"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="378.6 252.8 398.1 243.5 399.9 234.2 380.3 244.1 378.6 252.8"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="60.5 21.3 53.1 33.4 67 27.2 73 15.5 60.5 21.3"
            style={{
              fill: "#a80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="293.3 300.8 322.6 293.7 328.1 292.9 298.6 300.3 293.3 300.8"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="308.6 211.6 325.2 203.6 324 213.2 307.5 220.6 308.6 211.6"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="261.9 268.3 289.4 262.4 292.7 270.1 265 276.1 261.9 268.3"
            style={{
              fill: "#29ffce"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="24.9 312.2 27.6 320.5 21.7 298 18.8 288.9 24.9 312.2"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="342.9 282.5 368.5 274.7 372.6 268.4 346.8 276.5 342.9 282.5"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="107.4 415.6 105.6 417 92.5 412.8 93.7 411.2 107.4 415.6"
            style={{
              fill: "#0000cd"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="284.6 236.3 307.3 229.9 308.1 239.1 285.3 245.3 284.6 236.3"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="354.3 154.1 356.2 140.4 350.6 147.1 348.8 160 354.3 154.1"
            style={{
              fill: "#ffde00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="5.4 149.1 8.8 164.2 14.1 141.5 10.7 126.1 5.4 149.1"
            style={{
              fill: "#ffd300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="191.6 243 193.2 257.6 190.6 282.7 189 268.3 191.6 243"
            style={{
              fill: "#40ffb7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="298.6 300.3 328.1 292.9 333.4 290.8 303.6 298.4 298.6 300.3"
            style={{
              fill: "#00dcfe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="397 158.3 399.4 142.5 393.9 136.8 391.6 152.8 397 158.3"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="399.4 204.9 411 192.3 408.8 182.5 397.3 195.6 399.4 204.9"
            style={{
              fill: "#b7ff40"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="175.4 141.1 180.8 150.8 185.7 177.4 179.7 167.5 175.4 141.1"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="44.6 360.8 45.7 364.8 36.8 346.4 35.3 341.6 44.6 360.8"
            style={{
              fill: "#0054ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="11.2 252.8 14.9 264.7 13.1 240.3 9.3 227.7 11.2 252.8"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="259.5 260 287 254 289.4 262.4 261.9 268.4 259.5 260"
            style={{
              fill: "#3cffba"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="179.9 340.2 180.6 351.6 171.6 369.1 170.8 358 179.9 340.2"
            style={{
              fill: "#005cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="159 83.8 160.6 91.7 169 115.6 166.2 107.5 159 83.8"
            style={{
              fill: "#ff6400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="360.2 149.7 362.2 135.3 356.2 140.4 354.3 154.1 360.2 149.7"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="334.4 178.3 344 167.3 339.9 175.7 330.4 185.9 334.4 178.3"
            style={{
              fill: "#deff19"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="106.8 9.2 99.8 17.1 113.5 24.4 118.5 16.7 106.8 9.2"
            style={{
              fill: "#890000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="380.3 244.1 399.9 234.2 400.7 224.4 381.1 234.9 380.3 244.1"
            style={{
              fill: "#6dff8a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="391.5 152.8 393.9 136.8 388 132.8 385.6 148.8 391.5 152.8"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="310.7 203 327.3 194.4 325.2 203.6 308.6 211.6 310.7 203"
            style={{
              fill: "#adff49"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="346.8 276.5 372.6 268.4 376 261 350.1 269.6 346.8 276.5"
            style={{
              fill: "#2cffca"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="284.7 227.4 307.5 220.6 307.3 229.9 284.6 236.3 284.7 227.4"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="248.3 303.6 280.2 295.9 275.3 291.6 243.3 299.2 248.3 303.6"
            style={{
              fill: "#00dcfe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="243.3 299.2 275.3 291.6 270.8 286.2 238.7 293.7 243.3 299.2"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="366.5 146.9 368.6 131.9 362.2 135.3 360.2 149.7 366.5 146.9"
            style={{
              fill: "#ffc400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="148.5 402 146.5 406.9 134.1 413.4 135.6 408.7 148.5 402"
            style={{
              fill: "#0000ed"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="303.6 298.4 333.4 290.8 338.4 287.3 308.3 295.1 303.6 298.4"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="253.5 306.6 285.4 298.9 280.2 295.9 248.3 303.6 253.5 306.6"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="121 416.3 118.5 417.7 105.5 417 107.4 415.6 121 416.3"
            style={{
              fill: "#0000d1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="385.6 148.8 388 132.8 381.6 130.6 379.4 146.5 385.6 148.8"
            style={{
              fill: "#ffc400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="257.9 251.3 285.4 245.3 287 254 259.5 260 257.9 251.3"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="238.7 293.7 270.8 286.2 266.8 279.7 234.6 287.1 238.7 293.7"
            style={{
              fill: "#0ff8e7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="372.9 145.8 375.1 130.3 368.6 131.9 366.5 146.9 372.9 145.8"
            style={{
              fill: "#ffc100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="397.3 195.6 408.8 182.4 405.7 173.3 394.2 187 397.3 195.6"
            style={{
              fill: "#ceff29"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="379.4 146.5 381.6 130.7 375.1 130.3 372.9 145.8 379.4 146.5"
            style={{
              fill: "#ffbd00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="258.9 308.3 290.7 300.5 285.4 298.9 253.5 306.6 258.9 308.3"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="339 171.8 348.8 160 344 167.3 334.4 178.3 339 171.8"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="150.4 62.3 150.6 70 160.6 91.7 159 83.8 150.4 62.3"
            style={{
              fill: "#ff2d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="234.6 287.1 266.8 279.7 263.3 272.3 231.1 279.6 234.6 287.1"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="16.5 90.6 18.4 105.5 28.2 87.6 26.3 72.7 16.5 90.6"
            style={{
              fill: "#ff4e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.7 177.4 189.2 189.7 191.7 216.6 188.1 204.2 185.7 177.4"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="118.5 16.7 113.5 24.4 126.7 35.9 129.8 28.3 118.5 16.7"
            style={{
              fill: "#9b0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="5.9 188.5 9.9 202.9 12.8 179.1 8.8 164.2 5.9 188.5"
            style={{
              fill: "#d4ff23"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="35.3 341.6 36.8 346.4 29.4 326.3 27.6 320.5 35.3 341.6"
            style={{
              fill: "#008cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="381.1 234.9 400.7 224.4 400.6 214.6 380.9 225.6 381.1 234.9"
            style={{
              fill: "#87ff70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="264.3 308.5 296 300.7 290.7 300.5 258.9 308.3 264.3 308.5"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="73 15.5 67 27.2 81.7 25.3 86.2 14.1 73 15.5"
            style={{
              fill: "#8d0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="313.6 195.1 330.4 185.9 327.3 194.4 310.7 203 313.6 195.1"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="350.1 269.6 376 261 378.6 252.8 352.5 261.8 350.1 269.6"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="308.3 295.1 338.4 287.3 342.9 282.5 312.6 290.6 308.3 295.1"
            style={{
              fill: "#0ff8e7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.6 369.1 170.7 377.7 160.3 391.5 160.8 383.3 171.6 369.1"
            style={{
              fill: "#0028ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="190.6 282.7 192 296.5 187.1 319.5 185.6 306.1 190.6 282.7"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="285.8 218.7 308.6 211.6 307.5 220.6 284.7 227.4 285.8 218.7"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="18.8 288.9 21.7 298 17.9 274.8 14.9 264.7 18.8 288.9"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="79.7 405.3 78.4 404.2 66.8 394.1 67.4 394.6 79.7 405.3"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="257.2 242.4 284.6 236.3 285.3 245.3 257.9 251.3 257.2 242.4"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="67.4 394.6 66.8 394.1 56 381.3 56 381 67.4 394.6"
            style={{
              fill: "#0010ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="231.1 279.6 263.3 272.3 260.6 264.2 228.3 271.4 231.1 279.6"
            style={{
              fill: "#30ffc7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="394.2 187 405.7 173.3 401.7 165.2 390.3 179.2 394.2 187"
            style={{
              fill: "#e4ff13"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="140.6 43.7 139.2 51.2 150.6 70 150.4 62.3 140.6 43.7"
            style={{
              fill: "#e80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="129.8 28.3 126.7 35.9 139.2 51.2 140.6 43.7 129.8 28.3"
            style={{
              fill: "#b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="40.4 43.9 37.9 58.2 51.2 47.5 53.1 33.4 40.4 43.9"
            style={{
              fill: "#d10000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="344.3 166.5 354.3 154.1 348.8 160 339 171.8 344.3 166.5"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="92.5 412.8 90.5 411.4 78.4 404.2 79.7 405.3 92.5 412.8"
            style={{
              fill: "#0000f1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169 115.6 173.7 125.2 180.9 150.8 175.4 141.1 169 115.6"
            style={{
              fill: "#ff9f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="269.5 307.3 301.1 299.5 296 300.7 264.3 308.5 269.5 307.3"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="56 381 56 381.3 46.2 366 45.7 364.8 56 381"
            style={{
              fill: "#0038ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="134.1 413.4 131.1 415 118.5 417.7 120.9 416.3 134.1 413.4"
            style={{
              fill: "#0000e3"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="380.9 225.6 400.6 214.6 399.4 204.9 379.7 216.3 380.9 225.6"
            style={{
              fill: "#9dff5a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="228.3 271.4 260.6 264.2 258.6 255.7 226.3 262.7 228.3 271.4"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="390.3 179.2 401.7 165.2 397 158.3 385.7 172.6 390.3 179.2"
            style={{
              fill: "#f4f802"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="312.6 290.6 342.9 282.5 346.8 276.5 316.3 284.8 312.6 290.6"
            style={{
              fill: "#1fffd7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="317.3 188.1 334.4 178.3 330.4 185.9 313.6 195.1 317.3 188.1"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="215.4 312.1 248.3 303.6 243.3 299.2 210.2 307.6 215.4 312.1"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="352.5 261.8 378.6 252.8 380.3 244.1 354.1 253.4 352.5 261.8"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="210.2 307.6 243.3 299.2 238.7 293.7 205.4 301.8 210.2 307.6"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="350 162.7 360.2 149.7 354.3 154.1 344.3 166.6 350 162.7"
            style={{
              fill: "#ffde00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="257.3 233.5 284.7 227.4 284.6 236.3 257.2 242.4 257.3 233.5"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="287.7 210.5 310.7 203 308.6 211.6 285.8 218.7 287.7 210.5"
            style={{
              fill: "#aaff4d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="105.5 417 102.8 415.3 90.5 411.4 92.5 412.8 105.5 417"
            style={{
              fill: "#0000e8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="220.9 315.2 253.5 306.6 248.3 303.6 215.4 312.1 220.9 315.2"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="9.3 227.7 13.1 240.3 13.7 216.3 9.9 202.9 9.3 227.7"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="205.4 301.8 238.7 293.7 234.6 287.1 201.2 294.9 205.4 301.8"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="274.4 304.6 306 296.9 301.1 299.5 269.5 307.3 274.4 304.6"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="191.7 216.6 193.2 231.3 193.2 257.6 191.6 243 191.7 216.6"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="10.7 126.1 14.1 141.5 21.7 121.1 18.4 105.5 10.7 126.1"
            style={{
              fill: "#ff9100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.3 391.5 157.9 396.9 146.5 406.9 148.5 402 160.3 391.5"
            style={{
              fill: "#0008ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="385.7 172.6 397 158.3 391.6 152.8 380.4 167.1 385.7 172.6"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="226.4 316.9 258.9 308.3 253.5 306.6 220.9 315.2 226.4 316.9"
            style={{
              fill: "#0cf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="187.1 319.5 187.6 331.4 180.6 351.6 179.9 340.2 187.1 319.5"
            style={{
              fill: "#00a0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="27.6 320.5 29.4 326.3 23.8 304.9 21.7 298 27.6 320.5"
            style={{
              fill: "#0cf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="356.1 160.4 366.5 146.9 360.2 149.7 350 162.7 356.1 160.4"
            style={{
              fill: "#ffd300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="45.7 364.8 46.2 366 37.8 348.6 36.8 346.4 45.7 364.8"
            style={{
              fill: "#0064ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="201.2 294.9 234.6 287.1 231.1 279.6 197.5 287.2 201.2 294.9"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="226.3 262.7 258.6 255.7 257.5 246.8 225 253.8 226.3 262.7"
            style={{
              fill: "#5aff9d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="86.2 14.1 81.7 25.3 96.9 27.8 99.8 17.1 86.2 14.1"
            style={{
              fill: "maroon"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="379.7 216.3 399.4 204.9 397.3 195.6 377.7 207.4 379.7 216.3"
            style={{
              fill: "#b4ff43"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="380.4 167.1 391.6 152.8 385.6 148.8 374.7 163.1 380.4 167.1"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="321.8 182.1 339 171.8 334.4 178.3 317.3 188.1 321.8 182.1"
            style={{
              fill: "#e7ff0f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="362.4 159.7 372.9 145.8 366.5 146.9 356.1 160.4 362.4 159.7"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="232 317.2 264.3 308.5 258.9 308.3 226.4 317 232 317.2"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="316.3 284.8 346.8 276.5 350.1 269.6 319.3 278.1 316.3 284.8"
            style={{
              fill: "#30ffc7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="374.7 163.1 385.6 148.8 379.4 146.4 368.6 160.6 374.7 163.1"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="368.6 160.6 379.4 146.5 372.9 145.8 362.4 159.7 368.6 160.6"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="354.1 253.4 380.3 244.1 381.1 234.9 354.8 244.5 354.1 253.4"
            style={{
              fill: "#6dff8a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="258.3 225 285.8 218.7 284.7 227.4 257.3 233.6 258.3 225"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="290.5 203 313.6 195.1 310.7 203 287.7 210.5 290.5 203"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="278.9 300.7 310.5 293 306 296.9 274.4 304.6 278.9 300.7"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="197.5 287.2 231.1 279.6 228.3 271.4 194.5 278.6 197.5 287.2"
            style={{
              fill: "#2cffca"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.6 91.7 164.5 101.3 173.7 125.2 169 115.6 160.6 91.7"
            style={{
              fill: "#ff6000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="180.8 150.8 184.2 163.2 189.2 189.7 185.7 177.4 180.8 150.8"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="118.5 417.8 115 416 102.8 415.3 105.5 417 118.5 417.8"
            style={{
              fill: "#0000ed"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="14.9 264.7 17.9 274.8 16.2 251.4 13.1 240.3 14.9 264.7"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="225 253.8 257.5 246.8 257.1 237.9 224.7 244.7 225 253.8"
            style={{
              fill: "#6dff8a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="237.4 315.9 269.5 307.3 264.3 308.5 232 317.2 237.4 315.9"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="26.3 72.7 28.2 87.7 39.8 73.2 37.9 58.2 26.3 72.7"
            style={{
              fill: "#ff1a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="377.7 207.4 397.3 195.6 394.2 187 374.7 199.1 377.7 207.4"
            style={{
              fill: "#caff2c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="78.4 404.2 77.1 400.4 66.2 390.9 66.8 394.1 78.4 404.2"
            style={{
              fill: "#000cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="146.5 406.9 142.9 408.8 131.1 415 134.1 413.4 146.5 406.9"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183.6 321.4 215.4 312.1 210.2 307.6 178.1 316.6 183.6 321.4"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178.1 316.6 210.2 307.6 205.4 301.8 173 310.5 178.1 316.6"
            style={{
              fill: "#00dcfe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="66.8 394.1 66.2 390.9 56.1 378.8 56 381.3 66.8 394.1"
            style={{
              fill: "#0028ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="326.8 177.4 344.3 166.5 339 171.8 321.8 182.1 326.8 177.4"
            style={{
              fill: "#f8f500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="53.1 33.4 51.2 47.5 65.7 40.9 67 27.2 53.1 33.4"
            style={{
              fill: "#a40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="189.3 324.8 220.9 315.2 215.4 312.1 183.6 321.4 189.3 324.8"
            style={{
              fill: "#00c8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="180.6 351.6 179.5 360.8 170.7 377.7 171.6 369.1 180.6 351.6"
            style={{
              fill: "#0064ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="8.8 164.2 12.8 179.1 18 156.9 14.1 141.5 8.8 164.2"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="36.7 346.4 37.8 348.6 30.8 329.6 29.4 326.3 36.7 346.4"
            style={{
              fill: "#0098ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="194.5 278.6 228.3 271.4 226.3 262.7 192.4 269.6 194.5 278.6"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="173 310.5 205.4 301.8 201.2 294.9 168.4 303.2 173 310.5"
            style={{
              fill: "#06ecf1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="90.5 411.4 88.4 407.1 77.1 400.4 78.4 404.2 90.5 411.4"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="319.3 278.1 350 269.6 352.5 261.8 321.6 270.5 319.3 278.1"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="354.8 244.5 381.1 234.9 380.9 225.6 354.6 235.5 354.8 244.5"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="99.8 17.1 96.9 27.8 112.1 34.8 113.5 24.4 99.8 17.1"
            style={{
              fill: "#840000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="282.9 295.4 314.5 287.8 310.5 293 278.9 300.7 282.9 295.4"
            style={{
              fill: "#19ffde"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="193.1 257.6 194.6 271.7 192 296.5 190.6 282.7 193.1 257.6"
            style={{
              fill: "#40ffb7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="195.2 326.6 226.4 317 220.9 315.2 189.3 324.8 195.2 326.6"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="294 196.4 317.3 188.1 313.6 195.1 290.5 203 294 196.4"
            style={{
              fill: "#d4ff23"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="260.1 217 287.7 210.5 285.8 218.7 258.3 225 260.1 217"
            style={{
              fill: "#aaff4d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="56 381.3 56.1 378.8 46.9 364.5 46.2 366 56 381.3"
            style={{
              fill: "#004cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="242.5 313.2 274.4 304.6 269.5 307.3 237.4 315.9 242.5 313.2"
            style={{
              fill: "#00e0fb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168.4 303.2 201.2 294.9 197.5 287.2 164.5 295 168.4 303.2"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="374.7 199.1 394.2 187 390.3 179.2 370.9 191.6 374.7 199.1"
            style={{
              fill: "#deff19"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="150.6 70 153.4 79.7 164.5 101.3 160.6 91.7 150.6 70"
            style={{
              fill: "#ff2900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="332.3 174.1 350 162.7 344.3 166.5 326.8 177.4 332.3 174.1"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="224.7 244.7 257.1 237.9 257.7 229.2 225.2 235.9 224.7 244.7"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="21.7 298 23.8 304.9 20.2 282.8 17.9 274.8 21.7 298"
            style={{
              fill: "#1fffd7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="102.8 415.3 99.9 410.8 88.4 407.1 90.5 411.4 102.8 415.3"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="201.1 326.9 232 317.2 226.4 316.9 195.2 326.6 201.1 326.9"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="192.4 269.6 226.3 262.7 225 253.8 191.1 260.3 192.4 269.6"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="131.1 415 126.9 413.4 115 416 118.5 417.8 131.1 415"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="164.5 295 197.5 287.2 194.5 278.6 161.3 285.9 164.5 295"
            style={{
              fill: "#29ffce"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="370.9 191.6 390.3 179.2 385.7 172.6 366.5 185 370.9 191.6"
            style={{
              fill: "#eeff09"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="113.4 24.4 112.1 34.8 126.8 46 126.7 35.9 113.4 24.4"
            style={{
              fill: "#920000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="170.7 377.7 167.9 383.6 157.9 396.9 160.3 391.5 170.7 377.7"
            style={{
              fill: "#0038ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="338.1 172.2 356.1 160.4 350 162.7 332.3 174.1 338.1 172.2"
            style={{
              fill: "#ffde00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="354.6 235.5 380.9 225.6 379.7 216.3 353.5 226.5 354.6 235.5"
            style={{
              fill: "#9aff5d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="46.2 366 46.9 364.5 39 348.2 37.8 348.6 46.2 366"
            style={{
              fill: "#0074ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="321.6 270.5 352.5 261.8 354.1 253.4 323.1 262.2 321.6 270.5"
            style={{
              fill: "#5aff9d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="154.4 331.6 183.6 321.4 178.1 316.6 148.5 326.4 154.4 331.6"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="148.5 326.4 178.1 316.6 173 310.5 143 319.8 148.5 326.4"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="9.9 202.9 13.7 216.3 16.5 193.2 12.8 179.1 9.9 202.9"
            style={{
              fill: "#d1ff26"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="298.2 190.9 321.8 182.1 317.3 188.1 294 196.4 298.2 190.9"
            style={{
              fill: "#e4ff13"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="139.2 51.2 140.7 61.1 153.4 79.7 150.6 70 139.2 51.2"
            style={{
              fill: "#df0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="286.3 289.1 317.9 281.6 314.5 287.8 282.9 295.4 286.3 289.1"
            style={{
              fill: "#29ffce"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="189.2 189.7 190.7 204.5 193.2 231.3 191.7 216.6 189.2 189.7"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.7 335.2 189.3 324.8 183.6 321.4 154.4 331.6 160.7 335.2"
            style={{
              fill: "#00bcff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="247.1 309.1 278.9 300.7 274.4 304.6 242.5 313.2 247.1 309.1"
            style={{
              fill: "#09f0ee"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="262.6 209.7 290.5 203 287.7 210.5 260.1 217 262.6 209.7"
            style={{
              fill: "#beff39"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="126.7 35.9 126.8 46 140.7 61.1 139.2 51.2 126.7 35.9"
            style={{
              fill: "#b20000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="206.8 325.5 237.4 315.9 232 317.2 201.1 326.9 206.8 325.5"
            style={{
              fill: "#0cf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="143 319.8 173 310.5 168.4 303.2 138.1 311.9 143 319.8"
            style={{
              fill: "#00e0fb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="366.5 185 385.7 172.6 380.4 167.1 361.4 179.7 366.5 185"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="18.4 105.5 21.7 121.1 31.4 103.6 28.2 87.7 18.4 105.5"
            style={{
              fill: "#ff5200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="344.1 171.8 362.4 159.7 356.1 160.4 338.1 172.2 344.1 171.8"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="173.7 125.2 176.8 137.6 184.2 163.2 180.8 150.8 173.7 125.2"
            style={{
              fill: "#ff9f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="29.4 326.3 30.8 329.6 25.5 309.4 23.8 304.9 29.4 326.3"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="77.1 400.4 76.8 394 66.7 385.2 66.2 390.9 77.1 400.4"
            style={{
              fill: "#0028ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="167 337.2 195.2 326.6 189.3 324.8 160.7 335.2 167 337.2"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="225.2 235.9 257.7 229.2 259.1 220.9 226.5 227.5 225.2 235.9"
            style={{
              fill: "#9aff5d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="192 296.5 192.4 308.8 187.6 331.4 187.1 319.5 192 296.5"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="66.2 390.9 66.7 385.2 57.3 374 56.1 378.8 66.2 390.9"
            style={{
              fill: "#0040ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="191.1 260.3 225 253.8 224.7 244.7 190.6 250.9 191.1 260.3"
            style={{
              fill: "#6dff8a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="161.3 285.9 194.5 278.6 192.4 269.6 159 276.3 161.3 285.9"
            style={{
              fill: "#40ffb7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="361.4 179.6 380.4 167.1 374.7 163.1 355.9 175.6 361.4 179.6"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="350.1 173 368.6 160.6 362.4 159.7 344.1 171.8 350.1 173"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="115 416 111.4 411.4 99.9 410.8 102.8 415.3 115 416"
            style={{
              fill: "blue"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="355.9 175.6 374.7 163.1 368.6 160.6 350.1 172.9 355.9 175.6"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="138 311.9 168.4 303.2 164.5 295 133.8 303 138 311.9"
            style={{
              fill: "#0ff8e7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="88.4 407.1 87.4 400.2 76.8 394 77.1 400.4 88.4 407.1"
            style={{
              fill: "#0018ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="67 27.2 65.7 41 81.1 38.8 81.7 25.3 67 27.2"
            style={{
              fill: "#890000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.9 396.9 153.7 399.2 142.9 408.8 146.5 406.9 157.9 396.9"
            style={{
              fill: "#0018ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="353.4 226.5 379.7 216.3 377.7 207.4 351.4 217.8 353.4 226.5"
            style={{
              fill: "#b1ff46"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="303 186.5 326.8 177.4 321.8 182.1 298.2 190.9 303 186.5"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="56.1 378.8 57.3 374 48.8 360.7 46.9 364.5 56.1 378.8"
            style={{
              fill: "#0060ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="323.1 262.2 354.1 253.4 354.8 244.5 323.7 253.5 323.1 262.2"
            style={{
              fill: "#6dff8a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="173.3 337.5 201.1 326.9 195.2 326.6 167 337.2 173.3 337.5"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="13.1 240.3 16.2 251.4 16.7 228.3 13.6 216.3 13.1 240.3"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212.2 322.6 242.5 313.2 237.4 315.9 206.8 325.5 212.2 322.6"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="129.4 342.4 154.4 331.6 148.5 326.4 122.9 336.7 129.4 342.4"
            style={{
              fill: "#00b4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="289 281.8 320.5 274.4 317.9 281.6 286.3 289.1 289 281.8"
            style={{
              fill: "#3cffba"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="251.3 303.6 282.9 295.4 278.9 300.7 247.1 309.1 251.3 303.6"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="122.9 336.7 148.5 326.4 143 319.8 116.9 329.5 122.9 336.7"
            style={{
              fill: "#00c0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="37.7 348.6 39 348.2 32.5 330.4 30.8 329.6 37.7 348.6"
            style={{
              fill: "#00a8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="266 203.3 294 196.4 290.5 203 262.6 209.7 266 203.3"
            style={{
              fill: "#d1ff26"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="38 58.2 39.8 73.2 53 62.6 51.2 47.5 38 58.2"
            style={{
              fill: "#d10000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="136.2 346.3 160.7 335.2 154.4 331.6 129.4 342.4 136.2 346.3"
            style={{
              fill: "#00a8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="133.8 303 164.5 295 161.3 285.9 130.3 293.3 133.8 303"
            style={{
              fill: "#23ffd4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="99.9 410.8 98.1 403.6 87.4 400.2 88.4 407 99.9 410.8"
            style={{
              fill: "#0010ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="116.9 329.5 143 319.8 138 311.9 111.5 321 116.9 329.5"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="159 276.3 192.4 269.6 191.1 260.3 157.5 266.4 159 276.3"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.9 408.8 138.1 407.5 126.9 413.4 131.1 415 142.9 408.8"
            style={{
              fill: "#0008ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="190.6 250.9 224.7 244.7 225.2 235.9 191.1 241.7 190.6 250.9"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="226.5 227.5 259.1 220.9 261.3 213.2 228.7 219.6 226.5 227.5"
            style={{
              fill: "#b1ff46"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="351.4 217.8 377.7 207.4 374.7 199.1 348.6 209.6 351.4 217.8"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="143.1 348.5 167 337.2 160.7 335.2 136.2 346.3 143.1 348.5"
            style={{
              fill: "#00a8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="308.2 183.6 332.3 174.1 326.8 177.4 303 186.5 308.2 183.6"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="187.6 331.4 186.3 341.2 179.5 360.8 180.6 351.6 187.6 331.4"
            style={{
              fill: "#00a8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="179.4 336 206.8 325.5 201.1 326.9 173.3 337.5 179.4 336"
            style={{
              fill: "#00c0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="76.8 394 78.9 385.6 69.6 377.5 66.7 385.2 76.8 394"
            style={{
              fill: "#0040ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="46.9 364.5 48.8 360.7 41.5 345.5 39 348.2 46.9 364.5"
            style={{
              fill: "#08f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="17.9 274.8 20.2 282.8 18.6 260.5 16.2 251.4 17.9 274.8"
            style={{
              fill: "#5aff9d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="66.7 385.2 69.6 377.5 60.9 367.1 57.3 374 66.7 385.2"
            style={{
              fill: "#0058ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="14.1 141.5 18 156.9 25.5 137 21.7 121.1 14.1 141.5"
            style={{
              fill: "#ff9400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="111.5 321 138.1 311.9 133.8 303 106.8 311.3 111.5 321"
            style={{
              fill: "#06ecf1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="109.2 353.6 129.4 342.4 122.9 336.7 102.1 347.4 109.2 353.6"
            style={{
              fill: "#00a0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="217.1 318.3 247.1 309.1 242.5 313.2 212.2 322.7 217.1 318.3"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="102.1 347.4 122.9 336.7 116.9 329.5 95.5 339.5 102.1 347.4"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="193.2 231.3 194.7 245.7 194.6 271.6 193.1 257.6 193.2 231.3"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="87.4 400.2 88.6 391.3 78.9 385.6 76.8 394 87.4 400.2"
            style={{
              fill: "#0034ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="323.7 253.5 354.8 244.5 354.6 235.5 323.5 244.5 323.7 253.5"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="126.9 413.4 122.5 408.9 111.4 411.4 115 416 126.9 413.4"
            style={{
              fill: "#0008ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="130.3 293.3 161.3 285.9 159 276.3 127.8 282.9 130.3 293.3"
            style={{
              fill: "#39ffbe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="164.5 101.3 167.2 113.7 176.8 137.6 173.7 125.2 164.5 101.3"
            style={{
              fill: "#ff6000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="116.6 357.9 136.2 346.3 129.4 342.4 109.2 353.6 116.6 357.9"
            style={{
              fill: "#0094ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="269.9 197.9 298.2 190.9 294 196.4 266 203.3 269.9 197.9"
            style={{
              fill: "#e1ff16"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="254.7 297.1 286.3 289.1 282.9 295.4 251.3 303.7 254.7 297.1"
            style={{
              fill: "#29ffce"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="290.8 273.7 322.4 266.4 320.5 274.4 289 281.8 290.8 273.7"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="57.3 374 60.9 367.1 53.1 354.8 48.8 360.7 57.3 374"
            style={{
              fill: "#0074ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="149.9 348.8 173.3 337.5 167 337.2 143.1 348.5 149.9 348.8"
            style={{
              fill: "#00a8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="348.6 209.6 374.7 199.1 370.9 191.6 345 202.2 348.6 209.6"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="95.5 339.5 116.9 329.5 111.5 321 89.6 330.1 95.5 339.5"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="313.7 182 338.1 172.2 332.3 174.1 308.2 183.6 313.7 182"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="23.8 304.9 25.5 309.4 22.1 288.5 20.2 282.8 23.8 304.9"
            style={{
              fill: "#26ffd1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="111.4 411.4 108.8 404.2 98.1 403.7 99.9 410.8 111.4 411.4"
            style={{
              fill: "#0014ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.5 266.4 191.1 260.3 190.6 250.9 157 256.4 157.5 266.4"
            style={{
              fill: "#6dff8a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="81.7 25.3 81.1 38.8 96.9 41.1 96.9 27.8 81.7 25.3"
            style={{
              fill: "maroon"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="78.9 385.6 84.4 375.7 75.9 368.2 69.6 377.5 78.9 385.6"
            style={{
              fill: "#005cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="94.3 364.9 109.2 353.6 102.1 347.4 86.5 358.1 94.3 364.9"
            style={{
              fill: "#008cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="69.6 377.5 75.9 368.2 67.9 358.8 60.9 367.1 69.6 377.5"
            style={{
              fill: "#0070ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="106.8 311.3 133.8 303 130.3 293.3 103 300.6 106.8 311.3"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="86.5 358.1 102.1 347.4 95.5 339.5 79.2 349.4 86.5 358.1"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="124.2 360.3 143.1 348.5 136.2 346.3 116.6 357.9 124.2 360.3"
            style={{
              fill: "#0094ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="98.1 403.7 98.5 394.5 88.6 391.3 87.4 400.2 98.1 403.7"
            style={{
              fill: "#002cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="184.2 163.2 185.8 178.1 190.7 204.5 189.2 189.7 184.2 163.2"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.2 332.9 212.2 322.7 206.8 325.5 179.4 336 185.2 332.9"
            style={{
              fill: "#0cf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="179.5 360.8 176.3 367.4 167.9 383.6 170.7 377.7 179.5 360.8"
            style={{
              fill: "#0070ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="191.1 241.7 225.2 235.9 226.5 227.5 192.5 232.9 191.1 241.7"
            style={{
              fill: "#9aff5d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="228.7 219.6 261.3 213.2 264.2 206.3 231.7 212.7 228.7 219.6"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="84.4 375.7 94.3 364.8 86.5 358 75.9 368.2 84.4 375.7"
            style={{
              fill: "#0074ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="102.4 369.6 116.6 357.9 109.2 353.6 94.3 364.8 102.4 369.6"
            style={{
              fill: "#0080ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="88.6 391.3 93.3 380.9 84.4 375.7 78.9 385.6 88.6 391.3"
            style={{
              fill: "#004cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="30.8 329.6 32.5 330.4 27.6 311.5 25.5 309.4 30.8 329.6"
            style={{
              fill: "#00e0fb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="345 202.2 370.9 191.6 366.5 185 340.7 195.6 345 202.2"
            style={{
              fill: "#e7ff0f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="75.9 368.2 86.5 358 79.3 349.4 67.9 358.8 75.9 368.2"
            style={{
              fill: "#0084ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="319.4 181.9 344.1 171.8 338.1 172.2 313.7 182 319.4 181.9"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="89.6 330.1 111.5 321 106.8 311.3 84.5 319.5 89.6 330.1"
            style={{
              fill: "#00e0fb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="79.2 349.4 95.5 339.5 89.6 330.1 72.7 339.1 79.2 349.4"
            style={{
              fill: "#00b4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="60.9 367.1 67.9 358.8 60.7 347.5 53.1 354.8 60.9 367.1"
            style={{
              fill: "#008cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="39 348.2 41.5 345.5 35.4 329 32.5 330.4 39 348.2"
            style={{
              fill: "#00b4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="93.3 380.9 102.4 369.6 94.3 364.8 84.4 375.7 93.3 380.9"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="323.5 244.5 354.6 235.5 353.5 226.5 322.4 235.5 323.5 244.5"
            style={{
              fill: "#9aff5d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="48.8 360.7 53.1 354.8 46.3 340.9 41.5 345.5 48.8 360.7"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="221.4 312.5 251.3 303.7 247.1 309.1 217.1 318.3 221.4 312.5"
            style={{
              fill: "#13fce4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="156.6 347.3 179.4 336 173.3 337.5 149.9 348.8 156.6 347.3"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="12.8 179.1 16.5 193.2 21.6 171.8 18 156.9 12.8 179.1"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="127.8 282.9 159 276.3 157.5 266.4 126.2 272.2 127.8 282.9"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="274.4 193.9 303 186.6 298.2 190.9 269.9 198 274.4 193.9"
            style={{
              fill: "#eeff09"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="340.7 195.6 366.5 185 361.4 179.7 335.9 190.2 340.7 195.6"
            style={{
              fill: "#f4f802"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="67.9 358.8 79.2 349.4 72.7 339.1 60.7 347.5 67.9 358.8"
            style={{
              fill: "#00a0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="325.1 183.3 350.1 173 344.1 171.8 319.4 181.9 325.1 183.3"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="110.7 372.3 124.2 360.3 116.6 357.9 102.4 369.6 110.7 372.3"
            style={{
              fill: "#007cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="131.7 360.7 149.9 348.8 143.1 348.5 124.2 360.3 131.7 360.7"
            style={{
              fill: "#0094ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="167.9 383.6 163.3 386.6 153.7 399.2 157.9 396.9 167.9 383.6"
            style={{
              fill: "#04f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="28.2 87.7 31.4 103.6 42.9 89.4 39.8 73.2 28.2 87.7"
            style={{
              fill: "#ff1e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="291.9 265 323.5 257.9 322.4 266.4 290.8 273.7 291.9 265"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="98.5 394.5 102.4 383.8 93.3 380.9 88.6 391.3 98.5 394.5"
            style={{
              fill: "#0048ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="335.9 190.2 361.4 179.7 355.9 175.6 330.6 186.1 335.9 190.2"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="257.5 289.5 289 281.8 286.3 289.1 254.7 297.1 257.5 289.5"
            style={{
              fill: "#3cffba"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="330.6 186.1 355.9 175.6 350.1 173 325.1 183.3 330.6 186.1"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="103 300.6 130.3 293.3 127.8 282.9 100.2 289.4 103 300.6"
            style={{
              fill: "#36ffc1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="102.4 383.8 110.7 372.3 102.4 369.6 93.3 380.9 102.4 383.8"
            style={{
              fill: "#0064ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="72.7 339.1 89.6 330.1 84.5 319.5 67.1 327.4 72.7 339.1"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="153.4 79.7 155.7 92.2 167.2 113.7 164.5 101.3 153.4 79.7"
            style={{
              fill: "#ff2900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157 256.4 190.6 250.9 191.1 241.7 157.5 246.7 157 256.4"
            style={{
              fill: "#87ff70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="108.8 404.2 108.3 395 98.5 394.5 98.1 403.6 108.8 404.2"
            style={{
              fill: "#0030ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="84.5 319.5 106.8 311.3 103 300.6 80.3 307.8 84.5 319.5"
            style={{
              fill: "#13fce4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="194.6 271.6 194.9 284.6 192.4 308.8 192 296.5 194.6 271.6"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="53.1 354.8 60.7 347.5 54.5 334.7 46.3 340.9 53.1 354.8"
            style={{
              fill: "#00acff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="153.7 399.2 148.3 398.5 138.1 407.5 142.9 408.8 153.7 399.2"
            style={{
              fill: "#002cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="51.2 47.5 53 62.6 67.5 56.1 65.7 41 51.2 47.5"
            style={{
              fill: "#a80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="190.5 328.2 217.1 318.3 212.2 322.7 185.2 332.9 190.5 328.2"
            style={{
              fill: "#00dcfe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="96.9 27.8 96.9 41.1 112.6 47.8 112.1 34.8 96.9 27.8"
            style={{
              fill: "maroon"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="122.5 409 119.1 401.9 108.8 404.2 111.4 411.4 122.5 409"
            style={{
              fill: "#0020ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="231.7 212.7 264.2 206.3 267.9 200.5 235.3 206.7 231.7 212.7"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="60.7 347.5 72.7 339.1 67.1 327.4 54.5 334.7 60.7 347.5"
            style={{
              fill: "#00c0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="138.1 407.5 133 403.4 122.5 408.9 126.9 413.4 138.1 407.5"
            style={{
              fill: "#0020ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="192.5 232.9 226.5 227.4 228.7 219.6 194.7 224.8 192.5 232.9"
            style={{
              fill: "#b1ff46"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="322.4 235.5 353.4 226.5 351.4 217.8 320.4 226.8 322.4 235.5"
            style={{
              fill: "#b1ff46"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="13.7 216.3 16.7 228.3 19.5 206.1 16.5 193.2 13.7 216.3"
            style={{
              fill: "#ceff29"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="118.9 372.7 131.7 360.7 124.2 360.3 110.7 372.3 118.9 372.7"
            style={{
              fill: "#007cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="279.3 191.1 308.2 183.6 303 186.5 274.4 193.9 279.3 191.1"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="162.8 343.9 185.2 332.9 179.4 336 156.6 347.3 162.8 343.9"
            style={{
              fill: "#00bcff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="41.5 345.5 46.3 340.9 40.7 325.6 35.4 329 41.5 345.5"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="139 359 156.6 347.3 149.9 348.8 131.7 360.7 139 359"
            style={{
              fill: "#009cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="126.2 272.2 157.5 266.4 157 256.4 125.6 261.5 126.2 272.2"
            style={{
              fill: "#6dff8a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="108.3 395 111.4 384.3 102.4 383.8 98.5 394.5 108.3 395"
            style={{
              fill: "#004cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="225.1 305.5 254.7 297.1 251.3 303.7 221.4 312.5 225.1 305.5"
            style={{
              fill: "#23ffd4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="111.4 384.3 118.9 372.7 110.7 372.3 102.4 383.8 111.4 384.3"
            style={{
              fill: "#0064ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="67.1 327.4 84.5 319.5 80.3 307.8 62.4 314.6 67.1 327.4"
            style={{
              fill: "#09f0ee"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="140.7 61.1 142.5 73.8 155.7 92.2 153.4 79.7 140.7 61.1"
            style={{
              fill: "#df0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="292 256 323.7 249 323.5 257.9 291.9 265 292 256"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="32.5 330.4 35.4 329 30.9 311.4 27.6 311.5 32.5 330.4"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="16.2 251.4 18.6 260.4 19.1 238.5 16.8 228.3 16.2 251.4"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="112.1 34.8 112.6 47.8 128 58.8 126.8 46 112.1 34.8"
            style={{
              fill: "#920000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="259.4 281 290.8 273.7 289 281.8 257.5 289.5 259.4 281"
            style={{
              fill: "#50ffa7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="100.2 289.4 127.8 282.9 126.2 272.2 98.4 277.7 100.2 289.4"
            style={{
              fill: "#50ffa7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="80.3 307.8 103 300.6 100.2 289.4 77.1 295.5 80.3 307.8"
            style={{
              fill: "#30ffc7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="320.4 226.8 351.4 217.8 348.6 209.6 317.7 218.6 320.4 226.8"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="25.5 309.4 27.6 311.5 24.4 291.9 22.1 288.5 25.5 309.4"
            style={{
              fill: "#2cffca"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="46.3 340.9 54.5 334.7 49.4 320.7 40.7 325.6 46.3 340.9"
            style={{
              fill: "#00d4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="20.2 282.8 22.1 288.5 20.6 267.4 18.6 260.5 20.2 282.8"
            style={{
              fill: "#5aff9d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="126.8 46 128 58.8 142.5 73.8 140.7 61.1 126.8 46"
            style={{
              fill: "#b20000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="54.5 334.7 67.1 327.4 62.4 314.6 49.4 320.7 54.5 334.7"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="284.5 189.8 313.7 182 308.2 183.6 279.3 191.1 284.5 189.8"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.5 246.7 191.1 241.7 192.5 232.9 158.9 237.4 157.5 246.7"
            style={{
              fill: "#9dff5a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="235.3 206.7 267.9 200.5 272.1 195.7 239.6 201.9 235.3 206.7"
            style={{
              fill: "#e7ff0f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="195.1 322 221.4 312.5 217.1 318.3 190.5 328.2 195.1 322"
            style={{
              fill: "#09f0ee"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="192.4 308.8 191 319.3 186.3 341.2 187.6 331.4 192.4 308.8"
            style={{
              fill: "#06ecf1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="119.1 401.9 117.8 392.9 108.3 395 108.8 404.2 119.1 401.9"
            style={{
              fill: "#003cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="126.9 370.9 139 359 131.7 360.7 118.9 372.7 126.9 370.9"
            style={{
              fill: "#08f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="190.7 204.5 192.2 219.3 194.7 245.7 193.2 231.3 190.7 204.5"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="176.8 137.6 178.4 152.6 185.8 178.1 184.2 163.2 176.8 137.6"
            style={{
              fill: "#ff9f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="194.7 224.8 228.7 219.6 231.7 212.7 197.7 217.5 194.7 224.8"
            style={{
              fill: "#c7ff30"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="21.7 121.1 25.5 137 35 119.9 31.4 103.6 21.7 121.1"
            style={{
              fill: "#ff5900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168.5 338.8 190.5 328.2 185.2 332.9 162.8 343.9 168.5 338.8"
            style={{
              fill: "#00d0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="317.7 218.6 348.6 209.6 345 202.2 314.3 211.1 317.7 218.6"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="145.8 355.4 162.8 343.9 156.6 347.3 139 359 145.8 355.4"
            style={{
              fill: "#00acff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="289.9 189.9 319.4 181.9 313.7 182 284.6 189.8 289.9 189.9"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="120.1 382.3 126.9 370.9 118.9 372.7 111.4 384.3 120.1 382.3"
            style={{
              fill: "#0070ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="117.8 392.9 120.1 382.3 111.4 384.3 108.3 395 117.8 392.9"
            style={{
              fill: "#0054ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="125.6 261.5 157 256.4 157.5 246.7 126 250.9 125.6 261.5"
            style={{
              fill: "#87ff70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="291.3 246.9 323 240 323.7 249 292 256 291.3 246.9"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="62.4 314.6 80.3 307.8 77.1 295.5 59 301.1 62.4 314.6"
            style={{
              fill: "#26ffd1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="227.9 297.5 257.5 289.5 254.7 297.1 225.1 305.5 227.9 297.5"
            style={{
              fill: "#39ffbe"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="133 403.4 128.8 396.8 119.1 401.9 122.5 408.9 133 403.4"
            style={{
              fill: "#0038ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="35.4 329 40.7 325.6 36.5 309.4 30.9 311.4 35.4 329"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="260.4 272 291.9 265 290.9 273.7 259.4 281.1 260.4 272"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="186.3 341.2 182.9 348.5 176.3 367.4 179.5 360.8 186.3 341.2"
            style={{
              fill: "#00b0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="314.3 211.1 345 202.2 340.7 195.6 310.2 204.4 314.3 211.1"
            style={{
              fill: "#e4ff13"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="98.4 277.7 126.2 272.2 125.6 261.5 97.7 266 98.4 277.7"
            style={{
              fill: "#6aff8d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="295.3 191.5 325.1 183.3 319.4 181.9 289.9 189.9 295.3 191.5"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="77.1 295.5 100.2 289.4 98.4 277.7 75.1 282.7 77.1 295.5"
            style={{
              fill: "#49ffad"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="239.6 201.9 272.1 195.7 276.8 192.3 244.3 198.4 239.6 201.9"
            style={{
              fill: "#f4f802"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="65.7 41 67.5 56.1 82.8 54 81.1 38.8 65.7 41"
            style={{
              fill: "#8d0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="148.3 398.5 142.5 395 133 403.4 138.1 407.6 148.3 398.5"
            style={{
              fill: "#0040ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="49.4 320.7 62.4 314.6 59 301.1 45.6 305.8 49.4 320.7"
            style={{
              fill: "#1fffd7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="40.7 325.6 49.4 320.7 45.6 305.8 36.5 309.4 40.7 325.6"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="310.2 204.4 340.7 195.6 335.9 190.2 305.6 198.9 310.2 204.4"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="158.9 237.4 192.5 232.9 194.7 224.8 161.2 228.7 158.9 237.4"
            style={{
              fill: "#b4ff43"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="300.6 194.5 330.6 186.1 325.1 183.3 295.3 191.5 300.6 194.5"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="176.3 367.4 171.3 371.1 163.3 386.6 167.9 383.6 176.3 367.4"
            style={{
              fill: "#007cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="305.6 198.9 335.9 190.2 330.6 186.1 300.6 194.5 305.6 198.9"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="199 314.5 225.1 305.5 221.4 312.5 195.1 322 199 314.5"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="163.3 386.6 157.3 386.6 148.3 398.5 153.7 399.2 163.3 386.6"
            style={{
              fill: "#0054ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="39.8 73.2 42.9 89.4 56 78.9 53 62.6 39.8 73.2"
            style={{
              fill: "#d60000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="134.4 366.8 145.8 355.3 139 359 126.9 370.9 134.4 366.8"
            style={{
              fill: "#0098ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="197.7 217.5 231.7 212.7 235.3 206.7 201.5 211.3 197.7 217.5"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="18 156.9 21.6 171.8 28.8 152.5 25.5 137 18 156.9"
            style={{
              fill: "#ff9c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="27.6 311.5 30.9 311.4 27.9 293.2 24.4 291.9 27.6 311.5"
            style={{
              fill: "#30ffc7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="289.7 237.9 321.5 231.2 323 240 291.3 246.9 289.7 237.9"
            style={{
              fill: "#aaff4d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="128.8 396.8 126.8 388.1 117.8 392.9 119.1 401.9 128.8 396.8"
            style={{
              fill: "#0050ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="173.6 332.1 195.1 322 190.5 328.2 168.5 338.8 173.6 332.1"
            style={{
              fill: "#00e4f8"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="152.1 349.8 168.5 338.8 162.8 343.9 145.8 355.3 152.1 349.8"
            style={{
              fill: "#00c0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="244.4 198.4 276.8 192.3 281.9 190.3 249.5 196.4 244.4 198.4"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="126 250.9 157.5 246.7 158.9 237.4 127.5 240.9 126 250.9"
            style={{
              fill: "#a0ff56"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="229.9 288.5 259.4 281.1 257.5 289.5 227.9 297.5 229.9 288.5"
            style={{
              fill: "#50ffa7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="128.3 378 134.4 366.8 126.9 370.9 120.1 382.3 128.3 378"
            style={{
              fill: "#0080ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="59 301.1 77.1 295.5 75.1 282.7 56.7 287.1 59 301.1"
            style={{
              fill: "#46ffb1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="194.7 245.7 195 259.2 194.9 284.6 194.6 271.6 194.7 245.7"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="260.5 262.7 292 256 291.9 265 260.4 272 260.5 262.7"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="126.8 388.1 128.3 377.9 120.1 382.3 117.8 392.9 126.8 388.1"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="22.1 288.5 24.4 291.9 23 272.2 20.6 267.4 22.1 288.5"
            style={{
              fill: "#5dff9a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="167.2 113.7 168.8 128.8 178.4 152.6 176.8 137.6 167.2 113.7"
            style={{
              fill: "#ff6000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="16.5 193.2 19.5 206.1 24.4 185.5 21.6 171.8 16.5 193.2"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="97.7 266 125.6 261.5 126 250.9 98.1 254.5 97.7 266"
            style={{
              fill: "#87ff70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="75.1 282.7 98.4 277.7 97.7 266 74.3 269.9 75.1 282.7"
            style={{
              fill: "#6aff8d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="287.3 229.3 319.2 222.7 321.5 231.2 289.7 237.9 287.3 229.3"
            style={{
              fill: "#beff39"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="30.9 311.4 36.5 309.4 33.8 292.6 27.9 293.2 30.9 311.4"
            style={{
              fill: "#36ffc1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="161.2 228.7 194.7 224.8 197.7 217.5 164.3 221 161.2 228.7"
            style={{
              fill: "#caff2c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="201.5 211.3 235.3 206.7 239.6 201.9 205.9 206.3 201.5 211.3"
            style={{
              fill: "#e7ff0f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="18.6 260.4 20.6 267.4 21.1 246.7 19.1 238.5 18.6 260.4"
            style={{
              fill: "#90ff66"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="16.8 228.3 19.1 238.5 21.7 217.4 19.5 206.1 16.8 228.3"
            style={{
              fill: "#caff2c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="45.6 305.8 59 301.1 56.7 287.1 43.1 290.5 45.6 305.8"
            style={{
              fill: "#40ffb7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="249.5 196.4 281.9 190.3 287.2 189.7 254.9 195.8 249.5 196.4"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.5 395 137.6 388.9 128.8 396.8 133 403.4 142.5 395"
            style={{
              fill: "#0054ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="202 305.8 227.9 297.5 225.1 305.5 199 314.5 202 305.8"
            style={{
              fill: "#33ffc4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="36.5 309.4 45.6 305.8 43.1 290.5 33.8 292.7 36.5 309.4"
            style={{
              fill: "#3cffba"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="141.2 360.7 152 349.8 145.8 355.3 134.4 366.8 141.2 360.7"
            style={{
              fill: "#00acff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="81.1 38.8 82.8 54 98.6 56.3 96.8 41.1 81.1 38.8"
            style={{
              fill: "maroon"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.8 178.1 187.3 193.1 192.2 219.3 190.7 204.5 185.8 178.1"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="284.2 221.2 316.1 214.7 319.2 222.7 287.3 229.3 284.2 221.2"
            style={{
              fill: "#d1ff26"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="259.7 253.2 291.3 246.9 292 256 260.5 262.7 259.7 253.2"
            style={{
              fill: "#94ff63"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="177.8 323.9 199 314.5 195.1 322 173.6 332.1 177.8 323.9"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.5 342.4 173.6 332.1 168.5 338.8 152 349.8 157.5 342.4"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="230.9 279 260.4 272 259.4 281.1 229.9 288.6 230.9 279"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="254.9 195.8 287.2 189.7 292.6 190.5 260.3 196.6 254.9 195.8"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="127.5 240.9 158.9 237.4 161.2 228.7 129.9 231.6 127.5 240.9"
            style={{
              fill: "#b7ff40"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="194.9 284.6 193.4 295.8 191 319.3 192.4 308.8 194.9 284.6"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="31.4 103.6 35 119.9 46.3 106 42.9 89.4 31.4 103.6"
            style={{
              fill: "#ff2500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="56.7 287.1 75.1 282.7 74.3 269.9 55.8 273 56.7 287.1"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="135.8 371.3 141.2 360.7 134.4 366.8 128.3 377.9 135.8 371.3"
            style={{
              fill: "#0098ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.3 386.6 150.9 383.8 142.5 395 148.3 398.5 157.3 386.6"
            style={{
              fill: "#0068ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="280.3 214 312.3 207.6 316.1 214.7 284.2 221.2 280.3 214"
            style={{
              fill: "#e1ff16"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="205.9 206.3 239.6 201.9 244.4 198.4 210.8 202.7 205.9 206.3"
            style={{
              fill: "#f8f500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="137.6 388.9 135 380.8 126.8 388.1 128.8 396.8 137.6 388.9"
            style={{
              fill: "#006cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="155.7 92.2 157.3 107.4 168.8 128.8 167.2 113.7 155.7 92.2"
            style={{
              fill: "#ff2900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="24.4 291.9 27.9 293.2 26.6 274.9 23 272.2 24.4 291.9"
            style={{
              fill: "#60ff97"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="260.3 196.7 292.6 190.5 298 192.9 265.7 199 260.3 196.7"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="164.3 221 197.7 217.5 201.5 211.3 168.3 214.4 164.3 221"
            style={{
              fill: "#deff19"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="98.1 254.5 126 250.9 127.5 240.9 99.7 243.5 98.1 254.5"
            style={{
              fill: "#a4ff53"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="135 380.9 135.8 371.3 128.3 378 126.8 388.1 135 380.9"
            style={{
              fill: "#0080ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="74.3 269.9 97.7 266 98.1 254.5 74.7 257.3 74.3 269.9"
            style={{
              fill: "#87ff70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="275.9 207.8 307.9 201.5 312.3 207.6 280.3 214 275.9 207.8"
            style={{
              fill: "#eeff09"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="265.7 199 298 192.8 303.1 196.5 271 202.7 265.7 199"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="53 62.6 56 78.9 70.4 72.6 67.5 56.1 53 62.6"
            style={{
              fill: "#ad0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="271 202.7 303.1 196.5 307.9 201.5 275.9 207.8 271 202.7"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="191 319.3 187.4 327.5 182.9 348.5 186.3 341.2 191 319.3"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="258 243.8 289.7 237.9 291.3 246.9 259.7 253.2 258 243.8"
            style={{
              fill: "#aaff4d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="204.1 296.2 229.9 288.5 227.9 297.5 202 305.8 204.1 296.2"
            style={{
              fill: "#4dffaa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.3 371.1 164.8 371.9 157.3 386.6 163.3 386.6 171.3 371.1"
            style={{
              fill: "#08f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="96.9 41.1 98.6 56.3 114.4 63.1 112.6 47.8 96.9 41.1"
            style={{
              fill: "maroon"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="43.1 290.5 56.7 287.1 55.8 273 42.1 275 43.1 290.5"
            style={{
              fill: "#66ff90"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="182.9 348.5 177.5 353.1 171.3 371.1 176.3 367.4 182.9 348.5"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.2 352.6 157.5 342.4 152 349.8 141.2 360.7 147.2 352.6"
            style={{
              fill: "#00c8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="231 269 260.5 262.7 260.4 272 230.9 279 231 269"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="27.9 293.2 33.8 292.6 32.6 275.8 26.6 274.9 27.9 293.2"
            style={{
              fill: "#60ff97"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="210.8 202.7 244.3 198.4 249.5 196.4 216.1 200.6 210.8 202.7"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.5 73.8 144.1 88.9 157.3 107.4 155.7 92.2 142.5 73.8"
            style={{
              fill: "#df0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="33.8 292.6 43.1 290.5 42.1 275 32.6 275.8 33.8 292.6"
            style={{
              fill: "#63ff94"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="20.6 267.4 23 272.2 23.4 252.8 21.1 246.7 20.6 267.4"
            style={{
              fill: "#90ff66"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="181 314.4 202 305.8 199 314.5 177.8 323.9 181 314.4"
            style={{
              fill: "#2cffca"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="129.9 231.6 161.2 228.7 164.3 221 133.3 223.3 129.9 231.6"
            style={{
              fill: "#d1ff26"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="25.5 137 28.8 152.5 38.1 135.9 35 119.9 25.5 137"
            style={{
              fill: "#ff6000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="162.1 333.4 177.8 323.9 173.6 332.1 157.5 342.4 162.1 333.4"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="112.6 47.8 114.4 63.1 129.7 74 128 58.8 112.6 47.8"
            style={{
              fill: "#920000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168.3 214.4 201.5 211.3 205.9 206.3 172.9 209.1 168.3 214.4"
            style={{
              fill: "#eeff09"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="255.5 234.8 287.3 229.3 289.7 237.9 258 243.8 255.5 234.8"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="150.9 383.8 145.4 378.5 137.6 388.9 142.5 395 150.9 383.8"
            style={{
              fill: "#007cff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="128 58.8 129.7 74 144.1 88.9 142.5 73.8 128 58.8"
            style={{
              fill: "#b20000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="192.2 219.3 192.6 233.4 195 259.2 194.7 245.7 192.2 219.3"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="55.8 273 74.3 269.9 74.7 257.3 56.2 259.1 55.8 273"
            style={{
              fill: "#8aff6d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="216.1 200.6 249.5 196.4 254.9 195.8 221.7 200 216.1 200.6"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="99.7 243.5 127.5 240.9 129.9 231.6 102.3 233.4 99.7 243.5"
            style={{
              fill: "#beff39"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.4 362.4 147.2 352.6 141.2 360.7 135.8 371.3 142.4 362.4"
            style={{
              fill: "#00b4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178.4 152.6 180 168 187.3 193.1 185.8 178 178.4 152.6"
            style={{
              fill: "#ffa300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="19.1 238.5 21.1 246.7 23.6 226.8 21.7 217.4 19.1 238.5"
            style={{
              fill: "#c7ff30"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="74.7 257.3 98.1 254.5 99.7 243.5 76.4 245.2 74.7 257.3"
            style={{
              fill: "#a7ff50"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="21.6 171.8 24.4 185.5 31.4 167 28.8 152.5 21.6 171.8"
            style={{
              fill: "#ffa300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="205.2 285.9 230.9 279 229.9 288.6 204.1 296.2 205.2 285.9"
            style={{
              fill: "#63ff94"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="230.1 259 259.7 253.2 260.5 262.7 231 269 230.1 259"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="19.5 206.1 21.7 217.4 26.4 197.8 24.4 185.5 19.5 206.1"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="252.2 226.5 284.2 221.2 287.3 229.3 255.5 234.8 252.2 226.5"
            style={{
              fill: "#d4ff23"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="145.4 378.5 142.1 371.2 135 380.8 137.6 388.9 145.4 378.5"
            style={{
              fill: "#0090ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.1 371.2 142.3 362.4 135.8 371.3 135 380.9 142.1 371.2"
            style={{
              fill: "#00a0ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="221.7 200 254.9 195.8 260.3 196.7 227.3 200.9 221.7 200"
            style={{
              fill: "#ffde00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="23 272.2 26.6 274.9 27.1 256.9 23.4 252.8 23 272.2"
            style={{
              fill: "#8dff6a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="133.3 223.2 164.3 221 168.3 214.4 137.5 216.1 133.3 223.2"
            style={{
              fill: "#e4ff13"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="172.9 209.1 205.9 206.3 210.8 202.7 178.1 205.3 172.9 209.1"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="42.1 275 55.8 273 56.2 259.1 42.5 259.8 42.1 275"
            style={{
              fill: "#8aff6d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="152.2 342.8 162.1 333.4 157.5 342.4 147.2 352.6 152.2 342.8"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="164.8 371.9 158 370.1 150.9 383.8 157.3 386.6 164.8 371.9"
            style={{
              fill: "#0098ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183.3 303.9 204.1 296.2 202 305.8 181 314.4 183.3 303.9"
            style={{
              fill: "#46ffb1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="248.1 219 280.3 214 284.2 221.2 252.2 226.5 248.1 219"
            style={{
              fill: "#e4ff13"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="67.5 56.1 70.4 72.6 85.5 70.5 82.8 54 67.5 56.1"
            style={{
              fill: "#920000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="42.9 89.4 46.3 106 59.1 95.8 56 78.9 42.9 89.4"
            style={{
              fill: "#df0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="227.3 200.9 260.3 196.7 265.8 199 233 203.3 227.3 200.9"
            style={{
              fill: "#ffde00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="165.7 323 181 314.4 177.8 323.9 162.1 333.4 165.7 323"
            style={{
              fill: "#26ffd1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="195 259.2 193.5 271.2 193.4 295.8 194.9 284.6 195 259.2"
            style={{
              fill: "#83ff73"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="243.5 212.5 275.9 207.8 280.3 214 248.1 219 243.5 212.5"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="233 203.3 265.8 199 271 202.8 238.4 207.2 233 203.3"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="26.6 274.9 32.6 275.8 33.1 259.2 27.1 256.9 26.6 274.9"
            style={{
              fill: "#8dff6a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="32.6 275.8 42.1 275 42.5 259.8 33.1 259.2 32.6 275.8"
            style={{
              fill: "#8dff6a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="228.3 249 258 243.8 259.7 253.2 230.1 259 228.3 249"
            style={{
              fill: "#adff49"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="238.4 207.2 271 202.7 275.9 207.8 243.5 212.5 238.4 207.2"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="102.3 233.4 129.9 231.6 133.3 223.3 105.9 224.3 102.3 233.4"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="205.2 275.1 231 269 230.9 279 205.2 285.9 205.2 275.1"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="56.2 259.1 74.7 257.3 76.4 245.2 58 245.9 56.2 259.1"
            style={{
              fill: "#aaff4d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="177.5 353.1 170.7 355 164.8 371.9 171.3 371.1 177.5 353.1"
            style={{
              fill: "#00c4ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="178.1 205.3 210.8 202.7 216.1 200.6 183.7 203 178.1 205.3"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="76.4 245.2 99.7 243.5 102.3 233.4 79.2 234.1 76.4 245.2"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.8 351.6 152.2 342.8 147.2 352.6 142.3 362.4 147.8 351.6"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="193.4 295.8 189.7 304.9 187.4 327.5 191 319.3 193.4 295.8"
            style={{
              fill: "#46ffb1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="21.1 246.7 23.4 252.8 25.8 234.2 23.6 226.8 21.1 246.7"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168.8 128.8 170.6 144.5 180 168 178.4 152.6 168.8 128.8"
            style={{
              fill: "#ff6400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="137.5 216.1 168.3 214.4 172.9 209.1 142.4 210.4 137.5 216.1"
            style={{
              fill: "#f8f500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="158 370.1 151.9 365.8 145.4 378.5 150.9 383.8 158 370.1"
            style={{
              fill: "#00a8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="187.4 327.5 181.7 333.1 177.5 353.1 182.9 348.5 187.4 327.5"
            style={{
              fill: "#0ff8e7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="225.6 239.5 255.5 234.8 258 243.8 228.3 249 225.6 239.5"
            style={{
              fill: "#c4ff33"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="184.4 292.7 205.2 285.9 204.1 296.2 183.3 303.9 184.4 292.7"
            style={{
              fill: "#63ff94"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="187.3 193.1 187.8 207.8 192.6 233.3 192.2 219.3 187.3 193.1"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="35 119.9 38.1 135.9 49.1 122.5 46.3 106 35 119.9"
            style={{
              fill: "#ff3000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="148.1 359.5 147.8 351.6 142.4 362.4 142.1 371.2 148.1 359.5"
            style={{
              fill: "#00c8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183.7 203 216.1 200.6 221.7 200 189.6 202.4 183.7 203"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="151.9 365.8 148.1 359.5 142.1 371.2 145.4 378.5 151.9 365.8"
            style={{
              fill: "#00b8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="156.1 331.4 165.6 323 162.1 333.4 152.2 342.8 156.1 331.4"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="42.5 259.8 56.2 259.1 58 245.9 44.4 245.3 42.5 259.8"
            style={{
              fill: "#adff49"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="82.8 54 85.5 70.5 101.1 72.8 98.6 56.3 82.8 54"
            style={{
              fill: "#840000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="204.2 264.3 230.1 259 231 269 205.2 275.1 204.2 264.3"
            style={{
              fill: "#97ff60"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168.1 311.5 183.2 303.9 181 314.4 165.6 323 168.1 311.5"
            style={{
              fill: "#43ffb4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="21.7 217.4 23.6 226.8 28 208.3 26.4 197.8 21.7 217.4"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="222 230.6 252.2 226.5 255.5 234.8 225.6 239.5 222 230.6"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="105.9 224.3 133.3 223.2 137.5 216.1 110.4 216.5 105.9 224.3"
            style={{
              fill: "#eeff09"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="23.4 252.8 27.1 256.9 29.2 239.7 25.8 234.2 23.4 252.8"
            style={{
              fill: "#beff39"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.4 210.4 172.9 209.1 178.1 205.3 148 206.3 142.4 210.4"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="189.6 202.4 221.7 200 227.3 200.9 195.6 203.3 189.6 202.4"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="28.8 152.5 31.4 167 40.3 151.1 38.1 135.9 28.8 152.5"
            style={{
              fill: "#ff6c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="58 245.9 76.4 245.2 79.2 234.1 61 233.6 58 245.9"
            style={{
              fill: "#caff2c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="33.1 259.2 42.5 259.8 44.4 245.3 35.1 243.3 33.1 259.2"
            style={{
              fill: "#b4ff43"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="24.4 185.5 26.4 197.8 33.1 180.2 31.4 167 24.4 185.5"
            style={{
              fill: "#ffae00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="79.2 234.1 102.3 233.4 105.9 224.3 83.1 224.1 79.2 234.1"
            style={{
              fill: "#deff19"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.3 107.4 159.2 123.3 170.6 144.5 168.8 128.8 157.3 107.4"
            style={{
              fill: "#ff2d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="217.7 222.6 248.1 219 252.2 226.5 222 230.6 217.7 222.6"
            style={{
              fill: "#ebff0c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="170.7 355 163.4 354.2 158 370.1 164.8 371.9 170.7 355"
            style={{
              fill: "#0cf"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="56 78.9 59.1 95.8 73.2 89.7 70.4 72.6 56 78.9"
            style={{
              fill: "#b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="27.1 256.9 33.1 259.2 35.1 243.3 29.2 239.7 27.1 256.9"
            style={{
              fill: "#b7ff40"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="195.6 203.3 227.3 200.9 233 203.3 201.6 205.9 195.6 203.3"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="184.4 280.9 205.2 275.1 205.2 285.9 184.4 292.7 184.4 280.9"
            style={{
              fill: "#7dff7a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="202.2 253.5 228.3 249 230.1 259 204.2 264.3 202.2 253.5"
            style={{
              fill: "#b1ff46"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="152.1 339.2 156.1 331.4 152.2 342.8 147.8 351.6 152.1 339.2"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="212.8 215.7 243.5 212.5 248.1 219 217.7 222.6 212.8 215.7"
            style={{
              fill: "#f8f500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="201.6 205.9 233 203.3 238.4 207.2 207.3 210.1 201.6 205.9"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="98.6 56.3 101.1 72.9 116.7 79.5 114.4 63.1 98.6 56.3"
            style={{
              fill: "#890000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="207.3 210.1 238.4 207.2 243.5 212.5 212.8 215.7 207.3 210.1"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="192.6 233.4 191.1 246.2 193.5 271.2 195 259.2 192.6 233.4"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="148 206.3 178.1 205.3 183.7 203 154 203.8 148 206.3"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="110.4 216.5 137.5 216.1 142.4 210.4 115.8 210.3 110.4 216.5"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169.3 299.2 184.4 292.7 183.2 303.9 168.1 311.5 169.3 299.2"
            style={{
              fill: "#60ff97"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="158.7 318.8 168.1 311.5 165.6 323 156.1 331.4 158.7 318.8"
            style={{
              fill: "#3cffba"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="144.1 88.9 146.2 105.1 159.2 123.3 157.3 107.4 144.1 88.9"
            style={{
              fill: "#e40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="163.4 354.2 157 351 151.9 365.8 158 370.1 163.4 354.2"
            style={{
              fill: "#00d8ff"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="181.8 333.1 174.7 336.1 170.7 355 177.5 353.1 181.8 333.1"
            style={{
              fill: "#16ffe1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="152.7 345.9 152.1 339.2 147.8 351.6 148.1 359.5 152.7 345.9"
            style={{
              fill: "#0cf4eb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="44.4 245.3 58 245.9 61 233.6 47.7 231.9 44.4 245.3"
            style={{
              fill: "#d1ff26"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="114.4 63.1 116.7 79.5 131.9 90.4 129.7 74 114.4 63.1"
            style={{
              fill: "#9b0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="23.6 226.8 25.8 234.2 29.9 217 28 208.3 23.6 226.8"
            style={{
              fill: "#f4f802"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="199.2 243.2 225.6 239.5 228.3 249 202.2 253.5 199.2 243.2"
            style={{
              fill: "#c7ff30"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="180 168 180.7 183.1 187.8 207.8 187.3 193.1 180 168"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="129.7 74 131.9 90.4 146.2 105.1 144.1 88.9 129.7 74"
            style={{
              fill: "#b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157 351 152.7 345.9 148.1 359.5 151.9 365.8 157 351"
            style={{
              fill: "#02e8f4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="193.5 271.2 189.7 281.3 189.7 304.9 193.4 295.8 193.5 271.2"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="83.1 224.1 105.9 224.3 110.4 216.5 88 215.6 83.1 224.1"
            style={{
              fill: "#f8f500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183.3 269.1 204.2 264.3 205.2 275.1 184.4 280.9 183.3 269.1"
            style={{
              fill: "#9aff5d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="189.7 304.9 183.9 311.6 181.8 333.1 187.4 327.5 189.7 304.9"
            style={{
              fill: "#49ffad"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="154 203.8 183.7 203 189.6 202.4 160.3 203.1 154 203.8"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="61 233.6 79.2 234.1 83.1 224.1 65.3 222.6 61 233.6"
            style={{
              fill: "#e7ff0f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="46.3 106 49.1 122.5 61.6 112.7 59.1 95.8 46.3 106"
            style={{
              fill: "#f10800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="35.1 243.3 44.4 245.3 47.7 231.8 38.7 228.6 35.1 243.3"
            style={{
              fill: "#dbff1c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="195.4 233.6 222 230.6 225.6 239.5 199.2 243.2 195.4 233.6"
            style={{
              fill: "#deff19"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="115.8 210.3 142.4 210.4 148 206.3 121.8 205.8 115.8 210.3"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="25.8 234.2 29.2 239.7 33.1 223.7 29.9 217 25.8 234.2"
            style={{
              fill: "#ebff0c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="70.4 72.6 73.2 89.6 88.1 87.7 85.5 70.5 70.4 72.6"
            style={{
              fill: "#9f0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="155 325.3 158.7 318.8 156.1 331.4 152.1 339.2 155 325.3"
            style={{
              fill: "#36ffc1"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="26.4 197.8 28 208.3 34.3 191.8 33.1 180.2 26.4 197.8"
            style={{
              fill: "#ffb900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.3 203.1 189.6 202.4 195.6 203.3 166.8 204.2 160.3 203.1"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169.3 286.3 184.4 280.9 184.4 292.7 169.3 299.2 169.3 286.3"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="29.2 239.7 35.1 243.3 38.7 228.6 33.1 223.7 29.2 239.7"
            style={{
              fill: "#e1ff16"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="190.7 225 217.7 222.6 222 230.6 195.4 233.6 190.7 225"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.1 305.2 169.3 299.2 168.1 311.5 158.7 318.8 160.1 305.2"
            style={{
              fill: "#5dff9a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="38.1 135.9 40.3 151.1 50.9 138.3 49.1 122.5 38.1 135.9"
            style={{
              fill: "#ff3b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.7 336.1 167.1 336.5 163.4 354.2 170.7 355 174.7 336.1"
            style={{
              fill: "#1cffdb"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="181.1 257.3 202.2 253.5 204.2 264.3 183.3 269.1 181.1 257.3"
            style={{
              fill: "#b7ff40"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="166.8 204.2 195.6 203.3 201.6 205.9 173.2 207 166.8 204.2"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="31.4 167 33.1 180.2 41.6 165.1 40.3 151.1 31.4 167"
            style={{
              fill: "#ff7a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="185.4 217.5 212.8 215.7 217.7 222.6 190.7 225 185.4 217.5"
            style={{
              fill: "#ffea00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="88 215.6 110.4 216.5 115.8 210.3 93.9 208.7 88 215.6"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="173.2 207 201.6 205.9 207.3 210.1 179.5 211.5 173.2 207"
            style={{
              fill: "#ffd000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="47.7 231.9 61 233.6 65.3 222.6 52.3 219.8 47.7 231.9"
            style={{
              fill: "#f4f802"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="179.5 211.5 207.3 210.1 212.8 215.7 185.4 217.5 179.5 211.5"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="121.8 205.8 148 206.3 154 203.8 128.3 203.1 121.8 205.8"
            style={{
              fill: "#ffc800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="170.6 144.5 171.4 160.2 180.7 183.1 180 168 170.6 144.5"
            style={{
              fill: "#ff6c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="187.8 207.8 186.5 221.4 191.1 246.2 192.6 233.4 187.8 207.8"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="155.9 330.8 155 325.3 152.1 339.2 152.7 345.9 155.9 330.8"
            style={{
              fill: "#30ffc7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="65.3 222.6 83.1 224.1 88 215.6 70.7 213.3 65.3 222.6"
            style={{
              fill: "#ffe600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="167.1 336.5 160.4 334.6 157 351 163.4 354.2 167.1 336.5"
            style={{
              fill: "#23ffd4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.4 334.6 155.9 330.8 152.7 345.9 157 351 160.4 334.6"
            style={{
              fill: "#29ffce"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="177.8 246.1 199.2 243.2 202.2 253.5 181.1 257.3 177.8 246.1"
            style={{
              fill: "#d1ff26"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168 273.3 183.3 269.1 184.4 280.9 169.3 286.3 168 273.3"
            style={{
              fill: "#9dff5a"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="28 208.3 29.9 217 35.8 201.5 34.3 191.8 28 208.3"
            style={{
              fill: "#ffc400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183.9 311.6 176.7 315.8 174.7 336.1 181.7 333.1 183.9 311.6"
            style={{
              fill: "#4dffaa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="85.5 70.5 88.1 87.7 103.3 90 101.1 72.8 85.5 70.5"
            style={{
              fill: "#920000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="128.3 203.1 154 203.8 160.3 203.1 135.2 202.3 128.3 203.1"
            style={{
              fill: "#ffc100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="38.7 228.6 47.7 231.9 52.3 219.8 43.7 215.4 38.7 228.6"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="156.4 310.5 160.1 305.2 158.7 318.8 155 325.3 156.4 310.5"
            style={{
              fill: "#5aff9d"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="59.1 95.8 61.6 112.7 75.2 106.7 73.2 89.7 59.1 95.8"
            style={{
              fill: "#c80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="191.1 246.2 187.5 257.2 189.7 281.3 193.5 271.2 191.1 246.2"
            style={{
              fill: "#beff39"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160 291.1 169.3 286.3 169.3 299.2 160.1 305.2 160 291.1"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="93.9 208.7 115.8 210.3 121.8 205.8 100.4 203.8 93.9 208.7"
            style={{
              fill: "#ffc800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="189.7 281.3 184 289.1 183.9 311.6 189.7 304.9 189.7 281.3"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="173.5 235.6 195.4 233.6 199.3 243.2 177.8 246.1 173.5 235.6"
            style={{
              fill: "#e7ff0f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="29.9 217 33.1 223.7 38.6 209.4 35.8 201.5 29.9 217"
            style={{
              fill: "#ffd300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="159.2 123.3 160.2 139.5 171.4 160.2 170.6 144.5 159.2 123.3"
            style={{
              fill: "#ff3400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="33.1 223.7 38.7 228.6 43.7 215.4 38.6 209.4 33.1 223.7"
            style={{
              fill: "#ffde00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="135.2 202.3 160.3 203.1 166.8 204.2 142.3 203.4 135.2 202.3"
            style={{
              fill: "#ffb900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="52.3 219.8 65.3 222.6 70.7 213.3 58.2 209.5 52.3 219.8"
            style={{
              fill: "gold"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="70.7 213.3 88 215.6 93.9 208.7 77 205.7 70.7 213.3"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="33.1 180.2 34.3 191.8 42.3 177.6 41.6 165.1 33.1 180.2"
            style={{
              fill: "#ff8600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="165.5 260.4 181.1 257.3 183.3 269.1 168 273.3 165.5 260.4"
            style={{
              fill: "#baff3c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="101.1 72.9 103.3 90 118.6 96.6 116.7 79.5 101.1 72.9"
            style={{
              fill: "#960000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="168.4 226.2 190.7 225 195.4 233.6 173.5 235.6 168.4 226.2"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.5 314.5 156.4 310.5 155 325.3 155.9 330.8 157.5 314.5"
            style={{
              fill: "#56ffa0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="176.7 315.8 169 317.5 167.1 336.5 174.7 336.1 176.7 315.8"
            style={{
              fill: "#4dffaa"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="49.1 122.5 50.9 138.3 62.9 128.9 61.6 112.7 49.1 122.5"
            style={{
              fill: "#ff1300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="142.3 203.4 166.8 204.2 173.2 207 149.3 206.5 142.3 203.4"
            style={{
              fill: "#ffbd00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="180.7 183.1 179.5 197.5 186.5 221.4 187.8 207.8 180.7 183.1"
            style={{
              fill: "#ffae00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="146.2 105.1 147.5 121.6 160.2 139.5 159.2 123.3 146.2 105.1"
            style={{
              fill: "#f10800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="100.4 203.7 121.8 205.8 128.3 203.1 107.6 200.8 100.4 203.7"
            style={{
              fill: "#ffb900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="162.5 218 185.4 217.5 190.7 225 168.4 226.2 162.5 218"
            style={{
              fill: "#ffdb00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="40.3 151.1 41.6 165.1 51.7 153 50.9 138.3 40.3 151.1"
            style={{
              fill: "#ff4a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="149.3 206.5 173.2 207 179.5 211.5 156.1 211.4 149.3 206.5"
            style={{
              fill: "#ffc100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="156.1 211.4 179.5 211.5 185.4 217.5 162.5 218 156.1 211.4"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="116.7 79.5 118.6 96.6 133.5 107.2 131.9 90.4 116.7 79.5"
            style={{
              fill: "#a40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="158.6 276.8 168 273.3 169.3 286.3 160 291.1 158.6 276.8"
            style={{
              fill: "#a0ff56"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="131.9 90.4 133.5 107.2 147.5 121.6 146.2 105.1 131.9 90.4"
            style={{
              fill: "#c40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="162.1 317 157.5 314.5 155.9 330.8 160.4 334.6 162.1 317"
            style={{
              fill: "#53ffa4"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="156.3 295 160 291.1 160.1 305.2 156.4 310.5 156.3 295"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169 317.5 162.1 317 160.4 334.6 167.1 336.5 169 317.5"
            style={{
              fill: "#50ffa7"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="161.9 248.1 177.8 246.1 181.1 257.3 165.5 260.4 161.9 248.1"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="43.7 215.4 52.3 219.8 58.2 209.5 50.2 204.2 43.7 215.4"
            style={{
              fill: "#ffc800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="73.2 89.7 75.2 106.7 89.6 104.9 88 87.7 73.2 89.7"
            style={{
              fill: "#ad0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="34.3 191.8 35.8 201.5 43.3 188.2 42.3 177.6 34.3 191.8"
            style={{
              fill: "#ff9800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="77.1 205.7 93.9 208.7 100.4 203.8 84.3 200.2 77.1 205.7"
            style={{
              fill: "#ffb600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="184 289.1 176.7 294.6 176.7 315.8 183.9 311.6 184 289.1"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="107.6 200.8 128.3 203.1 135.2 202.3 115.2 199.9 107.6 200.8"
            style={{
              fill: "#ffae00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="58.2 209.5 70.7 213.3 77 205.7 65.2 201.2 58.2 209.5"
            style={{
              fill: "#ffb900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="186.5 221.4 183 233.4 187.5 257.2 191.1 246.2 186.5 221.4"
            style={{
              fill: "#f8f500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="38.6 209.4 43.7 215.4 50.2 204.2 45.5 197.1 38.6 209.4"
            style={{
              fill: "#ffb600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="35.8 201.5 38.6 209.4 45.5 197.1 43.3 188.2 35.8 201.5"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="187.5 257.2 181.8 266.3 184 289.1 189.7 281.3 187.5 257.2"
            style={{
              fill: "#baff3c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.2 236.5 173.5 235.6 177.8 246.1 161.9 248.1 157.2 236.5"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="155.9 262.6 165.5 260.4 168 273.3 158.6 276.8 155.9 262.6"
            style={{
              fill: "#c1ff36"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.4 297.6 156.3 295 156.4 310.5 157.5 314.5 157.4 297.6"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.4 160.2 170.5 175.2 179.5 197.5 180.7 183.2 171.4 160.2"
            style={{
              fill: "#ff7300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="115.2 199.9 135.2 202.3 142.3 203.4 122.9 201.2 115.2 199.9"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="41.6 165.1 42.3 177.6 51.9 166.1 51.7 153 41.6 165.1"
            style={{
              fill: "#ff5d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="154.8 279.2 158.6 276.8 160 291.1 156.3 295 154.8 279.2"
            style={{
              fill: "#a4ff53"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="61.6 112.7 62.9 128.9 76 123.2 75.2 106.7 61.6 112.7"
            style={{
              fill: "#da0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="151.6 226.2 168.4 226.2 173.5 235.6 157.2 236.5 151.6 226.2"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="84.3 200.2 100.4 203.8 107.6 200.8 92.1 197 84.3 200.2"
            style={{
              fill: "#ffa300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="176.7 294.6 169 297.7 169 317.5 176.7 315.8 176.7 294.6"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="88 87.7 89.6 104.8 104.5 107.1 103.3 90 88 87.7"
            style={{
              fill: "#a40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="122.9 201.2 142.3 203.4 149.3 206.5 130.6 204.6 122.9 201.2"
            style={{
              fill: "#ffab00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="50.1 204.1 58.2 209.5 65.2 201.2 57.8 195.1 50.1 204.1"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="162.1 298.6 157.4 297.6 157.5 314.5 162.1 317 162.1 298.6"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="50.9 138.3 51.7 153 63.1 144 62.9 128.9 50.9 138.3"
            style={{
              fill: "#ff2500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="145.1 217.2 162.5 218 168.4 226.2 151.6 226.2 145.1 217.2"
            style={{
              fill: "#fc0"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="130.6 204.6 149.3 206.5 156.1 211.4 138.1 210 130.6 204.6"
            style={{
              fill: "#ffae00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="169 297.7 162.1 298.6 162.1 317 169 317.5 169 297.7"
            style={{
              fill: "#80ff77"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="65.2 201.2 77.1 205.7 84.3 200.2 73.1 195.2 65.2 201.2"
            style={{
              fill: "#ff9f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="138.1 210 156.1 211.4 162.5 218 145.1 217.2 138.1 210"
            style={{
              fill: "#ffbd00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="151.9 249 161.9 248.1 165.5 260.4 155.9 262.6 151.9 249"
            style={{
              fill: "#e1ff16"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.2 139.5 159.7 155.2 170.5 175.2 171.4 160.2 160.2 139.5"
            style={{
              fill: "#ff3f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="42.3 177.6 43.3 188.2 52.2 177.6 51.9 166.1 42.3 177.6"
            style={{
              fill: "#ff6f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="179.5 197.5 176.3 210.5 183 233.4 186.5 221.4 179.5 197.5"
            style={{
              fill: "#ffb600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="92.1 197 107.6 200.8 115.1 199.9 100.4 196 92.1 197"
            style={{
              fill: "#ff9800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="45.5 197.1 50.1 204.1 57.8 195.1 53.8 187.2 45.5 197.1"
            style={{
              fill: "#ff9400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="181.8 266.3 174.7 273 176.7 294.6 184 289.1 181.8 266.3"
            style={{
              fill: "#b7ff40"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="103.3 90 104.5 107.1 119.3 113.5 118.6 96.6 103.3 90"
            style={{
              fill: "#a40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="155.7 280.4 154.8 279.2 156.3 295 157.4 297.6 155.7 280.4"
            style={{
              fill: "#a7ff50"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="151.8 263.7 155.9 262.6 158.6 276.8 154.8 279.2 151.8 263.7"
            style={{
              fill: "#c7ff30"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="43.3 188.2 45.5 197.1 53.8 187.2 52.2 177.6 43.3 188.2"
            style={{
              fill: "#ff8200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="183 233.4 177.5 243.6 181.8 266.2 187.5 257.2 183 233.4"
            style={{
              fill: "#f1fc06"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="146.7 236.3 157.2 236.5 161.9 248.1 151.9 249 146.7 236.3"
            style={{
              fill: "#fbf100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.5 121.6 147.3 137.9 159.7 155.2 160.2 139.5 147.5 121.6"
            style={{
              fill: "#ff1300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="75.2 106.7 76 123.2 89.9 121.5 89.6 104.8 75.2 106.7"
            style={{
              fill: "#c40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="118.6 96.6 119.3 113.5 133.7 123.9 133.5 107.2 118.6 96.6"
            style={{
              fill: "#b60000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="100.4 196 115.1 199.9 122.9 201.2 108.9 197.4 100.4 196"
            style={{
              fill: "#ff9400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="73.1 195.2 84.3 200.2 92.1 197 81.7 191.6 73.1 195.2"
            style={{
              fill: "#ff8d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="57.8 195.1 65.2 201.2 73.1 195.2 66.4 188.5 57.8 195.1"
            style={{
              fill: "#ff8d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="133.5 107.2 133.7 123.9 147.3 137.9 147.5 121.6 133.5 107.2"
            style={{
              fill: "#d10000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="51.7 153 51.9 166.1 62.7 157.7 63.1 144 51.7 153"
            style={{
              fill: "#ff3800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="160.3 279.9 155.7 280.4 157.4 297.6 162.1 298.6 160.3 279.9"
            style={{
              fill: "#adff49"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="174.7 273 167.1 277.5 169 297.7 176.7 294.6 174.7 273"
            style={{
              fill: "#b4ff43"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="140.5 224.9 151.6 226.2 157.2 236.5 146.7 236.3 140.5 224.9"
            style={{
              fill: "#ffd300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="62.9 128.9 63.1 144 75.6 138.7 76 123.2 62.9 128.9"
            style={{
              fill: "#f10800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="108.9 197.4 122.9 201.2 130.6 204.6 117.4 201.1 108.9 197.4"
            style={{
              fill: "#ff9400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="167.1 277.6 160.3 279.9 162.1 298.6 169 297.7 167.1 277.6"
            style={{
              fill: "#b1ff46"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.4 248.7 151.9 249 155.9 262.6 151.8 263.7 147.4 248.7"
            style={{
              fill: "#ebff0c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="170.5 175.2 167.7 189.1 176.3 210.5 179.5 197.5 170.5 175.2"
            style={{
              fill: "#ff7e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="133.4 215.1 145.1 217.2 151.6 226.2 140.5 224.9 133.4 215.1"
            style={{
              fill: "#ffb900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="117.4 201.1 130.6 204.6 138.1 210 125.6 207.1 117.4 201.1"
            style={{
              fill: "#ff9c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="152.4 263.4 151.8 263.7 154.8 279.2 155.7 280.4 152.4 263.4"
            style={{
              fill: "#ceff29"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="125.6 207.1 138.1 210 145.1 217.2 133.4 215.1 125.6 207.1"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="53.8 187.2 57.8 195.1 66.4 188.5 63.2 180 53.8 187.2"
            style={{
              fill: "#f70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="81.7 191.6 92.1 197 100.4 196 90.8 190.5 81.7 191.6"
            style={{
              fill: "#ff8200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="51.9 166.1 52.2 177.6 62.3 169.8 62.7 157.7 51.9 166.1"
            style={{
              fill: "#ff4a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="89.6 104.8 89.9 121.5 104.2 123.7 104.5 107.1 89.6 104.8"
            style={{
              fill: "#b60000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="177.5 243.6 170.6 251.7 174.7 273 181.8 266.3 177.5 243.6"
            style={{
              fill: "#ebff0c"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="52.2 177.6 53.8 187.2 63.2 180 62.3 169.8 52.2 177.6"
            style={{
              fill: "#ff6000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="66.4 188.5 73.1 195.2 81.7 191.6 75.8 184.5 66.4 188.5"
            style={{
              fill: "#f70"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="176.3 210.5 171.1 221.8 177.5 243.6 183 233.4 176.3 210.5"
            style={{
              fill: "#ffc100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="141.7 234.8 146.7 236.3 151.9 249 147.4 248.7 141.7 234.8"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="156.7 261.4 152.4 263.4 155.7 280.4 160.3 279.9 156.7 261.4"
            style={{
              fill: "#d7ff1f"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="159.7 155.2 157.2 169.9 167.7 189.1 170.5 175.2 159.7 155.2"
            style={{
              fill: "#ff4a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="90.8 190.5 100.4 196 108.9 197.4 100.1 192.1 90.8 190.5"
            style={{
              fill: "#ff7a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="63.1 144 62.7 157.7 74.4 152.7 75.6 138.7 63.1 144"
            style={{
              fill: "#ff1a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="76 123.2 75.6 138.7 88.8 137 89.9 121.5 76 123.2"
            style={{
              fill: "#da0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.6 247 147.4 248.7 151.8 263.7 152.4 263.4 147.6 247"
            style={{
              fill: "#f4f802"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="170.6 251.7 163.2 257.6 167 277.6 174.7 273 170.6 251.7"
            style={{
              fill: "#e4ff13"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="104.5 107.1 104.2 123.7 118.4 129.8 119.3 113.5 104.5 107.1"
            style={{
              fill: "#b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="134.8 222.3 140.5 224.9 146.7 236.3 141.7 234.8 134.8 222.3"
            style={{
              fill: "#ffc100"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="163.2 257.6 156.7 261.4 160.3 279.9 167 277.6 163.2 257.6"
            style={{
              fill: "#deff19"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="63.2 180 66.4 188.5 75.8 184.5 73.4 175.7 63.2 180"
            style={{
              fill: "#ff6000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="100.1 192.1 108.9 197.4 117.4 201.1 109.4 196.1 100.1 192.1"
            style={{
              fill: "#ff7e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="75.8 184.5 81.7 191.6 90.8 190.5 85.7 183.4 75.8 184.5"
            style={{
              fill: "#ff6c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.3 137.9 145.3 153.3 157.2 169.9 159.7 155.2 147.3 137.9"
            style={{
              fill: "#ff1e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="127 211.4 133.4 215.1 140.5 224.9 134.8 222.3 127 211.4"
            style={{
              fill: "#ffa700"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="119.3 113.5 118.4 129.8 132.3 139.8 133.7 123.9 119.3 113.5"
            style={{
              fill: "#c80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="109.4 196.1 117.4 201.1 125.6 207.1 118.5 202.6 109.4 196.1"
            style={{
              fill: "#ff8600"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="62.7 157.7 62.3 169.8 73.3 165.1 74.4 152.7 62.7 157.7"
            style={{
              fill: "#ff3000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="167.7 189.1 162.9 201.5 171.1 221.8 176.3 210.5 167.7 189.1"
            style={{
              fill: "#ff8900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="118.5 202.6 125.6 207.1 133.4 215.1 127 211.4 118.5 202.6"
            style={{
              fill: "#ff9400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="133.7 123.9 132.3 139.8 145.3 153.3 147.3 137.9 133.7 123.9"
            style={{
              fill: "#e40000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="171.1 221.8 164.5 231.1 170.6 251.7 177.5 243.6 171.1 221.8"
            style={{
              fill: "#ffc800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="62.3 169.8 63.2 180 73.4 175.7 73.3 165.1 62.3 169.8"
            style={{
              fill: "#ff4a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="141.4 231.8 141.7 234.8 147.4 248.7 147.6 247 141.4 231.8"
            style={{
              fill: "#ffd300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="151.4 243.7 147.6 247 152.4 263.4 156.7 261.4 151.4 243.7"
            style={{
              fill: "#feed00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="89.9 121.5 88.8 137 102.4 139.2 104.2 123.7 89.9 121.5"
            style={{
              fill: "#cd0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="85.7 183.4 90.8 190.5 100.1 192.1 95.9 185 85.7 183.4"
            style={{
              fill: "#ff6400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="75.6 138.7 74.4 152.7 86.9 151.2 88.8 137 75.6 138.7"
            style={{
              fill: "#f10800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="164.5 231.1 157.6 238.4 163.2 257.6 170.6 251.7 164.5 231.1"
            style={{
              fill: "#ffd300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="73.4 175.7 75.8 184.5 85.7 183.4 84.2 174.4 73.4 175.7"
            style={{
              fill: "#ff5200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.6 238.4 151.4 243.7 156.7 261.5 163.2 257.6 157.6 238.4"
            style={{
              fill: "#ffe200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="133.9 218.1 134.8 222.3 141.7 234.8 141.4 231.8 133.9 218.1"
            style={{
              fill: "#ffb200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="157.2 169.9 152.9 183.1 162.9 201.5 167.7 189.1 157.2 169.9"
            style={{
              fill: "#ff5900"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="95.9 185 100.1 192.1 109.4 196.1 106.1 189.5 95.9 185"
            style={{
              fill: "#ff6400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="74.4 152.7 73.3 165.1 85 163.7 86.9 151.2 74.4 152.7"
            style={{
              fill: "#f20"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="144.7 227.1 141.4 231.8 147.6 247 151.4 243.7 144.7 227.1"
            style={{
              fill: "#ffc400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="73.3 165.1 73.4 175.7 84.2 174.4 85 163.7 73.3 165.1"
            style={{
              fill: "#ff3800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="162.9 201.5 156.7 212 164.5 231.1 171.1 221.8 162.9 201.5"
            style={{
              fill: "#ff9400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="104.2 123.7 102.4 139.2 116 145 118.4 129.8 104.2 123.7"
            style={{
              fill: "#d10000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="125.4 206.2 127 211.4 134.8 222.3 133.9 218.1 125.4 206.2"
            style={{
              fill: "#ff9400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="106.1 189.5 109.4 196.1 118.5 202.6 116 196.6 106.1 189.5"
            style={{
              fill: "#ff6c00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="116 196.6 118.5 202.6 127 211.4 125.4 206.2 116 196.6"
            style={{
              fill: "#ff7e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="145.3 153.3 141.6 167.3 152.9 183.1 157.2 169.9 145.3 153.3"
            style={{
              fill: "#ff3000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="84.2 174.4 85.7 183.4 95.9 185 95.3 176.2 84.2 174.4"
            style={{
              fill: "#ff4a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="88.8 137 86.9 151.2 99.7 153.2 102.4 139.2 88.8 137"
            style={{
              fill: "#e80000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="118.4 129.8 116 145.1 129.2 154.5 132.3 139.8 118.4 129.8"
            style={{
              fill: "#df0000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="150.3 220.5 144.7 227.1 151.5 243.7 157.6 238.4 150.3 220.5"
            style={{
              fill: "#ffb200"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="132.3 139.8 129.2 154.5 141.6 167.3 145.3 153.2 132.3 139.8"
            style={{
              fill: "#fa0f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="156.8 212 150.3 220.5 157.6 238.4 164.6 231.1 156.8 212"
            style={{
              fill: "#ffa300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="136.5 212.1 133.9 218.1 141.4 231.8 144.7 227.1 136.5 212.1"
            style={{
              fill: "#ff9f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="152.9 183.1 147.4 194.7 156.7 212 162.9 201.5 152.9 183.1"
            style={{
              fill: "#ff6800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="85 163.7 84.2 174.4 95.3 176.2 97 165.6 85 163.7"
            style={{
              fill: "#ff3000"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="95.3 176.2 95.9 185 106.1 189.5 106.4 181.1 95.3 176.2"
            style={{
              fill: "#ff4a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="86.9 151.2 85 163.7 97 165.6 99.7 153.2 86.9 151.2"
            style={{
              fill: "#ff1a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="127.3 199.3 125.4 206.2 133.9 218.1 136.6 212.1 127.3 199.3"
            style={{
              fill: "#ff7e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="102.4 139.2 99.7 153.2 112.5 158.8 116 145 102.4 139.2"
            style={{
              fill: "#ed0400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="106.4 181.1 106.1 189.5 116 196.6 117.1 188.8 106.4 181.1"
            style={{
              fill: "#f50"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="141.5 204.4 136.5 212.1 144.7 227.1 150.3 220.5 141.5 204.4"
            style={{
              fill: "#ff8d00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="117.1 188.8 116 196.6 125.4 206.2 127.3 199.3 117.1 188.8"
            style={{
              fill: "#ff6800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="147.4 194.7 141.5 204.4 150.3 220.5 156.8 212 147.4 194.7"
            style={{
              fill: "#ff7a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="141.6 167.3 136.7 179.8 147.4 194.7 152.9 183.1 141.6 167.3"
            style={{
              fill: "#ff4300"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="97 165.6 95.3 176.2 106.4 181.1 108.9 170.8 97 165.6"
            style={{
              fill: "#ff3400"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="116 145 112.5 158.8 125 167.7 129.2 154.5 116 145"
            style={{
              fill: "#fa0f00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="99.7 153.2 97 165.6 108.9 170.8 112.5 158.8 99.7 153.2"
            style={{
              fill: "#ff1a00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="129.2 154.5 125 167.7 136.7 179.8 141.6 167.3 129.2 154.5"
            style={{
              fill: "#f20"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="131.5 190.5 127.3 199.3 136.5 212.1 141.5 204.4 131.5 190.5"
            style={{
              fill: "#ff6800"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="136.7 179.8 131.5 190.5 141.5 204.4 147.4 194.7 136.7 179.8"
            style={{
              fill: "#f50"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="108.9 170.8 106.4 181.1 117.1 188.8 120.5 179.2 108.9 170.8"
            style={{
              fill: "#ff3b00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="120.5 179.2 117.1 188.8 127.3 199.3 131.5 190.5 120.5 179.2"
            style={{
              fill: "#ff4e00"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="112.5 158.8 108.9 170.8 120.5 179.2 125 167.7 112.5 158.8"
            style={{
              fill: "#ff2500"
            }}
          />
        </g>
        <g
          style={{
            clipPath: "url(#clip-path)"
          }}
        >
          <polygon
            points="124.9 167.7 120.5 179.2 131.5 190.5 136.7 179.8 124.9 167.7"
            style={{
              fill: "#ff3800"
            }}
          />
        </g>
      </g>
    </g>
  </Icon>
);

export default CurveLP;
