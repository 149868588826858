import ChevronDownLine from "./ChevronDownLine";
import ChevronUpLine from "./ChevronUpLine";
import MoneyStack from "./MoneyStack";
import EthTokenIcon from "./EthTokenIcon";
import Bank from "./Bank";
import RedThermometer from "./RedThermometer";
import YellowThermometer from "./YellowThermometer";
import GreenThermometer from "./GreenThermometer";
import AddIcon from "./AddIcon";
import FilledAddIcon from "./FilledAddIcon";
import MinusIcon from "./MinusIcon";
import FilledMinusIcon from "./FilledMinusIcon";
import TooltipPrompt from "./TooltipPrompt";
import Expand from "./Expand";
import Success from "./Success";
import Failure from "./Failure";
import MetaMaskIcon from "./MetaMaskIcon";
import BlueAddIcon from "./BlueAddIcon";
import AVAX from "./AVAX";
import JOE from "./JOE";
import USDC from "./USDC";
import WAVAX from "./WAVAX";
import WETH from "./WETH";
import DANGER from "./DANGER";
import BC from "./BC";
import qiUSDC from "./qiUSDC";
import YETI from "./YETI";
import YUSD from "./YUSD";
import JLP from "./JLP";
import CLP from "./CurveLP";

const library = {
  MoneyStack,
  ChevronDownLine,
  ChevronUpLine,
  EthTokenIcon,
  RedThermometer,
  YellowThermometer,
  GreenThermometer,
  Bank,
  AddIcon,
  FilledAddIcon,
  MinusIcon,
  FilledMinusIcon,
  BlueAddIcon,
  TooltipPrompt,
  Expand,
  Success,
  Failure,
  MetaMaskIcon,
  AVAX,
  JOE,
  USDC,
  WAVAX,
  WETH,
  DANGER,
  BC,
  qiUSDC,
  YETI,
  YUSD,
  JLP,
  CLP
};

export default library;
