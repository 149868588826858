import React, { useState, useEffect } from "react";

import CoinAmount from "../../../Components/CoinAmount";
import Checkbox from "../../../Components/Checkbox";
import ProgressBar from "../../../Components/ProgressBar";
import { useTransactionFunction, useMyTransactionState } from "../../../Components/Transaction";
import { useLiquity } from "../../../Hooks/LiquityContext";
import tokenData from "../../../TokenData";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton
} from "@chakra-ui/modal";
import {
  Flex,
  Text,
  Button,
  Spacer,
  VStack,
  HStack,
  Divider,
  useDisclosure
} from "@chakra-ui/react";
import { useLiquitySelector } from "@liquity/lib-react";
import { LiquityStoreState } from "@liquity/lib-base";
import { format, limitDecimals, getNum, formatWithDecimals } from "../../../Utils/number";
import TransactionModal from "../../../Components/TransactionModal";

export type ConfirmCloseTroveModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const selector = ({ trove, price, yusdBalance, tokenBalances }: LiquityStoreState) => ({
  trove,
  price,
  yusdBalance,
  tokenBalances
});

const ConfirmCloseTroveModal: React.FC<ConfirmCloseTroveModalProps> = ({ isOpen, onClose }) => {
  const [step, setStep] = useState<0 | 1>(0);
  const { liquity } = useLiquity();
  const { trove, price, yusdBalance, tokenBalances } = useLiquitySelector(selector);

  const { isOpen: isTxModalOpen, onOpen: onTxModalOpen, onClose: onTxModalClosed } = useDisclosure();

  tokenData.map(
    token =>
      (token["troveBalance"] = formatWithDecimals(trove.collaterals[token.address], token.decimals))
  );
  tokenData.map(
    token =>
      (token["walletBalance"] = formatWithDecimals(
        tokenBalances[token.underlying == undefined ? token.address : token.underlying!],
        token.decimals
      ))
  );
  tokenData.map(token => (token["safetyRatio"] = format(token["safetyRatio"])));

  const collaterals = Object.keys(trove.collaterals);
  const collateral = tokenData.filter(collateral => collaterals.includes(collateral.address));

  const totalCollateral = tokenData
    .map(token => token.troveBalance * format(price[token.address]))
    .reduce((a, b) => a + b, 0);
  const totalDebt = limitDecimals(+trove.debt["debt"].toString());
  const yusdAvailable = limitDecimals(+yusdBalance.toString());

  const [understand, setUnderstand] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [understandError, setUnderstandError] = useState(false);
  const [confirmError, setConfirmError] = useState(false);

  useEffect(() => {
    if (understandError && understand) {
      setUnderstandError(false);
    }
  }, [understandError, understand]);

  useEffect(() => {
    if (confirmError && confirm) {
      setConfirmError(false);
    }
  }, [confirmError, confirm]);

  // const onApprove = () => {
  //   if (!understand) {
  //     setUnderstandError(true);
  //   }
  //   if (!confirm) {
  //     setConfirmError(true);
  //   }
  //   if (confirm && understand) {
  //     setStep(1);
  //   }
  // };

  // Close Trove
  const [close] = useTransactionFunction("close-trove", liquity.send.closeTrove.bind(liquity.send));

  const transactionId = "close-trove";
  const myTransactionState = useMyTransactionState(transactionId);

  const onSubmit = (): void => {
    if (!understand) {
      setUnderstandError(true);
    }
    if (!confirm) {
      setConfirmError(true);
    }
    if (confirm && understand) {
      close();
      onClose();
    }
  };
  const confirm1String =
    "I understand this will repay my YUSD debt and return all collateral back to my wallet. Deposit fees will have to be paid to reopen a trove.";

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="2xl" pb={1}>
          Close Trove
          <ModalCloseButton />
        </ModalHeader>

        <ModalBody>
          <Divider />

          <Flex my={5} align="center">
            <Text color="green.400" textStyle="title4">
              Collateral you Receive:
            </Text>
            <Spacer />
            <Text color="green.400" fontWeight="bold" fontSize="lg">
              ${getNum(totalCollateral, 2)} USD
            </Text>
          </Flex>
          <VStack align="flex-start" spacing={3}>
            {collateral.map(({ token, troveBalance }) => {
              return (
                <CoinAmount icon token={token} amount={troveBalance} key={token} fontWeight="bold" />
              );
            })}
          </VStack>
          <Flex mt={5} align="center">
            <Text color="red.500" textStyle="title4">
              Debt you Repay:
            </Text>
            <Spacer />

            <Text color="red.500" fontWeight="bold" fontSize="lg">
              ${getNum(totalDebt - 200, 2)} YUSD
              {/* - 200 here for the YUSD Gas compensation that will be paid back on close trove. */}
            </Text>
          </Flex>
          {totalDebt > yusdAvailable ? (
            <Flex justify="flex-end">
              <Text color="brand.300" fontWeight="light" fontSize="xs">
                ${getNum(yusdAvailable)} YUSD Available
              </Text>
            </Flex>
          ) : (
            <>
              <Flex justify="flex-end" mb={5}>
                <Text color="brand.300" fontWeight="light" fontSize="xs">
                  ${getNum(yusdAvailable)} YUSD Available
                </Text>
              </Flex>
              <VStack align="flex-start" spacing={5}>
                <Checkbox
                  isChecked={understand}
                  onChange={() => setUnderstand(!understand)}
                  error={understandError}
                  label={confirm1String}
                />
                <Checkbox
                  isChecked={confirm}
                  onChange={() => setConfirm(!confirm)}
                  error={confirmError}
                  label="I confirm that I want to liquify and close my trove."
                />
              </VStack>
            </>
          )}
        </ModalBody>
        <ModalFooter flexDirection="column">
          <HStack spacing={6}>
            {totalDebt > yusdAvailable ? (
              <Button variant="noYUSD">
                Additional {getNum(totalDebt - yusdAvailable - 200, 2)} YUSD required to close trove
                {/* - 200 here for the YUSD Gas compensation that will be paid back on close trove. */}
              </Button>
            ) : (
              <Button variant="primary" onClick={onSubmit}>
                Close Trove
              </Button>
            )}
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmCloseTroveModal;
