import React, { useState, useEffect } from "react";

import ProgressBar from "../../../Components/ProgressBar";
import CoinAmount from "../../../Components/CoinAmount";
import { useTransactionFunction, useMyTransactionState } from "../../../Components/Transaction";
import { capitalizeFirstLetter } from "../../../Utils/string";
import { Decimal } from "@liquity/lib-base";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { format } from "../../../Utils/number";

import { useLiquity } from "../../../Hooks/LiquityContext";

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton
} from "@chakra-ui/modal";
import { Flex, Text, Button, Spacer, HStack, useDisclosure } from "@chakra-ui/react";
import { SiEventstore } from "react-icons/si";
import Tooltip from "../../../Components/Tooltip";
import TransactionModal from "../../../Components/TransactionModal";

export type ConfirmStakeModalProps = {
  isOpen: boolean;
  onClose: () => void;
  mode: "deposit" | "withdraw";
  amount: string;
  total: number;
};
const selector = ({ yetiBalance, farmYETI }: LiquityStoreState) => ({
  yetiBalance,
  farmYETI
});

const ConfirmStakeModal: React.FC<ConfirmStakeModalProps> = ({
  isOpen,
  onClose,
  mode,
  amount,
  total
}) => {
  const { yetiBalance, farmYETI } = useLiquitySelector(selector);
  const [step, setStep] = useState<0 | 1 | 2>(0);
  const action = capitalizeFirstLetter(mode) == "Deposit" ? "Stake" : "Unstake";

  const { liquity, account } = useLiquity();
  var { userAddress } = liquity.connection;
  // console.log("111", userAddress);
  if (userAddress === undefined) {
    userAddress = "";
  }
  const { isOpen: isTxModalOpen, onOpen: onTxModalOpen, onClose: onTxModalClosed } = useDisclosure();

  const getFormattedValue = (value: string): number => {
    try {
      Decimal.from(value);
      return +value;
    } catch (err) {
      return 0;
    }
  };
  useEffect(() => {
    // let tot:Decimal = Decimal.ZERO
    // if (!(getFormattedValue(amount) == 0)) {
    //   tot = Decimal.from(amount)
    // }
    const open = isOpen;
    let interval: any = undefined;
    if (open) {
      interval = setInterval(async () => {
        const allowance = await checkAllowance(
          "0x261DA9C7E7932cCBC7d556836cf7128f543312b9",
          Decimal.from(getFormattedValue(amount))
        );
        if (allowance) {
          setStep(2);
        } else {
          setStep(1);
        }
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [amount, isOpen]);

  let newAmount;
  // console.log("amount", amount)
  const formatedAmount = getFormattedValue(amount);
  if (mode === "deposit" && formatedAmount === format(yetiBalance)) {
    newAmount = yetiBalance;
  } else {
    if (formatedAmount === format(farmYETI.yetiTokenBalance)) {
      newAmount = farmYETI.yetiTokenBalance;
    } else if (!isNaN(formatedAmount)) {
      newAmount = Decimal.from(formatedAmount);
    } else {
      newAmount = Decimal.from(0);
    }
  }
  // console.log("newAmount", newAmount.toString())
  const [sendTransaction] = useTransactionFunction(
    "stakeYETITokens",
    mode === "deposit"
      ? liquity.send.stakeYETITokens.bind(liquity.send, newAmount)
      : liquity.send.withdrawYETITokens.bind(liquity.send, newAmount)
  );
  const transactionId = "stakeYETITokens";
  const myTransactionState = useMyTransactionState(transactionId);

  const checkAllowance = async (token: string, amount: Decimal): Promise<boolean> => {
    const result = await liquity.getAllowanceOf(
      account,
      token,
      "0xbc1aB96830A4d875bf592c91537640C4412eE03F",
      amount
    );

    return result;
  };

  /*
  Fixme:
  amount should be a number like depositYUSDInStabilityPool
  **/
  const [approveTransaction] = useTransactionFunction(
    "approve",
    liquity.send.approveToken.bind(
      liquity.send,
      "0x261DA9C7E7932cCBC7d556836cf7128f543312b9",
      "0xbc1aB96830A4d875bf592c91537640C4412eE03F",
      Decimal.from("1000000000000000")
    )
  );

  const onApprove = () => {
    approveTransaction();
  };

  const onDeposit = () => {
    if (step === 2 || mode == "withdraw") {
      // console.log(mode);
      sendTransaction();
      onClose();
    }
  };

  let confirmStakeButtons = (
    <ModalFooter flexDirection="column">
      <HStack spacing={6}>
        <Button variant={step !== 1 ? "quaternary" : "primary"} onClick={onApprove}>
          Approve
        </Button>

        <Button variant={step !== 2 ? "quaternary" : "primary"} onClick={onDeposit}>
          {action}
        </Button>
      </HStack>
      <ProgressBar step={step === 2 ? 1 : 0} w="30%" mt={2} />
    </ModalFooter>
  );

  if (action == "Unstake") {
    confirmStakeButtons = (
      <ModalFooter flexDirection="column">
        <HStack spacing={6}>
          <Button variant={"primary"} onClick={onDeposit}>
            {action}
          </Button>
        </HStack>
      </ModalFooter>
    );
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize="2xl" pb={1}>
            Confirm {action}
            <ModalCloseButton />
          </ModalHeader>

          <ModalBody>
            <Flex>
              <Text fontSize="lg">{action} Amount:</Text>
              <Spacer />
              <CoinAmount
                amount={formatedAmount}
                token="YETI"
                fontWeight="bold"
                color="white"
                fontSize="md"
              />
            </Flex>
            <Flex mt={3}>
              <Text color="brand.200">New Total Staked YETI</Text>
              <Spacer />
              <CoinAmount amount={total} token="YETI" fontWeight="bold" />
            </Flex>
          </ModalBody>
          {confirmStakeButtons}
        </ModalContent>
      </Modal>
      <TransactionModal
        status={myTransactionState.type}
        isOpen={isTxModalOpen}
        onClose={onTxModalClosed}
      />
    </>
  );
};

export default ConfirmStakeModal;
