import React, { useReducer } from "react";
import { Text, TextProps } from "@chakra-ui/react";
import tokenData from "../../TokenData";
import { useLiquitySelector } from "@liquity/lib-react";
import { LiquityStoreState } from "@liquity/lib-base";
import { format, getNum } from "../../Utils/number";
import { connectionReducer } from "../WalletConnector";

type CurrencyConverterProps = {
  token: string;
  value: number;
  currency: string;
} & TextProps;

const selector = ({ price }: LiquityStoreState) => ({
  price
});

var dataSelector = useLiquitySelector;

const CurrencyConverterLabel: React.FC<CurrencyConverterProps> = ({
  token,
  value,
  currency,
  ...props
}) => {
  const [connectionState, dispatch] = useReducer(connectionReducer, { type: "inactive" });
  let converter = 1;

  if (token !== "YUSD" && token !== "YETI" && token !== "veYETI") {
    const collateral = tokenData.filter(collateral => collateral.token === token)[0];
    const { price } = dataSelector(selector);
    converter = format(price[collateral.address]);
  }

  if (currency === "USD") {
    return (
      <Text as="span" textStyle="body3" color="brand.300" whiteSpace="nowrap" {...props}>
        {` ≈ $${getNum(value * converter, 2)}`}
      </Text>
    );
  } else if (currency === "VC") {
    return (
      <Text as="span" textStyle="body3" color="brand.300" whiteSpace="nowrap" {...props}>
        {` ≈ ${getNum(value * converter, 2)}`} RAV
      </Text>
    );
  } else {
    return (
      <Text as="span" textStyle="body3" color="brand.300" whiteSpace="nowrap" {...props}>
        {` ≈ ${getNum(value * converter, 2)}`} YUSD
      </Text>
    );
  }
};

export default CurrencyConverterLabel;
