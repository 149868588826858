import { TroveMappings, Collateral } from "../../../Types";
import { adjustValue, format, formatFeeUncapped } from "../../../Utils/number";

type ChangedCollateral = {
  address: string;
  token: string;
  mode: string;
  total: number;
  change: number;
  fee: number | undefined;
  feePercentage: number;
  safetyRatio: number;
  recoveryRatio: number;
  yusdFromLever: number;
  changeWithoutLever: number;
  leverage: number;
  decimals: number;
};

type UnchangedCollateral = {
  address: string;
  token: string;
  total: number;
  safetyRatio: number;
  recoveryRatio: number;
};

export const sumArrVc = (
  arr: ChangedCollateral[],
  term: "change" | "total",
  price: TroveMappings
) => {
  return arr
    .map(
      collateral =>
        collateral[term] * format(price[collateral.address]) * format(collateral.safetyRatio)
    )
    .reduce((a, b) => a + b, 0);
};

export const sumUnchangedVc = (arr: UnchangedCollateral[], price: TroveMappings) => {
  return arr
    .map(
      collateral =>
        collateral.total * format(price[collateral.address]) * format(collateral.safetyRatio)
    )
    .reduce((a, b) => a + b, 0);
};

export const sumArrAVc = (
  arr: ChangedCollateral[],
  term: "change" | "total",
  price: TroveMappings
) => {
  return arr
    .map(
      collateral =>
        collateral[term] * format(price[collateral.address]) * format(collateral.recoveryRatio)
    )
    .reduce((a, b) => a + b, 0);
};

export const sumUnchangedAVc = (arr: UnchangedCollateral[], price: TroveMappings) => {
  return arr
    .map(
      collateral =>
        collateral.total * format(price[collateral.address]) * format(collateral.recoveryRatio)
    )
    .reduce((a, b) => a + b, 0);
};

export const getChangedCollateral = (
  collateral: Collateral[],
  values: { [key: string]: any },
  fees: TroveMappings
): ChangedCollateral[] => {
  const changedCollateral = collateral.filter(
    ({ token }) => values[token] && parseFloat(values[token]) !== 0
  );

  const changedCollateralData = changedCollateral.map(
    ({ address, token, troveBalance, safetyRatio, recoveryRatio, decimals }) => {
      const changeWithoutLever = values[token];
      const mode = values[token + "mode"];

      let fee = undefined;

      const yusdFromLever = Number(
        values[token + "ExpectedYUSD"] ? values[token + "ExpectedYUSD"] : "0"
      );
      const changeFromLever = Number(
        values[token + "PostLeverage"] ? values[token + "PostLeverage"] : "0"
      );
      const leverage = Number(values[token + "leverage"] ? values[token + "leverage"] : "0");

      const change = leverage != 0 ? changeWithoutLever * leverage : changeWithoutLever;
      if (mode === "deposit") {
        fee = formatFeeUncapped(change * formatFeeUncapped(fees[address]));
      }

      return {
        address: address,
        token: token,
        mode: mode,
        total: adjustValue(mode, troveBalance, change),
        change: change,
        fee: fee,
        feePercentage: formatFeeUncapped(fees[address]),
        safetyRatio: safetyRatio,
        recoveryRatio: recoveryRatio,
        yusdFromLever: yusdFromLever,
        changeWithoutLever: changeWithoutLever,
        leverage: leverage,
        decimals: decimals
      };
    }
  );

  return changedCollateralData;
};

export const calculateTotalYUSDFromLever = (changedCollateral: ChangedCollateral[]) => {
  let totalYUSDFromLever = 0;
  changedCollateral.map(collateral => {
    const { yusdFromLever } = collateral;
    if (yusdFromLever) {
      totalYUSDFromLever = totalYUSDFromLever + yusdFromLever;
    }
  });
  return totalYUSDFromLever;
};

export const getUnchangedCollateral = (
  whitelistedCollateral: Collateral[],
  values: { [key: string]: any }
): UnchangedCollateral[] => {
  const unchangedCollateral = whitelistedCollateral.filter(
    collateral =>
      collateral.troveBalance > 0 && (!values[collateral.token] || values[collateral.token] == 0)
  );

  const unchangedCollateralData = unchangedCollateral.map(
    ({ address, token, troveBalance, safetyRatio, recoveryRatio }) => ({
      address: address,
      token: token,
      total: troveBalance,
      safetyRatio: safetyRatio,
      recoveryRatio: recoveryRatio
    })
  );

  return unchangedCollateralData;
};
