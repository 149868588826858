import React, { useState, useEffect } from "react";
import { Box, BoxProps, Text, Tr, Td, Button, Flex, useDisclosure } from "@chakra-ui/react";
import { Icon, TokenTable, AdjustInput, Toggle } from "../../../Components";
import AddCollateralTypeModal from "../AddCollateralTypeModal";
import LeverUpModal from "../LeverUpModal";
import { CoinMode, CoinShow, Collateral, TokenData } from "../../../Types";
import { format, adjustValue, getNum } from "../../../Utils/number";
import { getVCChanges } from "../../../Utils/validation";
import { TroveMappings, Decimal } from "@liquity/lib-base";
import { useLiquity } from "../../../Hooks/LiquityContext";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { dec, getTroveValueWithLever } from "../AdjustTrove/AdjustTrove.utils";
import Tooltip from "../../../Components/Tooltip";

type AdjustCollateralProps = {
  values: { [key: string]: any };
  borrowMode: "normal" | "lever" | "unlever";
  leverSave: "saved" | "unsaved";
  setLeverSave: React.Dispatch<React.SetStateAction<"saved" | "unsaved">>;
  collateral: TokenData[];
  form: any;
} & BoxProps;

const selector = ({ trove, price, tokenBalances }: LiquityStoreState) => ({
  trove,
  price,
  tokenBalances
});

const AdjustCollateral: React.FC<AdjustCollateralProps> = ({
  values,
  collateral,
  form,
  borrowMode,
  setLeverSave,
  leverSave,
  ...props
}) => {
  const { liquity } = useLiquity();
  const { trove, price, tokenBalances } = useLiquitySelector(selector);
  const {
    isOpen: isAddCollateralTypeOpen,
    onOpen: onAddCollateralTypeOpen,
    onClose: onAddCollateralTypeClose
  } = useDisclosure();
  const { isOpen: isLeverUpOpen, onOpen: onLeverUpOpen, onClose: onLeverUpClose } = useDisclosure();

  const [leveredToken, setLeveredToken] = useState<Collateral>({} as Collateral);

  // Coin Display Config State

  const coins: CoinMode = {};
  //collateral.forEach(coin => {borrowMode !== "unlever" ? coins[coin.token] = "deposit" : coins[coin.token] = "withdraw"});
  collateral.forEach(coin => (coins[coin.token] = "deposit"));
  const coinShow: CoinShow = {};
  collateral.forEach(coin => {
    if (
      coin.troveBalance === 0 ||
      coin.token === "BC" ||
      coin.token == "VAULT_OLD"
    ) {
      coinShow[coin.token] = false;
    } else {
      coinShow[coin.token] = true;
    }
  });
  const [mode, setMode] = useState<CoinMode>(coins);
  const [show, setShow] = useState<CoinShow>(coinShow);
  let availableCollateral = collateral.filter(coin => !show[coin.token]);
  const currentCollateral = collateral.filter(coin => show[coin.token]);
  //console.log("adjsutcollateral.tsx currentCollateral", currentCollateral)
  // Fee Setup

  const fees: TroveMappings = {};
  collateral.map(token => (fees[token.address] = Decimal.ZERO));
  const [depositFees, setFees] = useState<TroveMappings>(fees);
  let vcInputsForLeverage: TroveMappings = {};
  const getFees = () => {
    let tempVCInputs: TroveMappings = {};
    let tempVCOutputs: TroveMappings = {};

    if (Object.keys(values).length !== 0) {
      for (let i = 0; i < collateral.length; i++) {
        const token = collateral[i].token;
        const address = collateral[i].address;
        const decimals = collateral[i].decimals;
        if (values[token] != null) {
          if (values[token] !== 0) {
            if (mode[token] === "deposit") {
              tempVCInputs[address] = Decimal.fromWithPrecision(
                isNaN(values[token]) || values[token] < 0 ? 0 : values[token],
                decimals
              );
            } else {
              tempVCOutputs[address] = Decimal.fromWithPrecision(
                isNaN(values[token]) || values[token] < 0 ? 0 : values[token],
                decimals
              );
              // To show the fee even for assets which have no adjust
              tempVCInputs[address] = Decimal.fromWithPrecision(1, decimals);
            }
          }
        } else {
          // To show the fee even for assets which have no adjust
          tempVCInputs[address] = Decimal.fromWithPrecision(1, decimals);
        }
      }
      vcInputsForLeverage = tempVCInputs;
      liquity.getDepositFee(tempVCInputs, tempVCOutputs).then(fees => {
        setFees(fees);
      });
    }
  };

  const openLeverUp = (token: Collateral) => {
    setLeveredToken(token);
    onLeverUpOpen();
    setLeverSave("unsaved");
  };

  const checker = (values: { [key: string]: any }) => {
    Object.keys(values).map(key => {
      if (!key.includes("mode")) {
        const value = values[key];
        try {
          Decimal.from(values[key]);
          values[key] = value;
        } catch (err) {
          delete values[key];
        }
      }
    });
    return values;
  };

  const showLeverModal = (item: Collateral) => {
    return (
      <Td pt={8} pb={2} pl={2}>
        {borrowMode === "normal" ? (
          <Button variant="orange" isDisabled>
            Lever Up
          </Button>
        ) : borrowMode === "lever" &&
          item.walletBalance === 0 &&
          isNaN(values[item.token + "leverage"]) ? (
          <Button variant="orange" onClick={() => openLeverUp(item)} isDisabled>
            Lever Up
          </Button>
        ) : borrowMode === "lever" &&
          item.walletBalance !== 0 &&
          isNaN(values[item.token + "slippage"]) ? (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            Lever Up
          </Button>
        ) : borrowMode === "unlever" &&
          item.troveBalance !== 0 &&
          isNaN(values[item.token + "slippage"]) ? (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            Deleverage
          </Button>
        ) : !isNaN(values[item.token + "leverage"]) && values[item.token + "leverage"] != 1 ? (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            {values[item.token + "leverage"]}x Leverage
          </Button>
        ) : borrowMode === "lever" && !isNaN(values[item.token + "slippage"]) ? (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            No Leverage
          </Button>
        ) : (
          <Button variant="orange" onClick={() => openLeverUp(item)}>
            Deleveraged
          </Button>
        )}
      </Td>
    );
  };

  useEffect(() => {
    availableCollateral = collateral.filter(coin => !show[coin.token]);
    getFees();
  }, [values, collateral]);

  useEffect(() => {
    for (var i = 0; i < collateral.length; i++) {
      const coin = collateral[i];
      if (borrowMode === "unlever") {
        coins[coin.token] = "withdraw";
      } else if (borrowMode === "lever") {
        coins[coin.token] = "deposit";
      }
    }
    setMode(coins);
  }, [collateral, borrowMode]);
  const newFormat = (x: Decimal | number) => {
    if (x) {
      return Math.min(parseFloat(x.toString()), 0.01);
    }
    return 0;
  };

  return (
    <>
      <AddCollateralTypeModal
        isOpen={isAddCollateralTypeOpen}
        onClose={onAddCollateralTypeClose}
        show={show}
        setShow={setShow}
        availableCollateral={availableCollateral}
        borrowMode={borrowMode}
      />
      <LeverUpModal
        isOpen={isLeverUpOpen}
        onClose={onLeverUpClose}
        collateral={leveredToken}
        type={borrowMode}
        values={values}
        setLeverSave={setLeverSave}
        vcInput={vcInputsForLeverage}
        depositFees={depositFees}
      />
      <Box {...props}>
        <Text textStyle="title4" color="white" px={6}>
          {trove.status === "open" ? "Adjust" : "Add"} Collateral
        </Text>
        {Object.values(show).some(_ => _) ? (
          <TokenTable
            headers={
              borrowMode !== "normal"
                ? [
                    "Token",
                    "Safety Ratio",
                    "Deposit Fee",
                    "APR",
                    "Actions",
                    "Adjusted Trove Amount",
                    "Lever Up"
                  ]
                : ["Token", "Safety Ratio", "Deposit Fee", "APR", "Actions", "Adjusted Trove Amount"]
            }
            tooltips={
              borrowMode !== "normal"
                ? [
                    "Name",
                    "Weighting for risk adjusted value",
                    "This is the simulated fee using demand driven fee curves. Fee capped at 1%.",
                    "Estimated APR",
                    "Deposit to add collateral to your trove. Withdraw to subtract.",
                    "Final amount of the collateral after adjustments",
                    "Lever Up"
                  ]
                : [
                    "Name",
                    "Weighting for risk adjusted value",
                    "This is the simulated fee using demand driven fee curves. Fee capped at 1%.",
                    "Estimated APR",
                    "Deposit to add collateral to your trove. Minus to subtract.",
                    "Final amount of the collateral after adjustments"
                  ]
            }
            width={borrowMode !== "normal" ? 7 : 6}
            borrow
          >
            <>
              {currentCollateral
                .filter(token => {
                  if (borrowMode === "unlever") {
                    if (token.troveBalance === 0) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                  return true;
                })
                .map(item => (
                  <Tr key={item.token}>
                    <Td pt={8} pb={2}>
                      <Flex align="center" w={28}>
                        <Icon iconName={item.token} h={5} w={5} />
                        <Text ml={3} whiteSpace="pre-wrap">
                          {item.token}
                        </Text>
                      </Flex>
                    </Td>
                    <Td pt={8} pb={2} pl={2}>
                      {item.safetyRatio.toFixed(2)}
                    </Td>
                    <Td pt={8} pb={2} pl={2}>
                      <Text whiteSpace="nowrap">
                        {(newFormat(depositFees[item.address]) * 100).toFixed(3)}%{" "}
                      </Text>
                    </Td>

                    <Td pt={8} pb={2} pl={2}>
                      <Text whiteSpace="nowrap">{item.apr !== undefined ? item.apr.toFixed(3): "N/A"}%</Text>
                    </Td>
                    <Td pt={2} pb={2} pl={2}>
                      <Flex direction="column">
                        <Text textStyle="body2" fontWeight="bold" mb={1}>
                          Balance: {getNum(item.walletBalance)}
                        </Text>
                        <AdjustInput
                          name={item.token}
                          iconStatus={mode}
                          setIconStatus={setMode}
                          token={item.token}
                          id="testId"
                          max={
                            mode[item.token] === "deposit"
                              ? tokenBalances[item.underlying == undefined ? item.address : item.underlying].toString()
                              : trove.collaterals[item.address]
                                ? trove.collaterals[item.address].toString()
                                : (0).toString()
                          }
                          min={0}
                          inputWidth={12}
                          size="sm"
                          showIcons
                          values={checker(values)}
                          borrowMode={borrowMode}
                        />
                      </Flex>
                    </Td>
                    <Td pt={8} pb={2} pl={2}>
                      {getNum(getTroveValueWithLever(mode[item.token], item, values, borrowMode))}
                    </Td>
                    {borrowMode !== "normal" ? showLeverModal(item) : <></>}
                  </Tr>
                ))}
            </>
            <Tr key="total-usd">
              <Td pt={4} pb={0} />
              <Td pt={4} pb={0} />
              <Td pt={4} pb={0} />
              <Td pt={4} pb={0} />
              <Td pt={4} pb={0}>
                <Text textStyle="subtitle2" textAlign="right">
                  Trove Collateral Value:
                </Text>
              </Td>
              <Td pt={4} pb={0} pl={2}>
                $
                {getNum(
                  currentCollateral
                    .map(
                      item =>
                        getTroveValueWithLever(mode[item.token], item, values, borrowMode) *
                        format(price[item.address])
                    )
                    .reduce((a, b) => a + b, 0)
                )}
              </Td>
            </Tr>
          </TokenTable>
        ) : (
          <Text textStyle="body1" px={6} my={4}>
            Add some collateral to start creating your trove!
          </Text>
        )}
        <Button
          colorScheme="brand"
          variant="primary"
          _active={{ bg: "transparent" }}
          mt={2}
          mx={6}
          leftIcon={<Icon iconName="BlueAddIcon" />}
          onClick={onAddCollateralTypeOpen}
        >
          Add Collateral Type
        </Button>
        <Button
          colorScheme="brand"
          variant="primary"
          _active={{ bg: "transparent" }}
          mt={2}
          onClick={form.reset}
        >
          Clear All
        </Button>
      </Box>
    </>
  );
};

export default AdjustCollateral;
