import React from "react";
import { Flex, Box, Text, Spacer } from "@chakra-ui/react";
import StatColumn from "../StatColumn";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { format, getNum } from "../../../Utils/number";

const selector = ({ veYETIStaked, farmYETI }: LiquityStoreState) => ({
  veYETIStaked,
  farmYETI
});

const StakeSummary: React.FC = () => {
  const { veYETIStaked, farmYETI } = useLiquitySelector(selector);

  const veBalance = format(veYETIStaked.veYETIGain);
  const staked = format(veYETIStaked.yetiStake.add(farmYETI.yetiTokenBalance));

  return (
    <Box layerStyle="base" flex={1}>
      <Text textStyle="title3" textAlign={["center", "left"]} pb={6}>
        Stake Summary
      </Text>
      <Flex>
        <StatColumn
          iconName="YETI"
          amount={getNum(staked, 2)}
          units="YETI"
          description="Staked in veYETI"
        />
        <Spacer />
        <StatColumn
          iconName="YETI"
          amount={getNum(veBalance, 2)}
          units="veYETI"
          description="Accrued"
        />
      </Flex>
    </Box>
  );
};

export default StakeSummary;
