type InfoRow = {
  title: string;
  value?: number;
  percent?: number;
  tooltip?: string;
};

export const getBalanceInfo = (
  staked: number,
  stakeShare: number,
  weeklyRewards: number,
  veYeti?: number
): InfoRow[] => [
  {
    title: "Total Amount Staked",
    value: staked
  },
  {
    title: "Hourly veYETI Accumulation",
    value: weeklyRewards, // TODO: hardcoded to 0 but will have to change on Mainnet
    tooltip: "Amount of veYETI gained in a hour"
  },
  {
    title: "Current veYETI",
    value: veYeti != undefined ? veYeti : weeklyRewards, // TODO: hardcoded to 0 but will have to change on Mainnet
    tooltip: "Amount of veYETI gained"
  }
];
