import { createIcon } from "@chakra-ui/react";

const YETI = createIcon({
  displayName: "YETI",
  viewBox: "0 0 565 565",
  path: (
    <g>
      <path
        d="M282.7 554.3C432.811 554.3 554.5 432.611 554.5 282.5C554.5 132.389 432.811 10.7002 282.7 10.7002C132.589 10.7002 10.8999 132.389 10.8999 282.5C10.8999 432.611 132.589 554.3 282.7 554.3Z"
        fill="url(#paint0_linear_1478_3391)"
        stroke="url(#paint1_linear_1478_3391)"
        stroke-width="21"
        stroke-miterlimit="10"
      />
      <path
        d="M157 183.3C156.3 181.3 179.6 168.4 208.6 160.2C236.6 152.3 284.2 144.7 348.8 162.3C319.961 160.811 291.073 164.089 263.3 172C258.2 171.7 251.1 171.3 242.5 171.4C192.2 171.7 157.8 185.6 157 183.3Z"
        fill="white"
      />
      <path
        d="M285.4 174.7C294.8 172.381 304.312 170.545 313.9 169.2C332.057 166.728 350.42 166.125 368.7 167.4C377.432 169.216 385.68 172.863 392.9 178.1C415.3 194.6 418.7 221 419.6 228.1C422.9 253.6 412.8 273.2 408.8 280.3C409.377 274.043 409.31 267.743 408.6 261.5C407.9 255.2 404.2 222.4 376.4 200.8C365.888 192.77 353.617 187.354 340.6 185C322.2 181.6 303.8 178.167 285.4 174.7Z"
        fill="white"
      />
      <path
        d="M382.2 317.2C391.5 321.8 418.8 337 431.2 368.2C434.2 375.8 440.7 392.9 435.7 413.4C434 420.4 430.6 433.1 418.5 443C411.019 449.179 401.859 452.975 392.2 453.9C385 454.5 376.5 453.4 353 440.9C338.632 433.284 324.8 424.698 311.6 415.2C319.853 417.597 328.406 418.81 337 418.8C346.2 418.8 357.1 418.8 368.3 412.9C369.9 412 386.7 402.8 392.3 384.3C395.3 374.5 392.8 362.7 387.8 339.5C385.7 330.2 383.7 322.6 382.2 317.2Z"
        fill="white"
      />
      <path
        d="M302 418.8C311.059 426.03 320.616 432.613 330.6 438.5C342.747 445.523 355.498 451.447 368.7 456.2C361.7 456.6 351.9 456.9 340.2 456.8C318.8 456.6 281.6 456.2 240.1 444.6C205.321 434.844 172.547 418.999 143.3 397.8C169.018 411.058 196.944 419.497 225.7 422.7C251.176 425.576 276.95 424.258 302 418.8V418.8Z"
        fill="white"
      />
      <path
        d="M282.6 85.8999C284.2 90.4999 286.7 96.7999 288 103.6C288.9 108.1 293.2 125 294.4 129.3C296.8 136.8 297.9 139.5 299.1 144.6C300.579 144.937 302.085 145.137 303.6 145.2C313.965 146.069 324.217 147.979 334.2 150.9C329.305 147.596 324.808 143.737 320.8 139.4C314.9 133.3 315.8 134.7 310.9 128.7C305.12 121.714 299.84 114.329 295.1 106.6C290.6 99.5999 287.5 94.5999 282.6 85.8999Z"
        fill="white"
      />
      <path
        d="M279.6 92C271.244 102.692 262.297 112.908 252.8 122.6C244.308 131.332 235.363 139.61 226 147.4C250.346 136.541 269.551 116.691 279.6 92V92Z"
        fill="white"
      />
      <path
        d="M179.2 275.1C182.127 275.1 184.5 271.16 184.5 266.3C184.5 261.44 182.127 257.5 179.2 257.5C176.273 257.5 173.9 261.44 173.9 266.3C173.9 271.16 176.273 275.1 179.2 275.1Z"
        fill="white"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M290.5 275.1C293.427 275.1 295.8 271.16 295.8 266.3C295.8 261.44 293.427 257.5 290.5 257.5C287.573 257.5 285.2 261.44 285.2 266.3C285.2 271.16 287.573 275.1 290.5 275.1Z"
        fill="white"
        stroke="white"
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M144 190.3C138.823 198.18 134.814 206.77 132.1 215.8C127.962 229.418 127.275 243.85 130.1 257.8C130.409 264.101 130.275 270.417 129.7 276.7C128.188 293.115 123.964 309.166 117.2 324.2C113.783 332.07 112.275 340.636 112.8 349.2C114.3 370 128.3 384.3 134.8 390.2C132.8 386.1 130.7 381.9 128.7 377.7C124.654 371.116 122.031 363.758 121 356.1C119.788 345.549 121.701 334.874 126.5 325.4C133.712 311.143 138.093 295.623 139.4 279.7C140.172 269.054 139.465 258.352 137.3 247.9C136.274 242.358 135.738 236.736 135.7 231.1C135.675 217.078 138.498 203.197 144 190.3V190.3Z"
        fill="white"
      />
      <path
        d="M380.3 214.8C385.972 222.285 390.927 230.287 395.1 238.7C395.1 238.7 403.4 257.6 403.2 270.9C403.2 272.3 403.9 281.9 404.1 288.3C404.4 296 404.4 296.6 404.6 298.8C404.879 300.995 405.313 303.167 405.9 305.3C407.42 310.968 409.744 316.39 412.8 321.4C417.5 329.6 419.9 336.1 427 342.1C422.9 338.6 422 338.2 416.3 333.8C413.2 331 408.3 327.4 396.7 317.4C389.6 311.2 386.2 307.7 383.6 302.6C380.481 296.306 379.035 289.315 379.4 282.3C379.315 279.103 379.652 275.909 380.4 272.8C381.8 267.1 383.8 265 385 259C385.489 256.427 385.757 253.818 385.8 251.2C386.3 237.9 381.1 217.8 380.3 214.8Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1478_3391"
          x1="101.339"
          y1="79.9749"
          x2="463.811"
          y2="485.11"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.03" stop-color="#3385A6" />
          <stop offset="1" stop-color="#006EB8" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1478_3391"
          x1="44.4576"
          y1="130.81"
          x2="520.651"
          y2="434.292"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#91D5FD" />
          <stop offset="0.13" stop-color="#8AD0FA" />
          <stop offset="0.32" stop-color="#78C1F0" />
          <stop offset="0.53" stop-color="#59AAE1" />
          <stop offset="0.77" stop-color="#2E8ACB" />
          <stop offset="1" stop-color="#0067B4" />
        </linearGradient>
      </defs>
    </g>
  )
});

export default YETI;
