import { Box } from "@chakra-ui/react";
import { LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import React from "react";
import tokenData from "../../../TokenData";
import { format, formatWithDecimals } from "../../../Utils/number";
import CollateralCalculator from "../CollateralCalculator";

const selector = ({ trove, price, tokenBalances, borrowingRate }: LiquityStoreState) => ({
  borrowingRate,
  trove,
  price,
  tokenBalances
});

const AdjustLiquidation: React.FC = () => {
  const { trove, tokenBalances } = useLiquitySelector(selector);

  // Shape collateral
  tokenData.map(
    token =>
      (token["troveBalance"] = formatWithDecimals(trove.collaterals[token.address], token.decimals))
  );
  tokenData.map(
    token =>
      (token["walletBalance"] = formatWithDecimals(
        tokenBalances[token.underlying == undefined ? token.address : token.underlying!],
        token.decimals
      ))
  );
  tokenData.map(token => (token["safetyRatio"] = format(token["safetyRatio"])));

  return (
    <Box layerStyle="card" flex={1} px={2}>
      <CollateralCalculator collateral={tokenData} />
    </Box>
  );
};

export default AdjustLiquidation;
