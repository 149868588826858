import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Button,
  Spacer,
  Text,
  Divider,
  BoxProps,
  useDisclosure,
  useToast,
  UseToastOptions
} from "@chakra-ui/react";
import AdjustBorrowAmount from "../AdjustBorrowAmount";
import AdjustCollateral from "../AdjustCollateral";
import ConfirmChangesModal from "../ConfirmChangesModal";
import { LiquityStoreState, Decimal } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import tokenData from "../../../TokenData";
import {
  checkCollateral,
  calculateVcValue,
  checkBorrow,
  getAffectedCollateral,
  calculateTotalYUSDFromLever
} from "./AdjustTrove.utils";
import { format, formatWithDecimals } from "../../../Utils/number";
import { AdjustedTokenData } from "../../../Types";
import { Form } from "react-final-form";
import { Toggle } from "../../../Components";

type AdjustTroveProps = { disconnected?: boolean } & BoxProps;

const selector = ({ trove, price, tokenBalances, borrowingRate }: LiquityStoreState) => ({
  borrowingRate,
  trove,
  price,
  tokenBalances
});

var BreakException = {};

const AdjustTrove: React.FC<AdjustTroveProps> = ({ disconnected, ...props }) => {
  const { trove, price, tokenBalances, borrowingRate } = useLiquitySelector(selector);

  const [borrowMode, setBorrowMode] = useState<"normal" | "lever" | "unlever">("normal");
  const [leverSave, setLeverSave] = useState<"saved" | "unsaved">("unsaved");

  const {
    isOpen: isConfirmChangesOpen,
    onOpen: onConfirmChangesOpen,
    onClose: onConfirmChangesClose
  } = useDisclosure();
  const toast = useToast();

  const toastProps: UseToastOptions = {
    status: "error",
    duration: 4000,
    isClosable: true,
    position: "top-right"
  };

  // Shape collateral
  useEffect(() => {
    tokenData.map(
      token =>
        (token["troveBalance"] = formatWithDecimals(
          trove.collaterals[token.address],
          token.decimals
        ))
    );
    tokenData.map(
      token =>
        (token["walletBalance"] = formatWithDecimals(
          tokenBalances[token.underlying == undefined ? token.address : token.underlying],
          token.decimals
        ))
    );
    tokenData.map(token => (token["safetyRatio"] = format(token["safetyRatio"])));
  }, [tokenBalances, trove.collaterals]);

  // Validate changes
  const validate = (
    values: { [key: string]: string | number },
    ICR: number,
    troveBalancePost: number
  ) => {
    try {
      // Data Engineering
      let affectedTokens = Object.keys(values).filter(value => !value.includes("mode"));
      const affectedCollateral = tokenData.filter(collateral =>
        affectedTokens.includes(collateral.token)
      );
      const tokenDataChanges: AdjustedTokenData[] = getAffectedCollateral(values);

      // Collateral Error Checks
      let totalYUSDFromLever: Decimal = calculateTotalYUSDFromLever(tokenDataChanges, price, values);
      let [changes, collateralApproved, message, description] = checkCollateral(
        affectedCollateral,
        values
      );
      if (!collateralApproved) {
        toast({
          title: message,
          description: description,
          ...toastProps
        });
        throw BreakException;
      }

      if (troveBalancePost < 2000 && trove.status == "open") {
        toast({
          title: "Invalid Action",
          description:
            "You are paying back more YUSD than minimum debt required to keep trove open (2000 YUSD). Please reduce debt repayment amount or close trove.",
          ...toastProps
        });
        throw BreakException;
      } else if (troveBalancePost < 2000) {
        toast({
          title: "Invalid Action",
          description: "You must borrow at least 2000 YUSD in order to open a Trove.",
          ...toastProps
        });
        throw BreakException;
      }

      if (!changes && values["YUSD"] === 0) {
        toast({
          title: "Invalid Action",
          description: "Please make some changes to your trove before confirming changes",
          ...toastProps
        });
        throw BreakException;
      }
      // console.log(values);
      let borrowApproved;
      [borrowApproved, message, description] = checkBorrow(
        values,
        totalYUSDFromLever,
        trove.debt["debt"],
        ICR
      );
      if (!borrowApproved) {
        toast({
          title: message,
          description: description,
          ...toastProps
        });
        throw BreakException;
      }

      if ((changes || values["YUSD"] !== 0) && borrowApproved && collateralApproved) {
        onConfirmChangesOpen();
      }
    } catch (e) {
      if (e !== BreakException) throw e;
    }
  };

  const getFormattedValue = (value: string) => {
    if (/^[0-9.]*$/.test(value)) {
      return value;
    }
    return "";
  };

  const leverOptions = [
    { key: "normal", value: "Normal" },
    { key: "lever", value: "Leverage" }
  ];

  if (trove.status === "open") {
    leverOptions.push({ key: "unlever", value: "Deleverage" });
  }
  return (
    <Box layerStyle="card" flex={1} px={2} {...props}>
      <Form
        onSubmit={() => {}}
        format={getFormattedValue}
        render={({ values, form }) => (
          <>
            <Flex justify="space-between" align="center" mb={2} px={6}>
              <Text color="white" textStyle="title2">
                {trove.status !== "open" ? "Create" : "Adjust"} Trove
              </Text>
              <Toggle
                options={leverOptions}
                size="md"
                onChange={v => {
                  setBorrowMode(v as "normal" | "lever" | "unlever");
                  form.reset();
                }}
              />
            </Flex>
            <ConfirmChangesModal
              isOpen={isConfirmChangesOpen}
              onClose={onConfirmChangesClose}
              values={values}
              collateral={getAffectedCollateral(values)}
              borrowMode={borrowMode}
            />
            <AdjustCollateral
              values={values}
              collateral={tokenData}
              form={form}
              borrowMode={borrowMode}
              leverSave={leverSave}
              setLeverSave={setLeverSave}
            />
            <Box my={5} px={6}>
              <Divider />
            </Box>
            <AdjustBorrowAmount
              values={values}
              collateral={tokenData}
              validateFunc={validate}
              borrowFee={(+borrowingRate.mul(100)).toFixed(3)}
              leverSave={leverSave}
              borrowMode={borrowMode}
            />
            {/* <Flex align="center" mt={4} mx={6}>
              <Text textStyle="body2">YUSD Borrow Fee: {(+borrowingRate.mul(100)).toFixed(3)}%</Text>
              <Spacer />
              <Button variant="primary" onClick={() => validate(values)}>
                Confirm Changes
              </Button>
            </Flex> */}
          </>
        )}
      />
    </Box>
  );
};

export default AdjustTrove;
