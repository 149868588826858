import React from "react";
import {
  Flex,
  Tr,
  Td,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Input,
  Text,
  Box,
  NumberInput,
  NumberInputField
} from "@chakra-ui/react";
import { TokenTable } from "../../../Components";
import {
  AdjustedCollateral,
  isStableCoin,
  OverallCollateralStats,
  getOverallWeightedValue
} from "../CollateralCalculator/CollateralCalculator";
import { useState } from "react";
import { useEffect } from "react";
import { getNum } from "../../utils";

type Summary = {
  icr: number;
  rsr: number;
  liquidatable: number;
};
type OverallStatsProps = {
  collaterals: AdjustedCollateral[];
  overallTroveStats: OverallCollateralStats;
  setCalculatorState: any;
  debt: number;
};

const getLiquidatableText = (value: number) =>
  value <= 0 ? (
    <Text>
      Your portfolio, excluding stablecoins would need to increase by{" "}
      <Text as="u">${value.toFixed(3)}%</Text> to be no longer liquidatable
    </Text>
  ) : (
    <Text>
      Your portofolio, excluding stablecoins would need to decrease by{" "}
      <Text as="u">${value.toFixed(3)}%</Text> to be liquidatable
    </Text>
  );

const OverallStats: React.FC<OverallStatsProps> = ({
  collaterals,
  overallTroveStats,
  setCalculatorState,
  debt
}) => {
  const [currentDebt, setCurrentDebt] = useState<{ debt: number; debtString: string }>({
    debt,
    debtString: debt.toFixed(3)
  });
  const [summary, setSummary] = useState<Summary>({
    icr: (overallTroveStats.weightedCollateralValue / currentDebt.debt) * 100,
    rsr: 0,
    liquidatable: 0
  });

  const handleOverallPriceChange = (val: number) => {
    const newCollaterals = [...collaterals].map(collateral => {
      if (isStableCoin(collateral)) {
        return {
          ...collateral,
          adjustedPrice: collateral.price,
          weightedCollateralValue:
            collateral.troveBalance * collateral.price * collateral.safetyRatio
        };
      }
      const adjPrice = collateral.price + (collateral.price * val) / 100;

      return {
        ...collateral,
        adjustedPrice: adjPrice,
        weightedCollateralValue: collateral.troveBalance * adjPrice * collateral.safetyRatio
      };
    });

    const weightedCollateral = getOverallWeightedValue(newCollaterals);

    setCalculatorState({
      adjustedCollaterals: newCollaterals,
      overallStats: {
        ...overallTroveStats,
        adjustedPrice: val,
        weightedCollateralValue: weightedCollateral
      }
    });
  };

  useEffect(() => {
    setSummary({
      ...summary,
      icr: (overallTroveStats.weightedCollateralValue / currentDebt.debt) * 100,
      liquidatable:
        ((overallTroveStats.weightedCollateralValue - currentDebt.debt * 1.1) /
          overallTroveStats.weightedCollateralValue) *
        100
    });
  }, [currentDebt, overallTroveStats.weightedCollateralValue]);

  return (
    <>
      <Flex>
        <TokenTable headers={["", "", "", ""]} width={4}>
          <Tr>
            <Td pt={8} pb={2}>
              <Flex align="center" w={44}>
                Overall trove stats
              </Flex>
            </Td>
            <Td pt={8} pb={2}>
              <Flex align="center" w={60}>
                <Slider
                  focusThumbOnChange={false}
                  min={overallTroveStats.minAdjustedPrice}
                  max={overallTroveStats.maxAdjustedPrice}
                  step={overallTroveStats.adjustmentStep}
                  value={overallTroveStats.adjustedPrice}
                  onChange={handleOverallPriceChange}
                >
                  <SliderMark value={-100} mt="1" ml="-2.5" fontSize="x-small">
                    0X
                  </SliderMark>
                  <SliderMark value={0} mt="1" ml="-2.5" fontSize="x-small">
                    1X
                  </SliderMark>
                  <SliderMark value={100} mt="1" ml="-2.5" fontSize="x-small">
                    2X
                  </SliderMark>
                  <SliderMark value={300} mt="1" ml="-2.5" fontSize="x-small">
                    4X
                  </SliderMark>
                  <SliderMark value={500} mt="1" ml="-2.5" fontSize="x-small">
                    6X
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Flex>
            </Td>
            <Td pt={8} pb={2}>
              <Flex align="center" w={120}>
                Total weighted collateral value (TWCV)
              </Flex>
            </Td>
            <Td pt={8} pb={2} pr={0}>
              <Flex align="center" w={36}>
                $ {getNum(overallTroveStats.weightedCollateralValue)}
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td pt={8} pb={2}></Td>
            <Td pt={8} pb={2}>
              <Flex align="center" w={60} direction="column">
                <Slider
                  focusThumbOnChange={false}
                  min={2000}
                  max={overallTroveStats.weightedCollateralValue * 0.9}
                  step={(overallTroveStats.weightedCollateralValue * 0.9) / 20}
                  value={currentDebt.debt}
                  onChange={val => {
                    setCurrentDebt({ debt: val, debtString: val.toFixed(3).toString() });
                  }}
                >
                  <SliderMark value={2000} mt="1" ml="-2.5" fontSize="x-small">
                    $2000
                  </SliderMark>
                  <SliderMark
                    value={overallTroveStats.weightedCollateralValue * 0.9}
                    mt="1"
                    ml="-2.5"
                    fontSize="x-small"
                  >
                    ${getNum(overallTroveStats.weightedCollateralValue * 0.9)}
                  </SliderMark>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
                <Text fontSize="small" mt={4} color="gray.500">
                  Max debt is 90% of TWCV
                </Text>
              </Flex>
            </Td>
            <Td pt={8} pb={2}>
              <Flex align="center" w={120}>
                Total Debt
              </Flex>
            </Td>
            <Td pt={8} pb={2} pr={0}>
              <Flex align="center" w={36}>
                <NumberInput
                  precision={3}
                  value={currentDebt.debtString}
                  onChange={val => {
                    setCurrentDebt({ debt: parseFloat(val), debtString: val.toString() });
                  }}
                >
                  <NumberInputField />
                </NumberInput>
              </Flex>
            </Td>
          </Tr>
          <Tr>
            <Td pt={8} pb={2}>
              <Flex align="center" w={44}>
                Summary
              </Flex>
            </Td>
            <Td pt={8} pb={2}>
              <Flex align="center" w={60}>
                {getLiquidatableText(summary.liquidatable)}
              </Flex>
            </Td>
            <Td pt={8} pb={2}>
              <Flex direction="column" align="flex-start" w={120}>
                <Box>
                  <Text fontSize="2xl">ICR</Text>
                </Box>
                <Box pt={5}>
                  <Text fontSize="2xl">RSR</Text>
                </Box>
              </Flex>
            </Td>
            <Td pt={8} pb={2} pr={0}>
              <Flex direction="column" align="flex-start" w={120}>
                <Box>
                  <Text fontSize="2xl">{summary.icr.toFixed(3)}%</Text>
                </Box>
                <Box pt={5}>
                  <Text fontSize="2xl">{summary.rsr.toFixed(3)}%</Text>
                </Box>
              </Flex>
            </Td>
          </Tr>
        </TokenTable>
      </Flex>
    </>
  );
};

export default OverallStats;
