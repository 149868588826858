import React from "react";
import { Web3ReactProvider } from "@web3-react/core";
import { BatchedWebSocketAugmentedWeb3Provider } from "@liquity/providers";
import { Center, Text, Button } from "@chakra-ui/react";
import { RootWrapper, Icon } from "./Components";
import { Loading } from "./Screens";
import WalletConnector from "./Components/WalletConnector";
import { LiquityProvider } from "./Hooks/LiquityContext";
import { TransactionProvider } from "./Components/Transaction";
import YetiFrontend from "./YetiFrontend";
import Snowfall from "react-snowfall";

declare var window: any;

const switchNetwork = () => {
  if (!window.ethereum) return;
  return window.ethereum.request({
    method: "wallet_switchEthereumChain",
    params: [{ chainId: `0xA869` }]
  });
};

const EthersWeb3ReactProvider: React.FC = ({ children }) => {
  return (
    <Web3ReactProvider getLibrary={provider => new BatchedWebSocketAugmentedWeb3Provider(provider)}>
      {children}
    </Web3ReactProvider>
  );
};

const UnsupportedMainnetFallback: React.FC = () => (
  <Center w="100vw" h="100vh" flexDirection="column" p={10}>
    <Snowfall snowflakeCount={100} style={{ zIndex: 1 }} />
    <img src="/img/yeti-logo.png" alt="Yeti Finance" style={{ width: "12rem" }} />
    <Text textStyle="title4" my={5} textAlign="center">
      Please switch your chain to the Avalanche FUJI C-Chain.
    </Text>
    <Button colorScheme="brand" mb={10} onClick={switchNetwork}>
      Switch Network
    </Button>
  </Center>
);

const App = () => {
  const unsupportedNetworkFallback = (chainId: number) => (
    <Center w="100vw" h="100vh" flexDirection="column" p={10}>
      <Snowfall snowflakeCount={100} style={{ zIndex: 1 }} />
      <img src="/img/yeti-logo.png" alt="Yeti Finance" style={{ width: "12rem" }} />
      <Text textStyle="title4" my={5} textAlign="center">
        Please switch your chain to the Avalanche FUJI C-Chain.
      </Text>
      <Button colorScheme="brand" mb={10} onClick={switchNetwork}>
        Switch Network
      </Button>
    </Center>
  );

  return (
    <EthersWeb3ReactProvider>
      <RootWrapper>
        <WalletConnector loader={<Loading />}>
          <LiquityProvider
            loader={<Loading />}
            unsupportedNetworkFallback={unsupportedNetworkFallback}
            unsupportedMainnetFallback={<UnsupportedMainnetFallback />}
          >
            <TransactionProvider>
              <YetiFrontend />
            </TransactionProvider>
          </LiquityProvider>
        </WalletConnector>
      </RootWrapper>
    </EthersWeb3ReactProvider>
  );
};

export default App;
