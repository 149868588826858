import React, { useState, useEffect } from "react";
import { Box, Flex, Spacer, Text, useDisclosure, Button } from "@chakra-ui/react";
import CoinAmount from "../../../Components/CoinAmount";
import { Decimal, LiquityStoreState, TroveMappings } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { getBalanceInfo } from "./YourBalance.utils";
import { newWeeklyAPR, format } from "../../../Utils/number";
import Tooltip from "../../../Components/Tooltip";
import { useLiquity } from "../../../Hooks/LiquityContext";
import PoolRewardsModal from "../../Pool/PoolRewardsModal";

const selector = ({ farmYETI }: LiquityStoreState) => ({
  farmYETI
});
const YourBalanceCard: React.FC = () => {
  const { farmYETI } = useLiquitySelector(selector);
  const { liquity } = useLiquity();
  const [reward, setReward] = useState<Decimal>(Decimal.from(0));
  useEffect(() => {
    if (!isNaN(+farmYETI.yetiTokenBalance)) {
      liquity
        .getEstimatedYETIFarmRewards(+String(farmYETI.yetiTokenBalance), 604800)
        .then(num => setReward(num));
    }
  }, [farmYETI.yetiTokenBalance]);
  // console.log("farmYETI.totalYETIStaked", farmYETI.totalYETIStaked);
  const balanceInfo = getBalanceInfo(
    +String(farmYETI.yetiTokenBalance),
    +String(Decimal.from(100).mul(farmYETI.yetiTokenBalance.div(farmYETI.totalYETIStaked))),
    !farmYETI.yetiTokenBalance.eq(Decimal.from(0)) ? +String(reward) : 0
  );
  const {
    isOpen: isPoolRewardsOpen,
    onOpen: onPoolRewardsOpen,
    onClose: onPoolRewardsClose
  } = useDisclosure();
  const earned: TroveMappings = {
    "0x261DA9C7E7932cCBC7d556836cf7128f543312b9": farmYETI.earnedYETI
  };
  return (
    <>
      <PoolRewardsModal
        isOpen={isPoolRewardsOpen}
        onClose={onPoolRewardsClose}
        rewards={earned}
        notStability={true}
        mode="YETI"
      />
      <Box layerStyle="card" flex={1}>
        <Text textStyle="title3" mb={5}>
          Your YETI Balance
        </Text>
        <Box>
          {balanceInfo.map(({ tooltip, value, percent, title }) => (
            <Flex direction="row" mt={4}>
              <Text textStyle="subtitle1" fontWeight="normal">
                {title + " "}
                {tooltip !== undefined && <Tooltip>{tooltip}</Tooltip>}
              </Text>
              <Spacer />
              {percent !== undefined && <Text textStyle="subtitle1">{percent.toFixed(3)}%</Text>}
              {value !== undefined && (
                <CoinAmount token="YETI" amount={value} textStyle="subtitle1" color="white" />
              )}
            </Flex>
          ))}
        </Box>
        <Flex justify="flex-end" mt={4}>
          <Button variant="primary" onClick={onPoolRewardsOpen}>
            View Rewards
          </Button>
        </Flex>
      </Box>
    </>
  );
};

export default YourBalanceCard;
