import React, { useState, useEffect } from "react";
import { Flex, Text, Button, Spacer, useDisclosure } from "@chakra-ui/react";
import { LiquityStoreState } from "@liquity/lib-base";
import { keyframes } from "@chakra-ui/react"

// const marquee = keyframes`
//   0% { transform: translate(0, 0); }
//   100% { transform: translate(100%, 0); }
// `;

const TestnetBanner: React.FC = () => {
  // const marqueeAnimation = `${marquee} infinite 2s linear`;
  return (
    <Flex bg="brand.500" align="center" px={16} py={3} w="100vw" justifyContent="center">
      <Text fontWeight="bold" fontSize="2xl" color="brand.100">
        Yeti Finance Testnet
      </Text>
    </Flex>
  );
};

export default TestnetBanner;
