import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { Header, ConnectCard } from "../../Components";
import { PoolCard, YourBalanceCard } from "../../PageComponents/Pool";
import { FarmCard, YourFarmBalanceCard } from "../../PageComponents/Farm";

export type PoolProps = {
  disconnected?: boolean;
};

const Pool: React.FC<PoolProps> = ({ disconnected = false }) => {
  return (
    <Box>
      <Header title="pool.png" />
      <Flex direction={["column", null, "row"]} flex={1} mt={6}>
        <Flex flex={1} mr={[0, null, 3]}>
          <PoolCard disconnected={disconnected} />
        </Flex>
        <Flex flex={1} ml={[0, null, 3]} mt={[6, null, 0]}>
          {disconnected ? <ConnectCard title="Your Balance" /> : <YourBalanceCard />}
        </Flex>
      </Flex>
      <Flex direction={["column", null, "row"]} flex={1} mt={6}>
        <Flex flex={1} mr={[0, null, 3]}>
          <FarmCard disconnected={disconnected} />
        </Flex>
        <Flex flex={1} ml={[0, null, 3]} mt={[6, null, 0]}>
          {disconnected ? <ConnectCard title="Your Balance" /> : <YourFarmBalanceCard />}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Pool;
