import React from "react";
import { Box, Button, Flex, Text, Progress, Spacer, useDisclosure } from "@chakra-ui/react";
import Icon from "../../../Components/Icon";
import { ConnectButton } from "../../../Components/WalletConnector";
import ConfirmCloseTroveModal from "../ConfirmCloseTroveModal";
import { calculateHealth, calculateHealthColor } from "./Trove.utils";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import tokenData from "../../../TokenData";
import { TroveMappings } from "../../../Types";
import { getNum, format, formatWithDecimals } from "../../../Utils/number";
import { useEffect } from "react";
import { useState } from "react";
import Tooltip from "../../../Components/Tooltip";
import { VC_explanation } from "../../../Utils/constants";

export type TroveProps = {
  disconnected?: boolean;
};

const selector = ({ trove, price, tokenBalances, icr }: LiquityStoreState) => ({
  trove,
  price,
  tokenBalances,
  icr,
});

var dataSelector = useLiquitySelector;

const Trove: React.FC<TroveProps> = ({ disconnected = false }) => {
  let trove: any, price: TroveMappings, tokenBalances: TroveMappings, icr: Decimal
  let healthRatio, totalBorrowed;
  // const [totalBorrowed, setTotalBorrowed] = useState<number>(0)
  // const [healthRatio, setHealthRatio] = useState<number>(0)
  // var vcValue: number = 0;
  var usdValue: number = 0;
  let vcValue: number = 0;
  let aicr: number = 0
  if (disconnected) {
    trove = null;
    healthRatio = 0;
    totalBorrowed = 0;
    price = {};
    icr = Decimal.ZERO;
  } else {
    ({ trove, price, tokenBalances, icr } = dataSelector(selector));
    // setHealthRatio(+trove.collateralRatio(price, false).toString());
    // setTotalBorrowed(+trove.debt["debt"]);
    healthRatio = format(icr) * 100;
    totalBorrowed = +trove.debt["debt"];
    tokenData.map(
      token =>
        (token["troveBalance"] = formatWithDecimals(
          trove.collaterals[token.address],
          token.decimals
        ))
    );
    tokenData.map(
      token =>
        (token["walletBalance"] = formatWithDecimals(
          tokenBalances[token.underlying == undefined ? token.address : token.underlying!],
          token.decimals
        ))
    );
    tokenData.map(token => (token["safetyRatio"] = format(token["safetyRatio"])));
    
    // let stableVC: number = 0;
    // let stableUSD: number = 0;
    tokenData.map(token => {
      const vc = format(price[token.address]) * token.safetyRatio * formatWithDecimals(trove.collaterals[token.address], token.decimals)
      vcValue += vc 
      
  
      const usd = format(price[token.address]) * formatWithDecimals(trove.collaterals[token.address], token.decimals)
      usdValue += usd
  
      // if (token.isStable) {
      //   stableVC += vc
      //   stableUSD += usd
      // }
        
    });
    aicr = vcValue / totalBorrowed  * 100
  }
  
  const {
    isOpen: isCloseTroveOpen,
    onOpen: onCloseTroveOpen,
    onClose: onCloseTroveClose
  } = useDisclosure();

  const troveHealth = calculateHealth(healthRatio);

  if (trove && trove.status !== "open") {
    return (
      <Box layerStyle="base" bg="brand.800" flex={1}>
        <Text textStyle="title2">Trove</Text>
        <Text textStyle="title4" mt={2}>
          Open a Trove below to see your Trove stats!
        </Text>
      </Box>
    );
  }

  return (
    <Box layerStyle="card" flex={1}>
      <Text textStyle="title2">Trove</Text>
      <Flex flex={1} direction={["column", null, "row"]}>
        <Flex direction="column" flex={3} mr={[0, null, 5]}>
          <Text textStyle="body1" mb={3}>
            Trove Statistics
          </Text>

          {/* Collateral and total borrowed */}
          <Flex
            backgroundColor="purple.400"
            align="center"
            py={3}
            pl={2}
            pr={4}
            mb={5}
            borderRadius="2xl"
          >
            <Flex align="center">
              <Icon iconName="MoneyStack" w={12} h={5} />
              <Text textStyle="subtitle3" mx={1}>
                Total Collateral <Tooltip>{VC_explanation}</Tooltip>
              </Text>
            </Flex>
            <Spacer />
            <Text textStyle="subtitle2">
              {getNum(vcValue, 2)} RAV (${getNum(usdValue, 2)})
            </Text>
          </Flex>

          {/* Total borrowed */}
          <Flex
            backgroundColor="green.400"
            align="center"
            py={3}
            pl={2}
            pr={4}
            mb={2}
            borderRadius="2xl"
          >
            <Flex align="center">
              <Icon iconName="Bank" w={12} h={5} />
              <Text textStyle="subtitle3" mx={1}>
                Total Borrowed
              </Text>
            </Flex>
            <Spacer />
            <Text textStyle="subtitle2">{getNum(totalBorrowed)} YUSD</Text>
          </Flex>
        </Flex>

        {/* Trove health */}
        <Flex direction="column" flex={2} mt={[6, null, 0]} ml={[0, null, 5]}>
          <Text textStyle="body1" mb={3}>
            Trove Collateral Ratio <Tooltip>Ratio between Trove VC and YUSD Debt</Tooltip>
          </Text>

          <Flex direction="column" mb={3}>
            <Flex gap={2} w="100%" align="center" mt={2} mb={7}>
              <Text textStyle="body3" whiteSpace="nowrap">
                110% (min)
              </Text>
              <Progress
                value={troveHealth}
                w="100%"
                colorScheme={calculateHealthColor(troveHealth)}
                bg="brand.900"
                borderRadius="infinity"
              />
            </Flex>
            
            <Flex gap={4} mb={1}>
              <Text textStyle="subtitle4" fontSize="2xl">
                Collateral Ratio:{" "}
                {trove ? `${parseFloat(icr.mul(100).toString()).toFixed(3)}%` : "N/A"}
              </Text>
            </Flex>
            <Flex gap={4}>
              <Text textStyle="body1">
                Stable Adjusted Collateral Ratio:{" "}
                {trove ? `${aicr.toFixed(2)}% ` : "N/A"}
                <Tooltip>{"Stable Adjusted Collateral Ratio is Collateral Ratio that gives more weights(1.55) to stable coins in your trove. This ratio should give you a sense of how safe your trove is."}</Tooltip>
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" flex={1} mt={[6, null, 0]} ml={[0, null, 10]}>
          <Text textStyle="body1" mb={3}>
            Trove Options
          </Text>
          {disconnected ? (
            <ConnectButton w="100%" />
          ) : (
            <>
              <Button w="100%" variant="secondary" onClick={onCloseTroveOpen}>
                Close Trove
              </Button>
              <ConfirmCloseTroveModal isOpen={isCloseTroveOpen} onClose={onCloseTroveClose} />
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Trove;
