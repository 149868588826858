import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Spacer,
  Tag,
  Text,
  useDisclosure,
  useToast
} from "@chakra-ui/react";
import { Toggle, AdjustInput, CoinAmount } from "../../../Components";
import Tooltip from "../../../Components/Tooltip";
import ConfirmStakeModal from "../ConfirmStakeModal";
import { Decimal, LiquityStoreState } from "@liquity/lib-base";
import { useLiquitySelector } from "@liquity/lib-react";
import { capitalizeFirstLetter } from "../../../Utils/string";
import { adjustValue, newWeeklyAPR, getNum, format } from "../../../Utils/number";
import { validateDeposit } from "../../../Utils/validation";
import { Form } from "react-final-form";
import { useLiquity } from "../../../Hooks/LiquityContext";

export type StakeCardProps = {
  disconnected?: boolean;
};

const selector = ({ farmYETI, yetiBalance }: LiquityStoreState) => ({
  farmYETI,
  yetiBalance
});

var dataSelector = useLiquitySelector;

const StakeCard: React.FC<StakeCardProps> = ({ disconnected = false }) => {
  let farmYETI, yetiBalance, yetiStaked: number, yetiAPR: number;
  let userYetiBalance: any;
  const { liquity } = useLiquity();
  const [value, setValue] = useState<any>({});
  const [reward, setReward] = useState<Decimal>(Decimal.from(0));

  useEffect(() => {
    if (!isNaN(value.stakeInput) && mode == "deposit") {
      liquity
        .getEstimatedYETIFarmRewards(+String(value.stakeInput) + yetiStaked, 604800)
        .then(num => setReward(num));
    }
  }, [value.stakeInput]);

  if (!disconnected) {
    ({ farmYETI, yetiBalance } = dataSelector(selector));
    // console.log("yetiBalance", yetiBalance);
    userYetiBalance = format(yetiBalance);
    // console.log("userYetiBalance", userYetiBalance);
    yetiStaked = format(farmYETI.yetiTokenBalance);
  } else {
    userYetiBalance = 1000;
    yetiStaked = 0;
    yetiAPR = 1.125;
  }

  const { isOpen: isConfirmOpen, onOpen: onConfirmOpen, onClose: onConfirmClose } = useDisclosure();
  const toast = useToast();

  const [mode, setMode] = useState<"deposit" | "withdraw">("deposit");

  const validate = (valueChange: number) => {
    validateDeposit(toast, mode, userYetiBalance, yetiStaked, valueChange, onConfirmOpen);
  };

  return (
    <>
      <Box layerStyle="card" flex={1}>
        <Text textStyle="title3" mb={2}>
          Stake YETI
        </Text>

        <Toggle
          options={[
            { value: "Stake", key: "deposit" },
            { value: "Unstake", key: "withdraw" }
          ]}
          size="md"
          onChange={v => setMode(v as "deposit" | "withdraw")}
        />
        <Form
          onSubmit={() => {}}
          render={({ values }) => (
            <>
              {setValue(values)}
              {!disconnected && (
                <ConfirmStakeModal
                  isOpen={isConfirmOpen}
                  onClose={onConfirmClose}
                  mode={mode}
                  amount={values.stakeInput || "0"}
                  total={adjustValue(mode, yetiStaked, values.stakeInput)}
                />
              )}

              <AdjustInput
                mt={4}
                max={mode === "deposit" ? userYetiBalance : yetiStaked}
                name="stakeInput"
                token="YETI"
                showToken
                fillContainer
              />
              {mode === "deposit" ? (<Text textStyle="body1" fontWeight="bold" mt={1.5}>
              Wallet Balance: {getNum(userYetiBalance)} YETI
              </Text>) : (<Text textStyle="body1" fontWeight="bold" mt={1.5}>
                Staked Balance: {getNum(yetiStaked)} YETI
              </Text>)}
              <Box>
                <Flex mt={4}>
                  <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                    {"New Staked Amount"}
                  </Text>
                  <Spacer />
                  <CoinAmount
                    token="YETI"
                    amount={adjustValue(mode, yetiStaked, values.stakeInput)}
                    textStyle="subtitle1"
                    color="white"
                  />
                </Flex>
                <Flex mt={4}>
                  <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                    {"New Estimated Weekly Rewards "}
                    <Tooltip>Estimated amount of rewards you will receive in a week based on your deposit</Tooltip>
                  </Text>
                  <Spacer />
                  <CoinAmount
                    token="YETI"
                    amount={
                      +values.stakeInput &&
                      Decimal.from(values.stakeInput).lte(Decimal.from(userYetiBalance))
                        ? +String(reward)
                        : 0
                    }
                    textStyle="subtitle1"
                    color="green.400"
                  />
                </Flex>
              </Box>
              <Divider color="brand.600" mt={4} />

              <Flex mt={4}>
                <Text textStyle="subtitle1" fontWeight="normal" color="brand.300">
                  {"YETI Reward APR "}
                  <Tooltip>Estimated amount of rewards you will receive in a week based on your deposit</Tooltip>
                </Text>
                <Spacer />

                <Tag bgColor="secondary.400">
                  <Text textStyle="subtitle1">
                    {(+values.stakeInput &&
                      Decimal.from(values.stakeInput).lte(Decimal.from(userYetiBalance))
                        ? ((+String(reward) * 52) / (+values.stakeInput + yetiStaked)) * 100
                        : 0
                    ).toFixed(3)}

                    {/* {(!isNaN(+values.stakeInput) && mode == "withdraw" &&
                      Decimal.from(values.stakeInput).lte(Decimal.from(yetiStaked))
                        && (((+String(reward) * 52) / (yetiStaked - +values.stakeInput)) * 100).toFixed(3)
                    )} */}

                    {/* {isNaN(+values.stakeInput) && 
                      (((+String(reward) * 52) / (yetiStaked)) * 100).toFixed(3)} */}
                    %
                  </Text>
                </Tag>
              </Flex>

              {!disconnected && (
                <Flex mt={4} justify="flex-end">
                  <Button colorScheme="brand" onClick={() => validate(values.stakeInput)}>
                    {mode == "deposit" ? "Stake" : "Unstake"}
                  </Button>
                </Flex>
              )}
            </>
          )}
        />
      </Box>
    </>
  );
};

export default StakeCard;
